



















































































































































import { getFirestore } from '@firebase/firestore'
import { addMonths, subHours } from 'date-fns'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import {
  BusinessOwner,
  PaymentMethod,
} from '../../shared/models/business-owner'
import { Invoice } from '../../shared/models/invoice'
import { UserDatabase } from '../db/user-database'
import { app } from '../helpers/firebase'
import { getDefaultPaymentMethod } from '../helpers/functions'
import { Plan, PLANS } from '../utils/plans'
const auth = createNamespacedHelpers('auth')
import { sendTrackingEvent } from '@/utils/eventTracker'
import { BillingInfo } from '../../shared/models/billing-info'
import { BillingInfoDatabase } from '../db/billing-info-database'

export const getStartOfJstMonth = (
  params: { month: number; year: number } | { prevMonth: number; year: number }
) => {
  if ('month' in params) {
    const { month, year } = params
    let date = new Date(year, month, 1)
    return subHours(date, 9)
  } else {
    const { prevMonth, year } = params
    const prevMonthDate = new Date(year, prevMonth, 1)
    const date = subHours(addMonths(prevMonthDate, 1), 9)
    return date
  }
}

@Component({
  components: {},
  computed: {
    ...auth.mapState(['uid', 'user']),
  },
  methods: {
    ...auth.mapMutations(['updateAuthStatus']),
  },
})
export default class PaymentOverview extends Vue {
  uid!: string
  user!: BusinessOwner

  plan: Plan | null = null
  paymentMethod: any = null

  billingInfo: BillingInfo | null = null

  interviewConfirmationsCount: number | null = null

  unpaidInvoices: Invoice[] = []

  isPreregisteredBySalePartner: boolean | null = null

  created() {
    this.plan = PLANS.find((v) => v.id === this.user.planId) || null

    this.isPreregisteredBySalePartner = this.user.isPreregisteredBySalePartner
    if (!this.isPreregisteredBySalePartner) {
      if (this.user.path && this.user.path.includes('preregister:')) {
        this.isPreregisteredBySalePartner = true
      }
    }

    if (this.user.paymentMethod === PaymentMethod.INVOICE) {
      const billingInfoDb = new BillingInfoDatabase({
        client: getFirestore(app),
      })
      billingInfoDb.getObj(this.uid).then((v) => (this.billingInfo = v))
    } else {
      getDefaultPaymentMethod({}).then(
        (v) => (this.paymentMethod = v.paymentMethod)
      )
    }

    const userDb = new UserDatabase({
      client: getFirestore(app),
    })

    const meetingDb = userDb.getMeetingDb(this.uid)
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const startOfMonth = getStartOfJstMonth({ month, year })
    const startOfNextMonth = getStartOfJstMonth({ prevMonth: month, year })
    meetingDb
      .countConfirmedMeetings({
        from: startOfMonth,
        to: startOfNextMonth,
      })
      .then((v) => (this.interviewConfirmationsCount = v))

    const invoiceDb = userDb.getInvoiceDb(this.uid)
    invoiceDb.getUnpaidInvoices().then((v) => (this.unpaidInvoices = v))
  }

  calculateThisMonthAmount() {
    if (this.plan) {
      return (
        this.plan.pricePerMonth +
        Math.max(
          0,
          (this.interviewConfirmationsCount || 0) - this.plan.freeQuota
        ) *
          this.plan.pricePerAdditionalConfirmation
      )
    }

    return 0
  }

  formatMoney(amount: number) {
    return amount.toLocaleString() + '円'
  }

  back() {
    this.$router.back()
  }

  changePlan() {
    this.$router.push('/select-plan')
    sendTrackingEvent(`${this.$route.path}:changePlan`, {}, this.uid)
  }

  changePaymentInfo() {
    if (this.user.paymentMethod === PaymentMethod.INVOICE) {
      this.registerBillingInfo()
    } else {
      this.registerCreditCard()
    }
  }

  registerBillingInfo() {
    this.$router.push('/register-billing-info')
    sendTrackingEvent(`${this.$route.path}:registerBillingInfo`, {}, this.uid)
  }

  registerCreditCard() {
    this.$router.push('/register-credit-card')
    sendTrackingEvent(`${this.$route.path}:registerCreditCard`, {}, this.uid)
  }

  payInvoice(invoice: Invoice) {
    if (invoice.stripeInvoice?.hosted_invoice_url) {
      window.open(invoice.stripeInvoice?.hosted_invoice_url, '_blank')
    }
    sendTrackingEvent(`${this.$route.path}:payInvoice`, invoice, this.uid)
  }
}
