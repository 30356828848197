import { ID, Optional } from './models'
import { User } from './user'

export enum UserAdminRole {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
}

export enum UserStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  UNPAID = 'unpaid',
  PREREGISTER_WITH_PHONE_NUMBER = 'preregister_with_phone_number',
}

export enum PaymentMethod {
  CARD = 'card',
  INVOICE = 'invoice',
}

export interface BusinessOwner extends User {
  url: string
  businessName: string | null
  billingName: string | null
  billingEmail: string | null
  creditCardProvided: boolean | null
  planId: string | null
  stripeCustomerId: string | null
  stripePaymentMethodId: string | null
  status: UserStatus
  shortenUrl: string | null
  paymentMethod: PaymentMethod | null
  acceptInterviews: boolean | null
  createdBy: ID | null
  adminRole: UserAdminRole | null
  adminRoleCreatedBy: string | null
  isPreregisteredBySalePartner: boolean | null
}

export type BusinessOwnerInput = Optional<BusinessOwner>

export const BusinessOwnerFn = {
  acceptInterviews: (user: BusinessOwner) => {
    return (
      // default value of `acceptInterviews` may NULL or UNDEFINED
      user.acceptInterviews === null ||
      user.acceptInterviews === undefined ||
      user.acceptInterviews
    )
  },
}
