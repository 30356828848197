import {
  collection,
  CollectionReference,
  DocumentData,
  limit,
  QueryConstraint,
  where,
} from '@firebase/firestore'
import {
  Notification,
  NotificationStatus,
  NotificationType,
} from '@shared/models/notification'
import { ITEM_PER_PAGE } from '../../shared/constants'
import { ID } from '../../shared/models/models'
import { Database } from './database'

/**
 *
 */
export class NotificationDatabase extends Database<Notification> {
  collection(): CollectionReference<DocumentData> {
    return collection(this.db(), 'NOTIFICATIONS')
  }

  async getNotifications({ userId }: { userId: ID }): Promise<Notification[]> {
    const wheres: QueryConstraint[] = [
      where('ownerId', '==', userId),
      limit(ITEM_PER_PAGE),
    ].filter((_) => !!_) as QueryConstraint[]

    return this.listObj(wheres)
  }

  async createDefaultNofications({
    userId,
  }: {
    userId: string
  }): Promise<void> {
    const types = [
      NotificationType.AFTER_MEETING_CONFIRMATION_NOTIFICATION,
      NotificationType.MEETING_AFTER_X_MINUTE,
      NotificationType.MEETING_IN_X_MINUTE,
      NotificationType.MEETING_NEXT_X_DAY,
    ]

    const notifications = await Promise.all(
      types.map((type: NotificationType) => {
        return this.create({
          type,
          content: null,
          day: 1,
          status: NotificationStatus.ENABLED,
          minute: 10,
          timezone: 'Asia/Tokyo',
          ownerId: userId,
        })
      })
    )

    console.log(
      'created notifications for user',
      userId,
      'ids:',
      notifications.map((notification) => notification.id)
    )
  }
}
