import { PersonalToken } from '@shared/models/personal-token'
import { User } from '@shared/models/user'
import {
  CollectionReference,
  DocumentData,
  collection,
} from 'firebase/firestore'
import { ChildDatabase } from './child-database'

/**
 *
 */
export class ActionTokenDatabase extends ChildDatabase<PersonalToken, User> {
  /**
   *
   * @return {CollectionReference<DocumentData>}
   */
  collection(): CollectionReference<DocumentData> {
    return collection(this.db(), this.parentRef?.path as string, 'TOKENS')
  }
}
