export const createShortenUrl = (
  targetUrl: string,
  options?: {
    fixedId?: string
    reusable?: boolean
  }
): Promise<{ shortenUrl?: string }> => {
  return fetch(
    `https://us-central1-myo-shorten-url.cloudfunctions.net/createShortenUrl`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        id: options?.fixedId,
        targetUrl,
        reusable: options?.reusable,
      }),
    }
  ).then((res) => res.json())
}
