






































































































import { getFirestore } from '@firebase/firestore'
import { Component, Vue } from 'vue-property-decorator'
import { BillingInfoDatabase } from '../db/billing-info-database'
import { app } from '../helpers/firebase'
import { createNamespacedHelpers } from 'vuex'
import {
  BusinessOwner,
  BusinessOwnerInput,
  PaymentMethod,
  UserStatus,
} from '../../shared/models/business-owner'
import { BillingInfoInput } from '@shared/models/billing-info'
import { normalizePhoneNumber } from '@shared/helpers/phones'
const auth = createNamespacedHelpers('auth')
import { sendTrackingEvent } from '@/utils/eventTracker'
import { $t } from '@/plugins/i18n'
import { UserDatabase } from '../db/user-database'
// import { PLANS } from '../utils/plans'
import { ID } from '../../shared/models/models'
import { PLANS } from '../utils/plans'
import { UserType } from '../../shared/models/user'

@Component({
  components: {},
  computed: {
    ...auth.mapState(['uid']),
  },
})
export default class UpdateCustomerPage extends Vue {
  uid!: ID

  userId: ID | null = null
  user: BusinessOwner | null = null

  billingInfo: BillingInfoInput & { id?: ID } = {
    id: undefined,
    name: '',
    billingName: '',
    billingPostalCode: '',
    billingAddress: '',
    telNo: '',
    email: '',
  }

  planId = ''
  plans = PLANS

  status: UserStatus | null = null
  statuses = [
    {
      label: `${UserStatus.ENABLED}`,
      value: UserStatus.ENABLED,
    },
    {
      label: `${UserStatus.DISABLED}`,
      value: UserStatus.DISABLED,
    },
  ]

  isSaving = false

  get isSaveEnabled() {
    return (
      !!this.billingInfo.name &&
      !!this.billingInfo.billingName &&
      !!this.billingInfo.billingPostalCode &&
      !!this.billingInfo.billingAddress &&
      !!this.billingInfo.telNo &&
      !!this.billingInfo.email &&
      !!this.planId
    )
  }

  async created() {
    const { userId } = this.$route.params
    this.userId = userId

    this.user = null
    this.billingInfo = {
      id: undefined,
      name: '',
      billingName: '',
      billingPostalCode: '',
      billingAddress: '',
      telNo: '',
      email: '',
    }

    if (userId) {
      const userDb = new UserDatabase({
        client: getFirestore(app),
      })
      this.user = (await userDb.getObj(userId)) as BusinessOwner | null
      if (this.user) {
        this.planId = this.user.planId || ''
        this.status = this.user.status || null
      }

      const billingInfoDb = new BillingInfoDatabase({
        client: getFirestore(app),
      })

      const billingInfo = await billingInfoDb.getObj(userId)
      if (billingInfo) {
        this.billingInfo = billingInfo
      }
    }
  }

  back() {
    this.$router.back()
  }

  async save() {
    this.isSaving = true

    const userDb = new UserDatabase({
      client: getFirestore(app),
    })
    const billingInfoDb = new BillingInfoDatabase({
      client: getFirestore(app),
    })

    const phoneNumber =
      normalizePhoneNumber(this.billingInfo.telNo) || this.billingInfo.telNo

    const existingUser = await userDb.getUserByPhoneNumber(phoneNumber)
    const userId =
      existingUser?.id || this.user?.id || `preregister:${phoneNumber}`

    const isNewUser = !existingUser && !this.user
    const isNewBillingInfo = !this.billingInfo.id

    const billingInfo = {
      ...this.billingInfo,
      telNo: phoneNumber,
      photoURL: this.user?.photoURL || null,
      createdBy: this.uid,
      updatedAt: new Date(),
    }
    delete billingInfo.id
    if (isNewBillingInfo) {
      ;(billingInfo as unknown as { createdAt: Date }).createdAt = new Date()
    }

    const user: BusinessOwnerInput = {
      name: this.billingInfo.name,
      businessName: this.billingInfo.billingName,
      ...(this.user || {}),
      type: UserType.BUSINESS_OWNER,
      phoneNumber,
      paymentMethod: PaymentMethod.INVOICE,
      planId: this.planId,
      status: this.status,
      createdBy: this.uid,
      updatedAt: new Date(),
    }
    if (isNewUser) {
      user.createdBy = this.uid
      user.status = UserStatus.PREREGISTER_WITH_PHONE_NUMBER
      user.isPreregisteredBySalePartner = true
      ;(user as unknown as { createdAt: Date }).createdAt = new Date()
    }

    billingInfoDb
      .set(userId, billingInfo, { merge: true })
      .then(() => userDb.set(userId, user, { merge: true }))
      .then(() => {
        alert($t('create_customer.customer_is_saved'))
        this.$router.back()
      })
      .catch((error) => {
        console.error(error)
        alert($t('create_customer.error_customer_is_not_saved'))
      })
      .finally(() => (this.isSaving = false))
    sendTrackingEvent(
      `${this.$route.path}:save`,
      { user, billingInfo },
      this.uid
    )
  }
}
