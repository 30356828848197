export function debounce(func: () => void, wait: number, immediate?: boolean) {
  let timeout: NodeJS.Timeout | null

  return function executedFunction() {
    /* eslint-disable */
    const context: any = this
    const args = arguments
    /*eslint-enable */

    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args as any)
    }

    const callNow = immediate && !timeout

    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args as any)
  }
}

export function getBrowserLanguage() {
  let language = navigator.language.toLowerCase()
  if (language.includes('-')) {
    language = language.split('-')[0]
  }

  if (!['en', 'ja'].includes(language)) {
    language = 'ja'
  }

  return language
}

export function randomString(length: number) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
