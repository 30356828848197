



































































































































import { Component, Vue } from 'vue-property-decorator'
import { PLANS } from '../utils/plans'
import { createNamespacedHelpers } from 'vuex'
import { BusinessOwner } from '../../shared/models/business-owner'
import { getCoupon, savePaymentMethodId } from '../helpers/functions'
const auth = createNamespacedHelpers('auth')
import { loadStripe, Stripe, StripeCardNumberElement } from '@stripe/stripe-js'
import { debounce } from '../utils/utils'
import { $t } from '@/plugins/i18n'
import { sendTrackingEvent } from '@/utils/eventTracker'

// Reference: https://stripe.com/docs/billing/subscriptions/fixed-price

const STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || ''

@Component({
  components: {},
  computed: {
    ...auth.mapState(['uid', 'user']),
  },
  methods: {
    ...auth.mapMutations(['updateAuthStatus']),
  },
})
export default class RegisterCreditCard extends Vue {
  uid!: string
  user!: BusinessOwner
  updateAuthStatus!: (user?: BusinessOwner) => void

  billingName = ''
  billingEmail = ''

  stripe: Stripe | null = null
  plan: any

  cardNumberElement: StripeCardNumberElement | null = null

  isCreditCardInputMounted = false
  cardNumberError: string = $t(
    'register_credit_card.please_confirm_card_number'
  )
  // cardExpirationDate: '',
  cardExpirationDateError = $t(
    'register_credit_card.please_confirm_expiration_date'
  )
  // cardCvv: '',
  cardCvvError = $t('register_credit_card.please_confirm_cvv')
  shouldInputCouponCode = false
  couponCode = ''
  couponDescriptions: { [key: string]: string } = {}
  coupons: { [key: string]: string } = {}
  showNowLoading = false

  showSuccessMessageAlert = false

  created() {
    console.log({ planId: this.user.planId })
    this.plan = PLANS.find((v) => v.id === this.user.planId)

    this.billingName =
      this.user.billingName || this.user.name || this.user.businessName || ''
    this.billingEmail = this.user.billingEmail || this.user.email || ''

    this.setupCardForm()
  }

  mounted() {
    const payWithInvoiceATag = document.getElementById('pay_with_invoice')
    if (payWithInvoiceATag) {
      payWithInvoiceATag.addEventListener('click', this.payWithInvoice)
    }
  }

  beforeUnmount() {
    const payWithInvoiceATag = document.getElementById('pay_with_invoice')
    if (payWithInvoiceATag) {
      payWithInvoiceATag.removeEventListener('click', this.payWithInvoice)
    }
  }

  payWithInvoice(event: MouseEvent) {
    event.preventDefault()
    this.$router.replace('/register-billing-info')
  }

  setupCardForm() {
    if (this.isCreditCardInputMounted) {
      return
    }

    loadStripe(STRIPE_PUBLISHABLE_KEY).then((stripe) => {
      this.stripe = stripe

      if (!stripe) {
        return
      }

      var style = {
        base: {
          fontFamily: 'Helvetica',
          fontSize: '16px',
        },
      }

      var elements = stripe.elements({
        locale: 'ja',
      })

      // card-number
      this.cardNumberElement = elements.create('cardNumber', {
        style,
        placeholder: '0000 0000 0000 0000',
      })
      this.cardNumberElement.on('ready', () => {
        this.isCreditCardInputMounted = true
      })
      this.cardNumberElement.on('change', (data) => {
        let errorMessage: string | null = null
        if (data.empty) {
          errorMessage = $t('register_credit_card.please_confirm_card_number')
        } else if (data.error) {
          errorMessage = data.error.message
        }
        this.cardNumberError = errorMessage || ''
      })
      this.cardNumberElement.mount('#card-number')

      // card-expiry
      const cardExpiryElement = elements.create('cardExpiry', {
        style,
        placeholder: 'mm/yy',
      })
      cardExpiryElement.on('change', (data) => {
        let errorMessage: string | null = null
        if (data.empty) {
          errorMessage = $t(
            'register_credit_card.please_confirm_expiration_date'
          )
        } else if (data.error) {
          errorMessage = data.error.message
        }
        this.cardExpirationDateError = errorMessage || ''
      })
      cardExpiryElement.mount('#card-expiry')

      // card-cvc
      const cardCvvElement = elements.create('cardCvc', {
        style,
        placeholder: '0000',
      })
      cardCvvElement.on('change', (data) => {
        let errorMessage: string | null = null
        if (data.empty) {
          errorMessage = $t('register_credit_card.please_confirm_cvv')
        } else if (data.error) {
          errorMessage = data.error.message
        }
        this.cardCvvError = errorMessage || ''
      })
      cardCvvElement.mount('#card-cvv')
    })
  }

  handleChangeCouponCode = debounce(async () => {
    const couponCode = this.couponCode
    if (couponCode && !this.couponDescriptions[couponCode]) {
      const result = (await getCoupon(this.couponCode).catch(() => {})) || {}
      const coupon = result.coupon
      if (coupon) {
        this.couponDescriptions = {
          ...this.couponDescriptions,
          [couponCode]: coupon['name'],
        }
        this.coupons = {
          ...this.coupons,
          [couponCode]: coupon,
        }
      }
    }
  }, 200)

  back() {
    this.$router.back()
  }

  async submit() {
    if (this.cardNumberError) {
      alert(this.cardNumberError)
      return
    }

    if (!this.billingName) {
      alert($t('register_credit_card.please_input_name'))
      return
    }

    if (this.cardExpirationDateError) {
      alert(this.cardExpirationDateError)
      return
    }

    if (this.cardCvvError) {
      alert(this.cardCvvError)
      return
    }

    if (this.shouldInputCouponCode && this.couponCode) {
      if (!this.couponDescriptions[this.couponCode]) {
        this.showNowLoading = true
        const result = (await getCoupon(this.couponCode).catch(() => {})) || {}
        const coupon = result.coupon
        if (!coupon) {
          this.showNowLoading = false
          alert($t('register_credit_card.copoun_code_is_invalid'))
          return
        }
      }
    }

    this.showNowLoading = true

    const result = await this.stripe!.createPaymentMethod({
      card: this.cardNumberElement!,
      type: 'card',
      billing_details: {
        name: this.billingName,
        email: this.billingEmail,
      },
    }).catch((error) => {
      this.showNowLoading = false
      if (error['message']) {
        alert(error['message'])
      } else {
        alert($t('register_credit_card.please_confirm_card_information'))
      }
      throw error
    })

    if (result && result.paymentMethod) {
      return savePaymentMethodId({
        paymentMethodId: result.paymentMethod.id,
        billingName: this.billingName,
        billingEmail: this.billingEmail,
      })
        .then(({ success, stripeCustomerId }) => {
          if (success) {
            // alert($t('register_credit_card.message_success'))
            this.showSuccessMessageAlert = true
            this.updateAuthStatus({
              ...this.user,
              billingName: this.billingName,
              billingEmail: this.billingEmail,
              stripeCustomerId: stripeCustomerId || null,
            })
          } else {
            alert($t('register_credit_card.message_error'))
          }
          sendTrackingEvent(
            `${this.$route.path}:submit`,
            {
              success,
              extra: {
                paymentMethodId: result.paymentMethod.id,
                billingName: this.billingName,
                billingEmail: this.billingEmail,
              },
            },
            this.uid
          )
        })
        .finally(() => (this.showNowLoading = false))
    } else {
      alert($t('register_credit_card.please_confirm_card_information'))
    }

    this.showNowLoading = false
  }

  goToHome() {
    this.showSuccessMessageAlert = false
    this.$router.push('/')
    sendTrackingEvent(`${this.$route.path}:goToHome`, {}, this.uid)
  }
}
