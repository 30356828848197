<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-title>{{ $t('landing.title') }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="back">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <landing-page-generator></landing-page-generator>
    </v-main>
  </v-app>
</template>

<script>
// import LoadingDialog from '@/components/LoadingDialog'
import LandingPageGenerator from '@/components/LandingPageGenerator.vue'
import { mapState } from 'vuex'
// import * as Sentry from '@sentry/browser'
// import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    LandingPageGenerator,
    // LoadingDialog,
  },
  computed: {
    ...mapState('auth', ['uid']),
  },
  data() {
    return {}
  },
  // watch: {
  //   uid: {
  //     immediate: true,
  //     handler(uid) {
  //       if (uid) {
  //         this.setup(uid)
  //       }
  //     },
  //   },
  // },
  methods: {
    back() {
      this.$router.back()
    },
    // async setup(uid) {
    //   const lpSiteDb = new LPSiteDatabase()
    //   const lpDeployDb = lpSiteDb.getLPDeployDb(uid)
    //   const lpPreviewDb = lpSiteDb.getLPPreviewDb(uid)

    //   this.jobTypes = ['default']
    //   const live = await lpDeployDb.getCurrentData()
    //   const preview = await lpPreviewDb.getCurrentData(uid)
    //   this.hasLive = Boolean(live)
    //   this.hasPreview = Boolean(preview)
    //   const { pageScriptPreloadedListeners, jobTypePreloadedListeners } =
    //     await listeners({ live, preview })
    //   this.pageScriptPreloadedListeners = pageScriptPreloadedListeners
    //   this.jobTypePreloadedListeners = jobTypePreloadedListeners
    //   this.loading = false
    // },
  },
  mounted() {
    // analyticsEvent(EVENT_NAMES.LOADED_LANDING_PAGE_GENERATOR)
  },
}
</script>

<style></style>
