











































import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { BusinessOwner } from '../../../shared/models/business-owner'
import { $t } from '@/plugins/i18n'
import { createShortenUrl } from '../../helpers/shorten-url'
import { getPublicUrl } from '../../utils/qrcode'
import { UserDatabase } from '../../db/user-database'
import { getFirestore } from '@firebase/firestore'
import { app } from '../../helpers/firebase'
import { sendTrackingEvent } from '../../utils/eventTracker'

@Component({
  components: {},
  props: {
    value: Boolean,
    currShortenUrl: String,
  },
  computed: {
    ...mapStateAuth(['uid', 'user']),
  },
})
export default class extends Vue {
  uid!: string
  user!: BusinessOwner

  shortenUrlRegex = /^[0-9a-zA-Z.-_]{4,}$/

  shortenUrl = ''

  isSaving = false

  get isEnabledSaveButton() {
    return !!this.shortenUrl && this.shortenUrlRegex.test(this.shortenUrl)
  }

  created() {
    this.shortenUrl =
      (this.$props.currShortenUrl as string).split('/').pop() || ''
  }

  async handleSave() {
    this.isSaving = true

    if (
      this.shortenUrl ===
      (this.$props.currShortenUrl as string).split('/').pop()
    ) {
      // do nothing
      this.$emit('input', false)
    } else {
      let result = await createShortenUrl(getPublicUrl(this.uid), {
        fixedId: this.shortenUrl,
      }).catch(() => null)

      console.log({ result })
      if (result && result.shortenUrl) {
        // OK
        let userDb = new UserDatabase({
          client: getFirestore(app),
        })
        await userDb.update(this.uid, {
          shortenUrl: result.shortenUrl,
        })
        this.$emit('onShortenUrlUpdated', result.shortenUrl)
        this.$emit('input', false)
        sendTrackingEvent(
          `${this.$route.path}:handleSaveQrCode`,
          result,
          this.uid
        )
      } else {
        alert($t('change_qr_code.error_cant_change_qr_code'))
      }
    }

    this.isSaving = false
  }
}
