var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.value,"persistent":"","width":"290px"},on:{"update:return-value":function (val) {
      _vm.$emit('input', val)
      _vm.$emit('change', val)
    }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.value,"label":"募集の開始日を入力してください。","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c('v-date-picker',{attrs:{"value":_vm.value,"full-width":""},on:{"input":function (val) {
        _vm.$emit('input', val)
        _vm.$emit('change', val)
      }}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" "+_vm._s(_vm.$t('create_slot.button_cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.value)}}},[_vm._v(" "+_vm._s(_vm.$t('create_slot.button_ok'))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }