


























































































import { getFirestore } from '@firebase/firestore'
import { format } from 'date-fns'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { SmsHistory } from '../../shared/models/sms-history'
import { Notification } from '../../shared/models/notification'
import { NotificationDatabase } from '../db/notification-database'
import { UserDatabase } from '../db/user-database'
import { app } from '../helpers/firebase'
import { DEFAULT_NOTIFICATION_CONTENT_MAP } from './UpdateNotificationPage.vue'
import { Applicant } from '../../shared/models/applicant'
import { sendSms } from '../helpers/functions'
import { sendTrackingEvent } from '../utils/eventTracker'
import { $t } from '@/plugins/i18n'

const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

@Component({
  components: {},
  computed: {
    ...mapStateAuth(['uid']),
  },
})
export default class Settings extends Vue {
  uid!: string

  applicant?: Applicant | null = null
  smsHistories: (SmsHistory & { notification?: Notification })[] = []
  notifications: Notification[] = []

  inputMessage = ''
  isSendingMessage = false

  async created() {
    let applicantId = this.$route.params.applicantId
    console.log({
      uid: this.uid,
      applicantId,
    })
    if (applicantId) {
      const userDb = new UserDatabase({
        client: getFirestore(app),
      })
      this.applicant = (await userDb.getObj(applicantId)) as Applicant | null

      const smsHistoryDb = userDb.getSmsHistoryDb(this.uid)
      this.smsHistories = this.addSections(
        await smsHistoryDb.getSmsListByApplicantId(applicantId)
      )

      const notificationDb = new NotificationDatabase({
        client: getFirestore(app),
      })
      this.notifications = await notificationDb.getNotifications({
        userId: this.uid,
      })

      this.smsHistories = this.addSections(
        this.smsHistories.map((v) => ({
          ...v,
          notification: v.notificationId
            ? this.notifications.find((v2) => v2.id === v.notificationId)
            : undefined,
        }))
      )
    }
  }

  addSections(
    smsHistories: (SmsHistory & {
      section?: string
      createdAtLabel?: string
    })[]
  ) {
    smsHistories.map((v, index) => {
      const prevV = index > 0 ? smsHistories[index - 1] : undefined
      if (!v.createdAtLabel) {
        v.createdAtLabel = this.formatDate(v.createdAt)
      }

      if (prevV && prevV.createdAtLabel === v.createdAtLabel) {
        v.section = undefined
      } else {
        v.section = v.createdAtLabel
      }
    })

    return smsHistories
  }

  back() {
    this.$router.back()
  }

  sendMessage() {
    if (this.isSendingMessage) {
      return
    }

    if (
      this.inputMessage &&
      this.inputMessage.trim() &&
      this.applicant &&
      this.applicant.phoneNumber
    ) {
      this.isSendingMessage = true
      sendSms({
        toUserId: this.applicant.id,
        phoneNumber: this.applicant.phoneNumber,
        content: this.inputMessage.trim(),
      })
        .then((v) => {
          if (v.sent) {
            // alert($t('sms_history.message_is_sent'))
            this.inputMessage = ''
          } else {
            alert($t('sms_history.cant_send_message'))
          }
          if (v.smsHistory) {
            this.smsHistories = this.addSections([
              ...this.smsHistories,
              {
                ...v.smsHistory,
                createdAt: new Date(v.smsHistory.createdAt._seconds * 1000),
              },
            ])
          }
        })
        .catch((e) => {
          console.error(e)
          alert($t('sms_history.cant_send_message'))
        })
        .finally(() => (this.isSendingMessage = false))
      sendTrackingEvent(
        `${this.$route.path}:sendMessage`,
        {
          toUserId: this.applicant.id,
          phoneNumber: this.applicant.phoneNumber,
          content: this.inputMessage,
        },
        this.uid
      )
    }
  }

  formatDate(date: Date) {
    return format(date, 'yyyy-MM-dd')
  }

  formatTime(date: Date) {
    return format(date, 'HH:mm')
  }

  getNotificationContent(item: SmsHistory & { notification?: Notification }) {
    return item.content || this.getDefaultNotificationContent(item.notification)
  }

  getDefaultNotificationContent(notification?: Notification) {
    if (notification) {
      return DEFAULT_NOTIFICATION_CONTENT_MAP[notification.type]
    } else {
      return ''
    }
  }
}
