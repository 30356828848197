export const LOCALE_EN = {
  common: {
    test_text: 'This is a test text',
    'slot_{no}': 'Slot #{no}',
    status: {
      // statuses
      enabled: 'Enabled',
      disabled: 'Disabled',
      // slot statues
      open: 'Open',
      closed: 'Closed',
      full: 'Full',
      // meeting statuses
      confirmed: 'Confirmed',
      cancelled: 'Cancelled',
      pending: 'Pending',
      rescheduled: 'Rescheduled',
    },
    sms_status: {
      sent: 'Sent',
      delivered: 'Delivered',
      failed: 'Failed',
    },
    language: {
      en: 'English',
      ja: '日本語',
    },
  },

  home: {
    configure_availabe_slots: 'Configure Available Slots',
    button_today: 'Today',
    button_calendar: 'Calendar',
    button_list: 'List',
    button_administrators: 'Administrators',
    button_customers: 'Customers',
    button_accept_interviews: 'Accept interviews?',
    button_payment: 'Payment',
    button_settings: 'Settings',
    button_developer: 'Developer',
    button_qrcode: 'QR Code',
    button_google_search_job: 'Jobs',
    button_copy_public_url: 'Copy Public URL',
    button_sign_out: 'Sign out',
    section_confirmed_slots: 'Confirmed slots',
    section_available_slots: 'Available slots',
    section_finished_slots: 'Finished slots',
    status: 'Status',
    time: 'Time',
    applicant_name: 'Applicant Name',
    applicant_phone: 'Applicant Phone',
    gmeet_url: 'Google meet URL',
    button_send_text_message: 'Send text message',
    cancel_this_meeting: 'Cancel this meeting',
    delete_this_slot: 'Delete this slot',
    public_url_is_copied: 'Public URL is copied to clipboard',
    do_you_want_to_sign_out: 'Do you want to sign out?',
    do_you_want_to_cancel_this_meeting: 'Do you want to cancel this meeting?',
    meeting_has_been_canceled: 'Meeting has been canceled',
    cant_cancel_meeting: "Can't cancel this meeting. Please try again later.",
    do_you_want_to_delete_this_slot: 'Do you want to delete this slot?',
    cant_delete_slot: "Can't delete this slot. Please try again later.",
    you_turned_off_acceptance_of_interviews:
      'You turned off the acceptance of interviews.',
    you_turned_on_acceptance_of_interviews:
      'You turned on the acceptance of interviews.',
    cant_change_acceptance_of_interviews:
      "Can't change the acceptance of interviews. Please try again later.",
    no_data_to_display: 'No data to display',
  },

  sign_in: {
    hi: 'Hi',
    welcome_to_mensetsu_yoyaku: 'Welcome to Mensetsu Yoyaku!',
    button_sign_in_with_phone_number: 'Sign In With Phone Number',
    button_sign_in_with_email: 'Sign In With Email',
  },

  sign_in_with_phone_number: {
    title_sign_in_with_phone_number: 'Sign in with Phone Number',
    button_next: 'Next',
    button_change_phone_number: 'Change phone number',
    phone_number_is_invalid: 'The phone number is invalid',
    verification_code: 'Verification code',
    an_error_has_occurred: 'An error has occurred',
    verification_code_is_invalid: 'The verification code is invalid',
    button_confirm: 'Confirm',
  },

  payment_overview: {
    title_payment_overview: 'Payment overview',
    button_change_plan: 'Change',
    this_month: 'This month',
    confirmed_mettings_count: 'Confirmed mettings',
    estimated_amount: 'Estimated amount',
    payment_info: 'Payment info',
    name: 'Name',
    email: 'Email',
    credit_card_number: 'Credit Card Number',
    button_change_credit_card: 'Change',
    unpaid_invoice: 'Unpaid invoice',
    'payment_for_{month}': 'Payment for {month}',
    amount: 'Amount',
    status: 'Status',
    button_pay_now: 'Pay now',
    invoice_payment: 'Pay with invoice',
    credit_card_payment: 'Pay with credit card',
  },

  qr_code: {
    title_qr_code: 'QR Code',
    this_your_qr_code: 'This your qr code',
    public_url: 'Public URL',
    public_url_is_copied: 'Public URL is copied to clipboard',
    button_change_qr_code: 'Change QR Code',
  },

  change_qr_code: {
    title_change_qr_code: 'Change QR Code',
    button_save: 'Save',
    error_cant_change_qr_code: "Can't change QR code. Please try again later.",
  },

  register_credit_card: {
    title_register_credit_card: 'Register credit card',
    billing_name: 'Billing name',
    billing_email: 'Billing email',
    card_number: 'Card number',
    expiration_date: 'Exp. date',
    cvv: 'CVV',
    button_those_who_have_coupon_code: 'Those who have a coupon code ',
    coupon_code: 'Coupon code',
    button_submit: 'Submit',
    please_confirm_card_number: 'Please confirm Card number',
    please_confirm_expiration_date: 'Please confirm Expiration date',
    please_confirm_cvv: 'Please confirm CVV',
    please_input_name: 'Please input name',
    copoun_code_is_invalid: 'Coupon code is invalid',
    please_confirm_card_information: 'Please confirm Card information',
    message_success: 'Success',
    message_error: 'Error',
    button_open_app: 'Open the app',
    html_pay_with_invoice:
      'Click <a id="pay_with_invoice">here</a> if you would like to pay by invoice',
  },

  register_billing_info: {
    title_register_billing_info: 'Billing Information',
    description_1:
      "<b>Payment:</b><br />At the beginning of the month, we will send you an invoice for the previous month's usage fee by email. Payment will be by bank transfer.",
    description_2:
      '<b>Fee: </b><br />Monthly fee {pricePerMonth} yen. Free interview setting {freeQuota} times / month = {pricePerInterview} yen per interview<br />{pricePerAdditionalInterview} yen for each additional interview',
    description_2_no_free_quota:
      '<b>Fee: </b><br />Monthly fee {pricePerMonth} yen<br />{pricePerAdditionalInterview} yen for each additional interview',
    billing_information: 'Billing information:',
    name: 'Name',
    placeholder_name: '山田太郎',
    email: 'Email',
    placeholder_email: 'example@tokyo.jp',
    tel_no: 'Phone Number',
    placeholder_tel_no: '08012345678（半角数字ハイフンなし）',
    billing_name: 'Billing Name',
    placeholder_billing_name: '株式会社 Example',
    billing_postal_code: 'Postal Code',
    placeholder_billing_postal_code: '1112222（半角数字ハイフンなし）',
    billing_address: 'Address',
    placeholder_billing_address: '東京都 渋谷区 1-2-3 exampleビル 101',
    button_save: 'Start using with invoice payment',
    message_billing_information_is_saved: 'Billing information is saved',
    error_billing_information_is_not_saved:
      'Billing information is not saved. Please try again later.',
    html_pay_with_credit_card:
      'If you would like to pay by credit card, please click <a id="pay_with_credit_card">here</a>',
  },

  select_plan: {
    title_select_plan: 'Select plan',
    button_select_plan: 'Select plan',
    plan_starter_description:
      '0円 for monthly subscription<br />1,000 JPY per interview confirmation',
    plan_tiny_description:
      '1,000 JPY per month (subscription)<br />5 free interview confirmations included<br />500 JPY per additional interview confirmation',
    plan_small_description:
      '5,000 JPY per month (subscription)<br />25 free interview confirmations included<br />500 JPY per additional interview confirmation',
    plan_medium_description:
      '1,0000 JPY per month (subscription)<br />50 free interview confirmations included<br />500 JPY per additional interview confirmation',
  },

  settings: {
    title_settings: 'Settings',
    sign_in_to_google_calendar: 'Sign in to Google Calendar',
    button_sign_in_to_google_calendar: 'Sign in with Google',
    sign_in_to_google_calendar_description:
      'When a meeting is confirmed, an event will automatically be created in your primary calendar',
    coming_soon: 'Coming soon',
    checking_google_linkage_status: 'Checking Google linkage status',
    unlink_google: 'Disconnect',
    google_unlink_notice:
      'Are you sure you want to disconnect from Google account? We will not be able to automatically add your interview to you calendar after this.',
    you_have_already_linked_with_google_calendar:
      'You have already linked with Google calendar',
    we_re_signing_you_on_with_google:
      "Please check back after a minute, we're signing you on with Google",
    profile: 'Profile',
    business_name: 'Business Name',
    phone_number: 'Phone Number',
    notifications: 'Notifications',
    section_language: 'Language',
    language: 'Language',
    personal_token: 'API Token',
    do_not_share_token:
      "Please do not share this token with anyone you don't trust. Please see the API Doc <a href='https://docs.myol.xyz/'>here</a>.",
    generate_personal_token: 'Click here to generate',
    loading_personal_token: 'Loading...',
    personal_token_not_ready: 'Not ready',
    personal_token_ready:
      'API Toke is ready. <i><small>(Expires in {expirationDate} day(s))</small></i>',
    personal_token_new: 'Create a New Token',
    personal_token_confirm_reset:
      'This action will delete the current token and create a new one, you will not be able to use current token any more. Are you sure you want to continue?',
    personal_token_expired: 'Previous token expired {expirationDate} days ago.',
    personal_token_copied:
      "Personal token has been copied. Do not share this token with anyone you don't trust.",
    reconnect: 'Reconnect',
  },

  update_notification: {
    title_update_notification: 'Detail of Notification',
    notification_content: 'Notification to be sent',
    button_save: 'Save',
    title_after_meeting_confirmation: 'Right after the meeting is confirmed',
    title_meeting_next_x_day: 'At 8:00 AM on the previous day of the meeting',
    title_meeting_in_x_minute: '{x} min before the meeting',
    title_metting_after_x_minute: '{x} hours after the meeting is held',
    title_metting_after_x_hours_to_business_owner:
      '{x} hours after the meeting is held (to be sent to you)',
    title_metting_after_x_days_to_business_owner:
      '{x} days after the meeting is held (to be sent to you)',
    gmeet_url_text: ', at {GMEET_URL}',
    notification_after_meeting_confirmation:
      '{APPLICANT_NAME}, Your interview has just been confirmed{GMEET_URL_TEXT}. Thanks. — {BUSINESS_NAME}, {BUSINESS_PHONE_NUMBER}',
    notification_meeting_next_x_day:
      "{APPLICANT_NAME}, Your interview will be held tomorrow at {MEETING_START_TIME}{GMEET_URL_TEXT}. We're looking forward to meeting you then. Thanks. — {BUSINESS_NAME}, {BUSINESS_PHONE_NUMBER}",
    notification_meeting_in_x_minute:
      "{APPLICANT_NAME}, Your interview will be held in 10 min at {MEETING_START_TIME}{GMEET_URL_TEXT}. We're looking forward to meeting you then. Thanks. — {BUSINESS_NAME}, {BUSINESS_PHONE_NUMBER}",
    notification_metting_after_x_minute:
      '{APPLICANT_NAME}, Thank you very much again for your time for your interview{GMEET_URL_TEXT}. — {BUSINESS_NAME}, {BUSINESS_PHONE_NUMBER}',
    notification_metting_after_x_hour_to_business_owner: `Please take a note of the interview. 
    
Did you interview {APPLICANT_NAME}({APPLICANT_PHONE_NUMBER})?
Click {URL_YES} if YES.
Click {URL_NO} if NO.
Click {URL_NO_SHOW} if NO-SHOW.`,
    notification_metting_after_x_days_to_business_owner: `Please take another note of the interview.

Did you decide to hire {APPLICANT_NAME}({APPLICANT_PHONE_NUMBER})? 
Click {URL_YES} if YES.
Click {URL_NO} if NO.`,
  },

  meeting_answer: {
    answer_success: 'Successfully answered',
    answer_error: 'Failed answering',
  },

  update_profile: {
    title_update_profile: 'Update profile',
    business_name: 'Business name',
    phone_number: 'Phone number',
    button_save: 'Save',
  },

  select_a_slot: {
    title_select_a_slot: 'Select a slot',
    button_today: 'Today',
    button_confirm: 'Confirm',
    placeholder_display_name: 'Display name',
    placeholder_phone_number: '0123456789',
    button_ok: 'OK',
    button_back: 'Back',
    thank_you: 'Thank you',
    we_ll_contact_you_later:
      "We'll contact you later. Meanwhile, you can create your calendar event using the below links",
    note: 'Note',
    interviews_are_not_accepted: 'Interviews are not accepted',
  },

  calendar: {
    event_calendar_title: 'Interview for {businessName} for {applicantName}',
    event_calendar_description: `
TITLE: ONLINE INTERVIEW
BUSINESS_NAME: {businessName}
APPLICANT_NAME: {applicantName}
APPLICANT_PHONE: {applicantPhone}
INTERVIEW_DATETIME: {interviewDateTime}
MEETING_ROOM: {meetingRoomUrl}
CONFIRMED_AT: {confirmedAt}
----------
Mensetsu Yoyaku ONLINE(TM) created this event.
https://www.mensetsuyoyaku.online/
`,
  },

  create_slot: {
    create_slot_title: 'Add a slot',
    update_slot_title: 'Update slot',
    button_cancel: 'Cancel',
    button_delete: 'Delete',
    button_ok: 'OK',
    date: 'Date (*)',
    start_time: 'Start time (*)',
    duration: 'Duration (*)',
    duration_unit: 'minutes',
    status: 'Status (*)',
    status_available: 'Available',
    status_confirmed: 'Confirmed',
    applicant_name: 'Applicant Name (*)',
    applicant_phone_number: 'Phone Number (*)',
    indicates_required_field: `*indicates required field`,
    button_add: 'Add',
    button_update: 'Update',
  },

  sms_history: {
    placeholder_please_input_message: 'Please input message...',
    message_is_sent: 'Message is sent',
    cant_send_message: "Can't send a message. Please try again later.",
  },

  developer: {
    title_developer: 'Developer',
    variables: 'Variables',
    copied: 'Copied {text} to clipboard',
    variable_description: {
      APPLICANT_NAME: 'Name of the applicant',
      APPLICANT_PHONE_NUMBER: 'Phone number of the applicant',
      BUSINESS_NAME: 'Your business name',
      BUSINESS_PHONE_NUMBER: 'Your business phone number',
      MEETING_START_TIME:
        "The meeting's start time, in Japan format. eg: 2022/12/24 08:30",
      MEETING_START_TIME_JP:
        "The meeting's start time, in Japan format. eg: 2022/12/24 08:30",
      MEETING_START_TIME_EU:
        "The meeting's start time, in EU format. eg: 24/12/2022 08:30",
      MEETING_START_TIME_US:
        "The meeting's start time, in US format. eg: 12/24/2022 08:30",
      MEETING_START_DATE:
        "The meeting's start date, in Japan format. eg: 2022/12/24",
      MEETING_START_DATE_JP:
        "The meeting's start date, in Japan format. eg: 24/12/2022",
      MEETING_START_DATE_EU:
        "The meeting's start date, in EU format. eg: 24/12/2022",
      MEETING_START_DATE_US:
        "The meeting's start date, in US format. eg: 12/24/2022",
      GMEET_URL: "The URL to join meeting's Google Meet conference",
    },
    embed_onto_your_website: 'Embed onto your website',
    embed_code: 'Embed code',
    embed_code_is_copied: 'Embed code is copied',
    note: `Note:
You can prefill applicant's information by modifying below attributes:
  - data-applicant-name 
  - data-applicant-phone-number
  - data-applicant-email
  - data-note (eg) Position, Referer`,
  },

  landing: {
    title: 'Website Page Settings',
    saved: 'Saved',
    updateJob_1: 'Please update your job types in',
    updateJob_2: 'before using the landing page generator.',
    store: {
      title: 'Store page',
      publicURL: 'Store public URL',
      previewURL: 'Store preview URL',
    },
    jobs: {
      title: 'Jobs Page',
      publicURL: ' public URL',
      previewURL: ' preview URL',
      pageOf: ' page',
    },
    addJob: 'Add Job',
    notFilled: 'Some fields are missing values.',
    save: 'Save and Preview',
    upload: 'Deploy Website',
    newJob: 'New Job',
    button_add: 'Add',
    button_cancel: 'Cancel',
  },

  customers: {
    title_customers: 'Customers',
    loading: 'Loading...',
    button_export_csv: 'Export CSV',
    button_add_customer: 'Add customer',
    field_id: 'ID',
    field_photoURL: ' ',
    field_name: 'Name',
    field_email: 'Email',
    field_telNo: 'Tel no',

    field_paymentMethod: 'Payment method',
    field_planId: 'Plan',
    field_pricePerMonth: 'Price per month',
    field_freeQuota: 'Free quota',
    field_pricePerAdditionalConfirmation: 'Price per additional interview',

    field_interviewsCount: 'Interviews count',
    field_billingAmount: 'Billing amount',
    field_billingName: 'Billing name',
    field_billingPostalCode: 'Billing postal code',
    field_billingAddress: 'Billing address',

    field_status: 'Status',
    field_createdAt: 'Created at',
    field_actions: 'Actions',
  },

  create_customer: {
    title_create_customer: 'Add customer',
    title_update_customer: 'Update customer',
    plan: 'Plan',
    placeholder_plan: 'Plan',
    button_save: 'Save',
    customer_is_saved: 'Customer is saved',
    error_customer_is_not_saved:
      'Customer is not saved. Please try again later.',
  },

  administrators: {
    title_administrators: 'Administrators',
    button_add_administrator: 'Add administrator',
    do_you_want_to_delete_administrator:
      'Do you want to delete this administrator?',
    you_deleted_administrator: 'The administrator has been deleted.',
    error_cant_delete_administrator:
      "Can't delete this administrator. Please try again later.",
  },

  create_administrator: {
    title_create_administrator: 'Add administrator',
    phone_number: 'Phone number',
    button_add: 'Add',
    error_cant_find_user_associated_with_phone_number:
      "Can't find user associated with this phone number",
    error_please_confirm_phone_number: 'Please confirm phone number',
  },
}
