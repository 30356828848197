var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":""}},[_c('v-app-bar-title',[_vm._v(_vm._s(_vm.$t('customers.title_customers')))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.back}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-main',[_c('v-container',{staticClass:"d-flex flex-column"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.businessOwners,"items-per-page":50,"loading":_vm.isLoading,"loading-text":_vm.$t('customers.loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('customers.title_customers')))]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.exportCSV}},[_vm._v(" "+_vm._s(_vm.$t('customers.button_export_csv'))+" ")]),(_vm.uid === _vm.selectedAdminId)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.createCustomer}},[_vm._v(" "+_vm._s(_vm.$t('customers.button_add_customer'))+" ")]):_vm._e()],1),(_vm.isSuperAdmin)?_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"mb-2 select-admin",attrs:{"label":_vm.$t('administrators.title_administrators'),"persistent-placeholder":"","items":_vm.admins,"item-text":"label","item-value":"id"},model:{value:(_vm.selectedAdminId),callback:function ($$v) {_vm.selectedAdminId=$$v},expression:"selectedAdminId"}})],1)],1):_vm._e()]},proxy:true},{key:"item.photoURL",fn:function(ref){
var item = ref.item;
return [(item.photoURL)?_c('v-avatar',{attrs:{"color":"#efefef","size":"20"}},[_c('v-img',{attrs:{"src":item.photoURL}})],1):(item.name)?_c('v-avatar',{attrs:{"color":_vm.getAvatarColor(item.id),"size":"20"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.getAvatarLabel(item.name)))])]):_c('v-avatar',{attrs:{"color":"#efefef","size":"20"}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-account ")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":_vm.getUserStatusColor(item.status),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.paymentMethod === 'invoice')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.updateCustomer(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }