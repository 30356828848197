import mixpanel from 'mixpanel-browser'

const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN || ''

mixpanel.init(mixpanelToken)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendTrackingEvent = (message: any, obj: any, uid: string) => {
  try {
    const logObj = obj ? obj : {}
    mixpanel.track(message, logObj)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).gtag('event', message, logObj)
    // const trackingLog = logObj
    // db.collection('TRACKING_LOG').add({
    //   log: trackingLog,
    //   type: message,
    //   createdAt: new Date(),
    //   createdBy: uid ? uid : 'no_user',
    // })
    console.log('sendTrackingEvent:', message)
    console.log('logObj:', logObj)
  } catch (err) {
    console.error(
      `[mixpanel] track ${uid} ${obj}`,
      JSON.stringify(err, null, 2)
    )
  }
}

const identifyTrackedUser = (uid: string) => {
  try {
    mixpanel.identify(uid)
    console.log('identifyTrackedUser', uid)
  } catch (err) {
    console.error(
      `[mixpanel] could not identify ${uid}`,
      JSON.stringify(err, null, 2)
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const signUpForTracking = (user: any) => {
  try {
    mixpanel.identify(user.uid)
    mixpanel.people.set({
      ...user,
      $name:
        user.name || user.businessName || user.billingName || user.phoneNumber,
      $email: user.email || user.billingEmail,
    })
    console.log('signUpForTracking', user)
  } catch (err) {
    console.error(
      `[mixpanel] could not set people ${user}`,
      JSON.stringify(err, null, 2)
    )
  }
}

export { sendTrackingEvent, signUpForTracking, identifyTrackedUser }

// sendTrackingEvent(
//   trackingMessage,
//   {
//     uid: this.uid,
//     eventId: this.$route.params.eventId,
//     title: this.event.title,
//     level: this.event.level,
//     startTime: this.event.startTime.toDate(),
//     endTime: this.event.endTime.toDate(),
//     trainer: this.event.trainer,
//     room: this.event.roomURL,
//     path: this.$route.name
//   },
//   this.uid
// )
