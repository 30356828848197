





























































import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { getEmbedCode } from '../utils/embed'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import copy from 'copy-text-to-clipboard'
import { $t } from '@/plugins/i18n'
import { BusinessOwner } from '../../shared/models/business-owner'
import { VariableNames } from '../../shared/helpers/variables'
import { sendTrackingEvent } from '@/utils/eventTracker'
import PersonalToken from '../components/PersonalToken.vue'

@Component({
  components: {
    PersonalToken,
  },
  computed: {
    ...mapStateAuth(['uid', 'user']),
  },
})
export default class Embedcode extends Vue {
  uid!: string
  user!: BusinessOwner

  text: string = ''

  data() {
    return {
      variables: VariableNames,
    }
  }

  created() {
    this.text = getEmbedCode(this.uid)
  }

  back() {
    this.$router.back()
  }

  copyVariable(variable: string) {
    const text = `{${variable}}`
    alert(this.$t('developer.copied', { text }))
    copy(text)
  }

  copyEmbedCode() {
    copy(this.text)
    alert($t('developer.embed_code_is_copied'))
    sendTrackingEvent(`${this.$route.path}:copyEmbedCode`, {}, this.uid)
  }
}
