<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-title>Authenticating</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="back">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container class="d-flex flex-column text-center">
        <div class="justify-space-between mt-16">
          <v-progress-circular
            :rotate="360"
            :size="150"
            :width="10"
            indeterminate
          >
          </v-progress-circular>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { UserDatabase } from '@/db/user-database'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { getFirestore } from '@firebase/firestore'
import { app } from '../helpers/firebase'
import { delay } from '../helpers/time'

@Component({
  components: {},
  computed: {
    ...mapStateAuth(['uid']),
  },
})
export default class Authenticating extends Vue {
  isValidState(state) {
    console.log('redirect state', state)
    return !!state // TODO:
  }

  async created() {
    const { error, code, state } = this.$route.query
    if (error || !this.isValidState(state)) {
      alert('サインインできませんでした。')
      this.$router.replace('/')
      return
    }

    const userDb = new UserDatabase({
      client: getFirestore(app),
    })

    Promise.all([
      await userDb.getCredentialDb(this.uid).createGoogleCredential({
        accessCode: code,
      }),
      delay(1000),
    ]).then(() => this.$router.replace('/settings'))
  }
}
</script>
