








































import { getFirestore } from '@firebase/firestore'
import { Component, Vue } from 'vue-property-decorator'
import { UserDatabase } from '../db/user-database'
import { app } from '../helpers/firebase'
import { createNamespacedHelpers } from 'vuex'
import { BusinessOwner } from '../../shared/models/business-owner'
import { User } from '@shared/models/user'
const auth = createNamespacedHelpers('auth')
import {
  identifyTrackedUser,
  sendTrackingEvent,
  signUpForTracking,
} from '@/utils/eventTracker'

@Component({
  components: {},
  computed: {
    ...auth.mapState(['uid', 'user']),
  },
  methods: {
    ...auth.mapMutations(['updateAuthStatus']),
  },
})
export default class UpdateProfile extends Vue {
  uid!: string
  user!: BusinessOwner

  updateAuthStatus!: (user?: BusinessOwner) => void

  businessName = ''
  phoneNumber = ''

  async created() {
    let userDb = new UserDatabase({
      client: getFirestore(app),
    })

    let user = (await userDb.getObj(this.uid)) as User
    this.businessName = user.businessName || ''
    this.phoneNumber = user.phoneNumber || ''
  }

  back() {
    this.$router.back()
  }

  save() {
    let userDb = new UserDatabase({
      client: getFirestore(app),
    })

    userDb
      .update(this.uid, {
        businessName: this.businessName,
        phoneNumber: this.phoneNumber,
      })
      .then(() => {
        this.updateAuthStatus({
          ...this.user,
          businessName: this.businessName,
          phoneNumber: this.phoneNumber,
        })
        setTimeout(() => this.back(), 100)
      })

    let user = {
      ...this.user,
      businessName: this.businessName,
      phoneNumber: this.phoneNumber,
    }
    identifyTrackedUser(user.id)
    signUpForTracking(user)
    sendTrackingEvent(`${this.$route.path}:save`, user, this.uid)
  }
}
