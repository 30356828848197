import {
  collection,
  CollectionReference,
  limit,
  orderBy,
  QueryConstraint
} from '@firebase/firestore'
import { LPDeploy } from '../../shared/models/lp-deploy'
import { LPSite } from '../../shared/models/lp-site'
import { ChildDatabase } from './child-database'

export class LPDeployDatabase extends ChildDatabase<LPDeploy, LPSite> {
  collection(): CollectionReference {
    return collection(
      this.db(),
      this.parentRef?.path as string,
      'LANDING_PAGE_DEPLOYS'
    )
  }

  getCurrentData() {
    const wheres: QueryConstraint[] = [
      orderBy('createdAt', 'desc'),
      limit(1),
    ].filter((_) => !!_) as QueryConstraint[]

    return this.listObj(wheres).then((values) =>
      values.length > 0 ? values[0] : null
    )
  }
}
