import {
  collection,
  CollectionReference,
  getFirestore
} from '@firebase/firestore'
import { LPSite } from '../../shared/models/lp-site'
import { ID } from '../../shared/models/models'
import { googleJobFirebaseApp } from '../helpers/firebase'
import { Database } from './database'
import { LPDeployDatabase } from './lp-deploy-database'
import { LPPreviewDatabase } from './lp-preview-database'

export class LPSiteDatabase extends Database<LPSite> {
  constructor() {
    super({
      client: getFirestore(googleJobFirebaseApp),
    })
  }

  collection(): CollectionReference {
    return collection(this.db(), 'LANDING_PAGE_SITES')
  }

  getLPDeployDb(id: ID): LPDeployDatabase {
    return new LPDeployDatabase({
      client: this.db(),
      parentRef: this.ref(id),
      parentDb: this,
    })
  }

  getLPPreviewDb(id: ID): LPPreviewDatabase {
    return new LPPreviewDatabase({
      client: this.db(),
      parentRef: this.ref(id),
      parentDb: this,
    })
  }
}
