const enpoint = `${process.env.VUE_APP_API_BASE_URL}`

export async function answer(token: string): Promise<unknown> {
  return fetch(`${enpoint}/meetings/answer`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
