import {
  addDays,
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  parse,
  startOfMinute
} from 'date-fns'
import { Rule } from '../models/rule'
import { Period } from '../models/slot'

const DATE_STR_FORMAT = {
  jp: 'yyyy/MM/dd',
  eu: 'dd/MM/yyyy',
  us: 'MM/dd/yyyy',
}
const TIME_STR_FORMAT = {
  jp: 'HH:mm',
  eu: 'HH:mm',
  us: 'HH:mm',
}
const DATE_TIME_STR_FORMAT = `${DATE_STR_FORMAT} ${TIME_STR_FORMAT}`

export function toDateStr(date: Date, type: 'jp' | 'eu' | 'us' = 'jp'): string {
  return format(date, DATE_STR_FORMAT[type])
}

export function toJSTDateStr(
  date: Date,
  type: 'jp' | 'eu' | 'us' = 'jp'
): string {
  return toDateStr(addHours(date, 9), type)
}

export function toTimeStr(date: Date, type: 'jp' | 'eu' | 'us' = 'jp'): string {
  return format(date, TIME_STR_FORMAT[type])
}

export function toDateTimeStr(
  date: Date,
  type: 'jp' | 'eu' | 'us' = 'jp'
): string {
  return format(date, `${DATE_STR_FORMAT[type]} ${TIME_STR_FORMAT[type]}`)
}

export function toJSTDateTimeStr(
  date: Date,
  type: 'jp' | 'eu' | 'us' = 'jp'
): string {
  return format(
    addHours(date, 9),
    `${DATE_STR_FORMAT[type]} ${TIME_STR_FORMAT[type]}`
  )
}

export function parseDateTimeString(str: string): Date {
  return parse(str, DATE_TIME_STR_FORMAT, startOfMinute(new Date()))
}

export function hasNoIntersection(
  start1: Date,
  end1: Date,
  start2: Date,
  end2: Date
): boolean {
  // - Assume that start1 is before end1 and start2 is before end2
  // - We use differenceInMinutes because we only need to be correct to minute level, if somehow the
  // time is updated to use seconds, differenceInSeconds should be used instead
  // - differenceInMinutes(start2, end1) >= 0 means start2 is after or equal to end1 and with above assumtion => Case 1 and Case 1-edge
  // - differenceInMinutes(end2, start1) <= 0 means end2 is before or equal to start1 and with above assumtion => Case 2 and Case 2-edge
  return (
    differenceInMinutes(start2, end1) >= 0 ||
    differenceInMinutes(end2, start1) <= 0
  )
}

/**
 *
 * @param {number} slotDuration
 * @param {Date} startTime
 * @param {Date} endTime
 * @param {*} ignore
 */
export function calculateSlots(
  slotDuration: number,
  startTime: Date,
  endTime: Date,
  ignore = [] as Period[]
): Period[] {
  const slots: Period[] = []
  let currentStartTime = startTime
  let currentEndTime = startTime

  // Preprocess the ignored slots
  const normalizedIgnored = ignore.map((slot) => {
    if (!slot.startTime && !slot.durationMinute) {
      // There're some older slots that is not a proper IgnoredSlotTimeStamp with startTime
      // and duration so we assume that these old slots are of 30 minutes duration
      return {
        startTime: slot.startTime,
        duration: 30,
        endTime: addMinutes(slot.startTime, 30),
      }
    }
    const { startTime, durationMinute } = slot
    return {
      ...slot,
      endTime: addMinutes(startTime, durationMinute),
    }
  })

  do {
    currentEndTime = addMinutes(currentStartTime, slotDuration)

    // End time has go over the limitation, we should end the loop
    if (isAfter(currentEndTime, endTime)) {
      break
    }

    // Look for the first ignored slot that has some kind of intersection with the
    // current timestamps. Intersections cases are below:
    // Case 1-intersection
    // ------------------|-ignored------|----->
    // -----------|-current--------|---------->
    // Case 2-intersection
    // ------|-ignored------|----------------->
    // -----------|-current--------|---------->
    // Case 3-intersection
    // -----------------|-ignored------|------>
    // -----------|-current--------------|---->
    // Case 4-intersection
    // -------|-ignored----------------|------>
    // -----------|-current--------|---------->
    // Case 5-intersection
    // -------|-ignored--------|-------------->
    // -------|-current--------|-------------->
    // Note:
    // Case 2-intersection, and Case 4-intersection can still happens even if after a loop we set currentStartTime
    // to the end of the first found ignored slot, because ignored sections can still possibly be overlapped (probably
    // by bugs only), but we should still consider this situation.
    // For shorter implementation, and by elimination, these cases should not be considered intersections
    // Case 1
    // ------------------|-ignored------|----->
    // ---|-current----|---------------------->
    // Case 1-edge
    // ------------------|-ignored------|----->
    // ----|-current-----|-------------------->
    // Case 2
    // ----|-ignored----|--------------------->
    // ---------------------|-current-----|--->
    // Case 2-edge
    // ----|-ignored-----|-------------------->
    // ------------------|-current-----|------>
    const firstIntersectedIgnoredSlot = normalizedIgnored.find(
      ({ startTime, endTime }) => {
        return !hasNoIntersection(
          currentStartTime,
          currentEndTime,
          startTime,
          endTime
        )
      }
    )

    // We should ignore the current timestamps if there's some ignored slots
    // that has intersection with the current timestamps
    const shouldIgnore = !!firstIntersectedIgnoredSlot

    if (!shouldIgnore) {
      // We don't need to ignore this slot, we can add it directly, and move
      // to the next loop, with the currentStartTime set to the last currentEndTime
      slots.push({
        startTime: currentStartTime,
        durationMinute: slotDuration,
      })
      currentStartTime = currentEndTime
      continue
    }

    // We should ignore these timestamps, and move passed the current ignored slots
    // The default new Date() will never run because of the previous shouldIgnore
    // check
    currentStartTime = firstIntersectedIgnoredSlot
      ? firstIntersectedIgnoredSlot.endTime
      : new Date()
  } while (isBefore(currentEndTime, endTime))

  return slots
}

// Fri Sep 11 2020 00: 00: 00 GMT + 0700(Indochina Time) "{"id":"xtE6OCIksvUgxn04tY8t89cXEaR2","sunday":true,"wednesday":true,"saturday":false,"friday":true,"start":"07: 30","timezone":"Asia / Tokyo","tuesday":true,"ignoredStartTimes":[{"duration":30,"startTime":"2020 - 09 - 11T02: 00: 00.000Z"},{"startTime":"2020 - 09 - 24T03: 30: 00.000Z","duration":30},{"startTime":"2020 - 09 - 24T03: 00: 00.000Z","duration":30},{"duration":30,"startTime":"2020 - 09 - 10T01: 00: 00.000Z"},{"duration":30,"startTime":"2020 - 09 - 29T03: 00: 00.000Z"},{"startTime":"2020 - 09 - 11T00: 30: 00.000Z","duration":30}],"monday":true,"thursday":true,"end":"18: 30","attendees":[]}"
/**
 * @typedef GenerateRuleSlotsOptions
 * @type {object}
 * @property {Rule} rules
 * @property {Date} startDate
 * @property {number} numberOfDays
 */
/**
 * @param {GenerateRuleSlotsOptions} options
 * @return {Slot[]}
 */
export function generateRuleSlots({
  startDate,
  rule,
  numberOfDays,
}: {
  startDate: Date
  rule?: Rule
  numberOfDays: number
}): Period[] {
  let generatedSlots: Period[] = []

  console.log('generating for rule', rule)
  if (!rule) {
    return generatedSlots
  }

  const { dow, startTime, durationMinute, excludedPeriods, endTime } = rule
  if (!startTime || !endTime || dow.length === 0 || !differenceInMinutes) {
    return []
  }

  const start = toTimeStr(startTime)
  const end = toTimeStr(endTime)

  const duration = durationMinute || 30

  for (let i = 0; i < numberOfDays; i++) {
    const date = addDays(startDate, i)
    const dateString = toDateStr(date)

    if (
      (isMonday(date) && dow.includes('mon')) ||
      (isTuesday(date) && dow.includes('tue')) ||
      (isWednesday(date) && dow.includes('wed')) ||
      (isThursday(date) && dow.includes('thu')) ||
      (isFriday(date) && dow.includes('fri')) ||
      (isSaturday(date) && dow.includes('sat')) ||
      (isSunday(date) && dow.includes('sun'))
    ) {
      generatedSlots = generatedSlots.concat(
        calculateSlots(
          duration,
          parseDateTimeString(`${dateString} ${start}`),
          parseDateTimeString(`${dateString} ${end}`),
          excludedPeriods
        )
      )
    }
  }

  return generatedSlots
}
