const {
  PhoneNumberFormat,
  PhoneNumberType,
  PhoneNumberUtil,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
} = require('google-libphonenumber')

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCEPTABLE_TYPES = [
  PhoneNumberType.MOBILE,
  PhoneNumberType.FIXED_LINE_OR_MOBILE,
  PhoneNumberType.FIXED_LINE,
]

/**
 *
 * @param {string} number
 * @return {string | null}
 */
export function normalizePhoneNumber(number: string): string | null {
  const phoneUtil = PhoneNumberUtil.getInstance()

  try {
    // Try to normalize the phone number as a Japanese number
    const parsed = phoneUtil.parseAndKeepRawInput(number, 'JP')
    // if (!ACCEPTABLE_TYPES.includes(phoneUtil.getNumberType(parsed))) {
    //   return null
    // }
    return phoneUtil.format(parsed, PhoneNumberFormat.E164)
  } catch (e) {
    console.warn(`Cannot parse [${number}], due to:`, e)
    return null
  }
}
