import configs from '@shared/configs'
import { FirebaseApp, initializeApp } from 'firebase/app'

const firebaseConfig = configs.firebase

export function initialize(): FirebaseApp {
  return initializeApp(firebaseConfig)
}

// Initialize Firebase
export const app = initialize()

export const googleJobFirebaseApp = initializeApp(
  configs.googleJobFirebase,
  'google-job'
)
