
























































import { getFirestore } from '@firebase/firestore'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { app } from '../helpers/firebase'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { $t } from '@/plugins/i18n'
import { sendTrackingEvent } from '@/utils/eventTracker'
import { UserDatabase } from '../db/user-database'
import {
  BusinessOwner,
  UserAdminRole,
} from '../../shared/models/business-owner'
import CreateAdministratorDialog from '../components/dialogs/CreateAdministratorDialog.vue'

@Component({
  components: { CreateAdministratorDialog },
  computed: {
    ...mapStateAuth(['uid']),
  },
})
export default class Administrators extends Vue {
  uid!: string

  adminUsers: BusinessOwner[] = []

  showCreateAdministratorDialog = false

  async created() {
    const userDb = new UserDatabase({
      client: getFirestore(app),
    })
    userDb.getAdminUsersByCreatedBy(this.uid).then((v) => (this.adminUsers = v))
  }

  canDeleteAdmin(user: BusinessOwner) {
    return user.adminRole !== UserAdminRole.SUPER_ADMIN
  }

  back() {
    this.$router.back()
  }

  createAdmin() {
    this.showCreateAdministratorDialog = true
  }

  deleteAdmin(user: BusinessOwner) {
    if (confirm($t('administrators.do_you_want_to_delete_administrator'))) {
      const userDb = new UserDatabase({
        client: getFirestore(app),
      })
      if (user.id.startsWith('preregister:')) {
        userDb
          .delete(user.id)
          .then(() => {
            alert($t('administrators.you_deleted_administrator'))
            this.adminUsers = this.adminUsers.filter((v) => v.id !== user.id)
          })
          .catch(() => {
            alert($t('administrators.error_cant_delete_administrator'))
          })
      } else {
        userDb
          .update(user.id, {
            adminRole: null,
          })
          .then(() => {
            alert($t('administrators.you_deleted_administrator'))
            this.adminUsers = this.adminUsers.filter((v) => v.id !== user.id)
          })
          .catch(() => {
            alert($t('administrators.error_cant_delete_administrator'))
          })
      }
    }
  }

  handleOnAdministratorAdded(user: BusinessOwner) {
    this.adminUsers = [...this.adminUsers, user]
  }
}
