import {
  collection,
  CollectionReference,
  DocumentReference,
  limit,
  orderBy,
  QueryConstraint,
  where
} from '@firebase/firestore'
import { generateRuleSlots } from '@shared/helpers/rules'
import type { Applicant, ApplicantInput } from '@shared/models/applicant'
import { ID } from '@shared/models/models'
import { Period, Slot } from '@shared/models/slot'
import { addDays } from 'date-fns'
import { ITEM_PER_PAGE } from '../../shared/constants'
import {
  BusinessOwner,
  BusinessOwnerInput,
  UserAdminRole
} from '../../shared/models/business-owner'
import { User, UserType } from '../../shared/models/user'
import { ActionTokenDatabase } from './action-token-database'
import { AvailableSlotDatabase } from './available-slot-database'
import { Database, DatabaseOptions } from './database'
import { InvoiceDatabase } from './invoice-database'
import { MeetingDatabase } from './meeting-database'
import { PersonalTokenDatabase } from './personal-token-database'
import { RuleDatabase } from './rule-database'
import { SmsHistoryDatabase } from './sms-history-database'
import { TokenDatabase } from './token-database'

export class UserDatabase extends Database<BusinessOwner | Applicant> {
  constructor(options: DatabaseOptions) {
    super(options)
  }

  collection(): CollectionReference {
    return collection(this.db(), 'USERS')
  }

  public async createApplicant(input: ApplicantInput): Promise<Applicant> {
    return (await super.create({
      ...input,
      type: UserType.APPLICANT,
    })) as Applicant
  }

  public async updateApplicant(
    id: ID,
    input: ApplicantInput
  ): Promise<Applicant> {
    await super.update(id, {
      ...input,
    })

    return (await this.getObj(id)) as Applicant
  }

  public async createBusinessOwner(
    input: BusinessOwnerInput
  ): Promise<BusinessOwner> {
    return (await super.create({
      ...input,
      type: UserType.BUSINESS_OWNER,
    })) as BusinessOwner
  }

  getUserByPhoneNumber(phoneNumber: string) {
    const wheres: QueryConstraint[] = [
      where('phoneNumber', '==', phoneNumber),
      limit(10),
    ].filter((_) => !!_) as QueryConstraint[]
    return this.listObj(wheres)
      .then((list) => list.filter((v) => !v.id.startsWith('applicant')))
      .then((list) => (list.length > 0 ? list[0] : null))
  }

  getAdminUsersByCreatedBy(adminRoleCreatedBy: string) {
    const wheres: QueryConstraint[] = [
      where('adminRole', '==', UserAdminRole.ADMIN),
      where('adminRoleCreatedBy', '==', adminRoleCreatedBy),
      limit(ITEM_PER_PAGE),
    ].filter((_) => !!_) as QueryConstraint[]
    return this.listObj(wheres) as Promise<BusinessOwner[]>
  }

  getBusinessUsersByCreatedBy(createdBy: string) {
    const wheres: QueryConstraint[] = [
      where('type', '==', UserType.BUSINESS_OWNER),
      where('createdBy', '==', createdBy),
      orderBy('createdAt', 'desc'),
      limit(ITEM_PER_PAGE),
    ].filter((_) => !!_) as QueryConstraint[]
    return this.listObj(wheres) as Promise<BusinessOwner[]>
  }

  getAllBusinessUsers() {
    const wheres: QueryConstraint[] = [
      where('type', '==', UserType.BUSINESS_OWNER),
      orderBy('createdAt', 'desc'),
      limit(ITEM_PER_PAGE),
    ].filter((_) => !!_) as QueryConstraint[]
    return this.listObj(wheres) as Promise<BusinessOwner[]>
  }

  getRuleDb(id: ID): RuleDatabase {
    return new RuleDatabase({
      client: this.db(),
      parentRef: this.ref(id),
      parentDb: this,
    })
  }

  getSlotDb(id: ID): AvailableSlotDatabase {
    return new AvailableSlotDatabase({
      client: this.db(),
      parentRef: this.ref(id),
      parentDb: this,
    })
  }

  getCredentialDb(id: ID): TokenDatabase {
    return new TokenDatabase({
      client: this.db(),
      parentRef: this.ref(id),
      parentDb: this,
    })
  }

  public async getAllSlot({
    userId,
    numberOfDays,
  }: {
    userId: string
    numberOfDays: number
  }): Promise<{
    predefinedSlots: Slot[]
    ruleSlots: Period[]
    finishedSlots: Slot[]
  }> {
    const ruleDb = this.getRuleDb(userId)
    const slotDb = this.getSlotDb(userId)

    const startDate = new Date()
    const endDate = addDays(startDate, numberOfDays)

    const rule = await ruleDb.getDefaultObj()
    let ruleSlots: Period[] = []
    if (rule) {
      ruleSlots = generateRuleSlots({
        numberOfDays,
        rule,
        startDate,
      })
    }

    const predefinedSlots: Slot[] = await slotDb.getSlots({
      before: startDate,
      after: endDate,
    })

    const finishedSlots: Slot[] = await slotDb.getFinishedSlots()

    console.log('predefinedSlots', predefinedSlots)
    return {
      predefinedSlots,
      ruleSlots,
      finishedSlots,
    }
  }

  /**
   *
   * @param {ID} id
   * @return {SmsHistoryDatabase}
   */
  getSmsHistoryDb(id: ID): SmsHistoryDatabase {
    return new SmsHistoryDatabase({
      ...this.options,
      parentRef: this.ref(id) as DocumentReference<User>,
      parentDb: this,
    })
  }

  /**
   *
   * @param {ID} id
   * @return {MeetingDatabase}
   */
  getMeetingDb(id: ID): MeetingDatabase {
    return new MeetingDatabase({
      ...this.options,
      parentRef: this.ref(id) as DocumentReference<User>,
      parentDb: this,
    })
  }

  getInvoiceDb(id: ID): InvoiceDatabase {
    return new InvoiceDatabase({
      ...this.options,
      parentRef: this.ref(id) as DocumentReference<User>,
      parentDb: this,
    })
  }

  /**
   *
   * @param {ID} id
   * @return {AvailableSlotDatabase}
   */
  getPersonalTokenDb(id: ID): PersonalTokenDatabase {
    return new PersonalTokenDatabase({
      ...this.options,
      parentRef: this.ref(id) as DocumentReference<User>,
      parentDb: this,
    })
  }

  /**
   *
   * @param {ID} id
   * @return {AvailableSlotDatabase}
   */
  getActionTokensDb(id: ID): ActionTokenDatabase {
    return new ActionTokenDatabase({
      ...this.options,
      parentRef: this.ref(id) as DocumentReference<User>,
      parentDb: this,
    })
  }
}
