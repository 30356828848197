<template>
  <v-container id="landing-page-generator">
    <!-- <v-alert
      v-if="!jobTypesValid"
      border="left"
      colored-border
      type="warning"
      elevation="2"
    >
      {{ $t('landing.updateJob_1') }}
      <v-btn outlined to="/bot-settings" class="mx-2">{{
        $t('m.botSettings.title')
      }}</v-btn>
      {{ $t('landing.updateJob_2') }}
    </v-alert>
    <v-card v-else> -->
    <v-card>
      <v-progress-linear v-if="saving" indeterminate />
      <v-tabs v-model="tab">
        <v-tab>
          <template v-if="jobQuestionsMissingValue === false">
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
          </template>
          <template v-else>
            <v-icon color="warning">mdi-alert</v-icon>
          </template>
          {{ $t('landing.store.title') }}
        </v-tab>
        <v-tab>
          <template v-if="noMissingJobValues">
            <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
          </template>
          <template v-else>
            <v-icon color="warning">mdi-alert</v-icon>
          </template>
          {{ $t('landing.jobs.title') }}
        </v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item eager>
            <v-alert
              v-if="hasJobSite"
              border="top"
              colored-border
              type="success"
              elevation="2"
            >
              <div>{{ $t('landing.store.publicURL') }}</div>
              <div>
                <a target="_blank" :href="jobSiteUrl">{{ jobSiteUrl }}</a>
              </div>
            </v-alert>

            <v-alert
              v-if="hasPreview"
              border="top"
              colored-border
              type="info"
              elevation="2"
            >
              <div>{{ $t('landing.store.previewURL') }}</div>
              <div>
                <a target="_blank" :href="previewUrl">{{ previewUrl }}</a>
              </div>
            </v-alert>
            <v-select
              label="公開／非公開の設定"
              placeholder="公開、もしくは非公開を設定してください。"
              :items="[
                { text: '非公開', value: true },
                { text: '公開', value: false },
              ]"
              item-text="text"
              item-value="value"
              v-model="pageDetails.isSetPrivate"
              @change="updateJobQuestionsMissingValue()"
            ></v-select>
            <v-textarea
              label="企業(店舗)について教えてください"
              v-model="pageDetails.aboutWorkplace"
              @keyup="updateJobQuestionsMissingValue()"
            ></v-textarea>
            <v-textarea
              label="働き方について教えてください"
              v-model="pageDetails.workEthic"
              @keyup="updateJobQuestionsMissingValue()"
            ></v-textarea>
          </v-tab-item>
          <v-tab-item eager>
            <v-row v-if="addJobType">
              <v-col cols="auto">
                <v-btn
                  small
                  outlined
                  color="green"
                  @click.stop="dialogOpen = true"
                >
                  <v-icon left>mdi-plus-circle-outline</v-icon>
                  {{ $t('landing.addJob') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-expansion-panels v-model="selectedJobTypeIndex" inset>
                  <v-expansion-panel v-for="(job, i) in jobsData" :key="i">
                    <v-expansion-panel-header>
                      <v-row align="center">
                        <v-col cols="auto">
                          <template v-if="missingValues[job.jobType] === false">
                            <v-icon color="green"
                              >mdi-checkbox-marked-circle</v-icon
                            >
                          </template>
                          <template v-else>
                            <v-icon color="warning">mdi-alert</v-icon>
                          </template>
                        </v-col>
                        <v-col>{{
                          job.jobType === selectedJobType
                            ? botSettingsTitle
                            : job.jobType
                        }}</v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                      <v-row v-if="hasJobSite && urls[i]">
                        <v-col cols="12">
                          <v-alert
                            border="top"
                            colored-border
                            type="success"
                            elevation="2"
                          >
                            <div>
                              {{ job.jobType
                              }}{{ $t('landing.jobs.publicURL') }}
                            </div>
                            <div>
                              <a target="_blank" :href="urls[i]">
                                {{ urls[i] }}
                              </a>
                            </div>
                          </v-alert>
                        </v-col>
                      </v-row>

                      <v-row v-if="hasPreview && previewUrls[i]">
                        <v-col cols="12">
                          <v-alert
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <div>
                              {{ job.jobType
                              }}{{ $t('landing.jobs.previewURL') }}
                            </div>
                            <div>
                              <a target="_blank" :href="previewUrls[i]">
                                {{ previewUrls[i] }}
                              </a>
                            </div>
                          </v-alert>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            label="募集職種をひとつだけ記入してください。"
                            v-model="jobsData[i].data.jobOpeningType1Ja"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="募集する仕事を一言で表すと何と表現できますか。（例：和食店のホールスタッフ）"
                            v-model="jobsData[i].data.parts1ForTitle"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="応募者に一番アピールしたい特徴をひとつ記入してください。（例：週2日からOK、1日3時間からOK、ピアス・髪型自由、主婦（夫）歓迎、飲食業未経験OK、交通費全額支給、まかない有り、など）"
                            v-model="jobsData[i].data.parts2ForTitle"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="勤務地のエリアについての特徴を一言で記入してください。（例：恵比寿、小田急線沿い、駅近、など）"
                            v-model="jobsData[i].data.parts3ForTitle"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-select
                            label="雇用形態を選択してください。"
                            :items="[
                              '正社員、フルタイム',
                              'アルバイト、パートタイム',
                              '契約社員',
                              '派遣社員',
                              'インターン',
                              'ボランティア',
                              '日雇い',
                              '業務委託',
                              'その他',
                            ]"
                            v-model="jobsData[i].data.employmentType"
                            @change="updateMissingJobValuesForIndex(i)"
                          ></v-select>

                          <v-textarea
                            v-if="jobsData[i].data.employmentType == 'その他'"
                            label="その他の雇用形態を具体的に入力してください。"
                            v-model="jobsData[i].data.theOtherEmploymentType"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-select
                            label="給与タイプを選択してください。"
                            :items="[
                              '時給',
                              '日給',
                              '週給',
                              '月給',
                              '年給',
                              'なし',
                            ]"
                            v-model="jobsData[i].data.salaryType"
                            rows="1"
                            auto-grow
                            @change="updateMissingJobValuesForIndex(i)"
                          ></v-select>
                          <v-textarea
                            label="最低給与を半角数字のみで入力してください。（例 1100）"
                            v-model="jobsData[i].data.minSalary"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="最高給与を半角数字のみで入力してください。（例 2500）"
                            v-model="jobsData[i].data.maxSalary"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="給与についての詳細を記入してください。試用期間がある場合は、その期間中の給与についても記入してください。"
                            v-model="jobsData[i].data.salaryDetail"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>

                          <v-textarea
                            label="勤務地の都道府県を記入してください。"
                            v-model="
                              jobsData[i].data.prefectureOfLocationOfWork
                            "
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="市区町村を記入してください。"
                            v-model="jobsData[i].data.localityOfLocationOfWork"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="それ以降の住所を記入してください。"
                            v-model="
                              jobsData[i].data.streetAddressOfLocationOfWork
                            "
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="募集する勤務時間やシフトについて記入してください。また、休日、休憩時間、時間外労働（1日8時間以上、週40時間以上）の有無については必要に応じて記入してください。"
                            v-model="jobsData[i].data.workHours"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="具体的な仕事内容を記入してください。"
                            v-model="jobsData[i].data.jobDescription"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>

                          <v-textarea
                            label="どのような方を採用したいですか。（例：主婦（夫）歓迎、学生歓迎、明るく接客ができる人歓迎、接客業経験者歓迎、ラストまで入れる方歓迎、英語で接客できる方歓迎）※注意事項：性別、年齢、性格、国籍、居住地域などに直接的に関係する表現で限定することはNGです。"
                            v-model="jobsData[i].data.idealCandidate"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="最寄り駅からの徒歩要時間など、アクセス情報を記入してください。"
                            v-model="jobsData[i].data.accessInfo"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="交通費の支給について記入してください。"
                            v-model="jobsData[i].data.transportationCosts"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="社会保険について記入してください。（雇用保険、労災保険、厚生年金、健康保険）"
                            v-model="jobsData[i].data.insurance"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="契約期間について記入してください。"
                            v-model="jobsData[i].data.contractTerm"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>

                          <v-textarea
                            label="試用期間の有無や期間を記入してください。"
                            v-model="jobsData[i].data.trialTerm"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <v-textarea
                            label="福利厚生や必要な資格・言語など、その他のアピールポイントや条件がありましたら記入してください。"
                            v-model="jobsData[i].data.otherThings"
                            rows="1"
                            auto-grow
                            @keyup="updateMissingJobValuesForIndex(i)"
                          ></v-textarea>
                          <my-date-input
                            label="募集の開始日を入力してください。"
                            v-model="jobsData[i].data.dateOfPostData_date"
                            @change="updateMissingJobValuesForIndex(i)"
                          ></my-date-input>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <div class="mt-3" v-if="jobTypesValid">
      <v-alert
        type="warning"
        colored-border
        border="left"
        tile
        elevation="2"
        v-if="hasMissingValues"
        >{{ $t('landing.notFilled') }}</v-alert
      >
      <v-btn
        @click="onClickSave({ preview: true })"
        outlined
        color="primary"
        :loading="saving"
        class="mx-2 mb-2"
      >
        <v-icon left>mdi-content-save-alert</v-icon>
        {{ $t('landing.save') }}
      </v-btn>
      <v-btn
        @click="onClickSave({ preview: false })"
        outlined
        color="success"
        :disabled="hasMissingValues"
        :loading="saving"
        v-if="hasPreview"
        class="mx-2 mb-2"
      >
        <v-icon left>mdi-cloud-upload</v-icon>
        {{ $t('landing.upload') }}
      </v-btn>
    </div>

    <v-dialog v-model="dialogOpen" max-width="290" :persistent="loading">
      <v-card>
        <v-card-title class="headline">{{ $t('landing.addJob') }}</v-card-title>

        <v-card-text>
          <v-text-field
            :disabled="loading"
            v-model.trim="newJobType"
            :label="$t('landing.newJob')"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="grey"
            text
            @click="dialogOpen = false"
            :disabled="loading"
            >{{ $t('landing.button_cancel') }}</v-btn
          >

          <v-btn
            color="primary"
            @click="onAddJobType"
            :loading="loading"
            :disabled="loading || !newJobTypeValid"
            >{{ $t('landing.button_add') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
// import { firestoreOutgoingDataCleaner } from '@/helpers/upil'
import MyDateInput from '@/components/commons/MyDateInput.vue'
import { LPSiteDatabase } from '@/db/lp-site-database'
import { UserDatabase } from '@/db/user-database'
import { getFirestore } from '@firebase/firestore'
import { app, googleJobFirebaseApp } from '@/helpers/firebase'
import { getGoogleJobPageToken } from '@/helpers/functions'
import { getAuth, signInWithCustomToken } from '@firebase/auth'
import { isAfter } from 'date-fns'
import { getPublicUrl } from '@/utils/qrcode'
import { createShortenUrl } from '@/helpers/shorten-url'

function shortenUrl(targetUrl) {
  return createShortenUrl(targetUrl, {
    reusable: true,
  }).then((v) => (v ? v.shortenUrl : null))
}

export default {
  components: {
    // FormMode,
    MyDateInput,
  },
  props: {},
  data: () => ({
    googleJobFirebaseUserUid: null,
    saving: false,
    saveError: false,
    recentlySaved: false,
    baseStaticSiteUrl: process.env.VUE_APP_BASE_STATIC_SITE_URL,
    hasLive: false,
    hasPreview: false,

    dialogOpen: false,
    loading: false,
    newJobType: '',
    missingValues: {},
    jobQuestionsMissingValue: false,
    selectedJobTypeIndex: -1,
    tab: -1,
    jobSiteUrl: '',
    previewUrl: '',
    urls: [],
    previewUrls: [],
    pageDetails: {},
    jobsData: [],
  }),
  computed: {
    ...mapState('auth', ['uid']),
    jobTypes() {
      return this.jobsData.map((v) => v.jobType)
    },
    hasJobSite() {
      return this.jobsData.length > 0
    },
    newJobTypeValid() {
      return this.newJobType !== ''
    },
    selectedJobType() {
      return this.jobTypes[this.selectedJobTypeIndex]
    },
    botSettingsTitle() {
      return this.selectedJobType
        ? `${this.selectedJobType}${this.$i18n.t('landing.jobs.pageOf')}`
        : null
    },
    noMissingJobValues() {
      return this.jobTypesValid
        ? this.jobTypes.every(
            (jobType) => this.missingValues[jobType] === false
          )
        : true
    },
    hasMissingValues() {
      return !(
        this.noMissingJobValues && this.jobQuestionsMissingValue === false
      )
    },
    // If jobTypes is using the old string-based method, then the owner will need to update their entry
    jobTypesValid() {
      return Array.isArray(this.jobsData)
    },
  },
  methods: {
    async generatePageUrlsAndPreviewUrls() {
      this.urls = await Promise.all(
        this.jobTypes.map((jobType) => {
          return this.generatePageUrl(jobType)
        })
      )
      this.previewUrls = await Promise.all(
        this.jobTypes.map((jobType) => {
          return this.generatePreviewPageUrl(jobType)
        })
      )
    },
    async generatePageUrl(jobType) {
      // Deploy:
      // - Top: `${this.baseStaticSiteUrl}/${this.uid}`
      // - Job: `${this.baseStaticSiteUrl}/${this.uid}/jobs/${index + 1}`
      // Preview:
      // - Top: `${this.baseStaticSiteUrl}/${this.uid}/previews/${previewId}`
      // - Job: `${this.baseStaticSiteUrl}/${this.uid}/previews/${previewId}/jobs/${index + 1}`
      const index = this.jobTypes.indexOf(jobType)
      return shortenUrl(
        `${this.baseStaticSiteUrl}/${this.googleJobFirebaseUserUid}/jobs/${
          index + 1
        }`
      )
    },
    async generatePreviewPageUrl(jobType) {
      const index = this.jobTypes.indexOf(jobType)
      return shortenUrl(
        `${this.baseStaticSiteUrl}/${
          this.googleJobFirebaseUserUid
        }/previews/latest/jobs/${index + 1}`
      )
    },
    updateJobQuestionsMissingValue() {
      let pageDetails = this.pageDetails

      const requiredFields = ['isSetPrivate', 'aboutWorkplace', 'workEthic']
      let missingValues = false
      requiredFields.map((field) => {
        if (
          pageDetails[field] ||
          pageDetails[field] === false ||
          pageDetails[field] === 0
        ) {
          // OK
        } else {
          missingValues = true
        }
      })

      this.jobQuestionsMissingValue = missingValues
    },
    updateMissingJobValues() {
      this.jobsData.map((_, i) => this.updateMissingJobValuesForIndex(i))
    },
    updateMissingJobValuesForIndex(index) {
      if (index < 0 || index >= this.jobsData.length) {
        return
      }

      const job = this.jobsData[index]

      const requiredFields = [
        'jobOpeningType1Ja',
        'parts1ForTitle',
        'parts2ForTitle',
        'parts3ForTitle',
        'employmentType',
        'salaryType',
        'minSalary',
        'maxSalary',
        'salaryDetail',
        'prefectureOfLocationOfWork',
        'localityOfLocationOfWork',
        'streetAddressOfLocationOfWork',
        'workHours',
        'jobDescription',
        'idealCandidate',
        'accessInfo',
        'transportationCosts',
        'insurance',
        'contractTerm',
        'trialTerm',
        'otherThings',
        'dateOfPostData_date',
      ]
      let missingValues = false
      requiredFields.map((field) => {
        if (
          job.data[field] ||
          job.data[field] === false ||
          job.data[field] === 0
        ) {
          // OK
        } else {
          missingValues = true
        }
      })
      this.missingValues = {
        ...this.missingValues,
        [job.jobType]: missingValues,
      }
    },
    async onAddJobType() {
      this.loading = true
      try {
        if (this.newJobTypeValid) {
          await this.addJobType(this.newJobType)
          this.newJobType = ''
          this.dialogOpen = false
        }
      } finally {
        this.loading = false
      }
    },
    generatePageJobData() {
      // const { settings, ...pageDetails } = this.pageUpil
      //   ? cleanState(this.pageUpil)
      //   : {}
      // const jobsData = Object.entries(this.jobUpils)
      //   .map(([jobType, upilInstance]) => ({
      //     jobType,
      //     data: cleanState(upilInstance),
      //   }))
      //   .map(({ jobType, data: { settings, ...data } }) => ({
      //     jobType,
      //     data,
      //   }))
      return { pageDetails: this.pageDetails, jobsData: this.jobsData }
    },
    async onClickSave({ preview }) {
      if (!this.saving && !this.loading) {
        this.saving = true
        try {
          const data = this.generatePageJobData()
          ;(await preview) ? this.onPreview(data) : this.onSave(data)
        } finally {
          this.saving = false
        }
      }
    },
    missingValuesUpdated(jobType, isMissingValue) {
      this.$set(this.missingValues, jobType, isMissingValue)
    },
    saveLandingPagePreviewData({ data }) {
      const lpSiteDb = new LPSiteDatabase()
      const lpPreviewDb = lpSiteDb.getLPPreviewDb(this.googleJobFirebaseUserUid)
      return lpPreviewDb.create(data).then(async (v) => {
        await lpSiteDb.set(
          this.googleJobFirebaseUserUid,
          {
            lastPreviewId: v.id,
            createdAt: new Date(),
          },
          {
            merge: true,
          }
        )
        return v
      })
    },
    saveLandingPageData({ data }) {
      const lpSiteDb = new LPSiteDatabase()
      const lpDeployDb = lpSiteDb.getLPDeployDb(this.googleJobFirebaseUserUid)
      return lpDeployDb.create(data).then(async (v) => {
        await lpSiteDb.set(
          this.googleJobFirebaseUserUid,
          {
            lastDeploymentId: v.id,
            createdAt: new Date(),
          },
          {
            merge: true,
          }
        )
        return v
      })
    },
    async onPreview({ pageDetails, jobsData }) {
      return this.onSave({ pageDetails, jobsData, preview: true })
    },
    async onSave({ pageDetails, jobsData, preview }) {
      try {
        const userDb = new UserDatabase({
          client: getFirestore(app),
        })
        const user = await userDb.getObj(this.googleJobFirebaseUserUid)
        const url = user.shortenUrl || getPublicUrl(this.uid)

        pageDetails = {
          ...pageDetails,
          logo: `${process.env.VUE_APP_BASE_URL}/img/icons/android-chrome-512x512.png`,
          chatUrl: url,
        }
        jobsData = jobsData.map((job) => ({
          ...job,
          jobType: job.data.jobOpeningType1Ja,
          chatUrl: url,
        }))

        this.saveError = false
        this.saving = true
        const saveFunction = preview
          ? this.saveLandingPagePreviewData
          : this.saveLandingPageData
        await saveFunction({
          ownerId: this.googleJobFirebaseUserUid,
          data: {
            pageDetails,
            jobsData,
            companyInfo: {
              companyNameJa: user.businessName,
              contactInformationJa: user.phoneNumber,
            },
            user,
          },
        })
        await this.generatePageUrlsAndPreviewUrls()
        this.pageDetails = pageDetails
        this.jobsData = jobsData
        this.updateJobQuestionsMissingValue()
        this.updateMissingJobValues()
        if (preview) {
          this.hasPreview = true
        } else {
          this.hasLive = true
        }
        this.recentlySaved = true
        window.setTimeout(() => {
          if (this) {
            this.recentlySaved = false
          }
        }, 3000)
      } catch (err) {
        this.saveError = true
        console.error('Error saving landing page data', err) // eslint-disable-line no-console
        // Sentry.captureException(err)
      } finally {
        this.saving = false
      }
    },
    addJobType(jobType) {
      this.jobsData = [
        ...this.jobsData,
        {
          jobType,
          data: {
            jobOpeningType1Ja: jobType,
          },
        },
      ]
    },
  },
  async mounted() {
    // custom auth for google job firebase app
    let googleJobFirebaseUser = await new Promise((resolve, reject) => {
      const unsubscribe = getAuth(googleJobFirebaseApp).onAuthStateChanged(
        (v) => {
          unsubscribe()
          resolve(v)
        },
        (error) => {
          unsubscribe()
          reject(error)
        }
      )
    })
    if (
      !googleJobFirebaseUser ||
      !googleJobFirebaseUser.uid.endsWith(this.uid)
    ) {
      console.log('DO custom auth now!!!')
      const { token } = await getGoogleJobPageToken()
      const result = await signInWithCustomToken(
        getAuth(googleJobFirebaseApp),
        token
      )
      googleJobFirebaseUser = result.user
    }

    this.googleJobFirebaseUserUid = googleJobFirebaseUser.uid

    shortenUrl(
      `${this.baseStaticSiteUrl}/${this.googleJobFirebaseUserUid}`
    ).then((url) => (this.jobSiteUrl = url))
    shortenUrl(
      `${this.baseStaticSiteUrl}/${this.googleJobFirebaseUserUid}/previews/latest`
    ).then((url) => (this.previewUrl = url))

    const lpSiteDb = new LPSiteDatabase()
    const lpDeployDb = lpSiteDb.getLPDeployDb(this.googleJobFirebaseUserUid)
    const lpPreviewDb = lpSiteDb.getLPPreviewDb(this.googleJobFirebaseUserUid)

    const [live, preview] = await Promise.all([
      lpDeployDb.getCurrentData(),
      lpPreviewDb.getCurrentData(this.googleJobFirebaseUserUid),
    ])
    this.hasLive = !!live
    this.hasPreview = !!preview

    let latest = live || preview
    if (latest) {
      if (preview) {
        if (isAfter(preview.updatedAt, live.updatedAt)) {
          latest = preview
        }
      }

      console.log({ latest })
      this.pageDetails = latest.pageDetails || {}
      this.jobsData = latest.jobsData || []
      await this.generatePageUrlsAndPreviewUrls()
      this.updateJobQuestionsMissingValue()
      this.updateMissingJobValues()
    }

    this.loading = false
  },
}
</script>
<style lang="scss">
#landing-page-generator {
  .upil-node-text,
  .v-alert__content {
    text-align: left;
  }
}
</style>
