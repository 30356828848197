import {
  collection,
  CollectionReference,
  DocumentData,
  where
} from '@firebase/firestore'
import type { SmsHistory } from '@shared/models/sms-history'
import { User } from '@shared/models/user'
import { ChildDatabase } from './child-database'

/**
 *
 */
export class SmsHistoryDatabase extends ChildDatabase<SmsHistory, User> {
  collection(): CollectionReference<DocumentData> {
    return collection(this.db(), this.parentRef?.path as string, 'SMS_HISTORY')
  }

  async getSmsListByApplicantId(applicantId: string) {
    const list1 = await this.listObj([where('fromUserId', '==', applicantId)])
    const list2 = await this.listObj([where('toUserId', '==', applicantId)])

    const list = [...list1, ...list2]
    list.sort(compareSmsHistory)
    return list
  }
}

const compareSmsHistory = (a: SmsHistory, b: SmsHistory) => {
  return a.createdAt.getTime() - b.createdAt.getTime()
}
