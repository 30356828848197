import { ID, Model, Optional, Response } from './models'
import { Slot, SlotResponse } from './slot'

export enum MeetingStatus {
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  RESCHEDULED = 'rescheduled',
}

export interface GoogleEvent {
  conferenceData: {
    conferenceId: string
    conferenceSolution: {
      iconUri: string
      key: {
        type: string
        name: string
      }
    }
    entryPoints: {
      entryPointType: string
      label?: string
      pin?: string
      regionCode?: string
      uri: string
    }[]
  }
}

export interface MeetingActionHistory {
  type: 'answer'
  storeValueAs: string | null
  value: string | null
  createdAt: Date | string | null
  notificationId: string | null
}
export interface MeetingCommon {
  slotId: ID
  applicantId: ID
  status: MeetingStatus
  businessOwnerId: ID
  slot: Slot | SlotResponse
  gmeetUrl: string
  cancelledAt: Date | string | null
  confirmedAt: Date | string | null
  timeZone?: string
  googleEvent?: GoogleEvent
  actionsHistory: MeetingActionHistory[]
  applicantAcceptance?: 'yes' | 'no'
  interviewStatus?: 'yes' | 'no' | 'no-show'
  note?: string
}

export interface Meeting extends MeetingCommon, Model {
  cancelledAt: Date | null
  slot: Slot
  confirmedAt: Date | null
}
export interface MeetingResponse extends MeetingCommon, Response {
  cancelledAt: string | null
  slot: SlotResponse
  confirmedAt: string | null
}

export type MeetingInput = Optional<Meeting>
