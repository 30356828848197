















































































































































































import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { BusinessOwner } from '../../../shared/models/business-owner'
import { $t } from '@/plugins/i18n'
import { UserDatabase } from '../../db/user-database'
import { getDoc, getFirestore } from '@firebase/firestore'
import { app } from '../../helpers/firebase'
import {
  Slot,
  SlotStatus,
  SlotWithExtraInfo,
} from '../../../shared/models/slot'
import { Applicant } from '../../../shared/models/applicant'
import { toObj } from '../../helpers/data'
import { createMeeting } from '../../helpers/functions'
import { randomString } from '../../utils/utils'
import { sendTrackingEvent } from '@/utils/eventTracker'
import { format } from 'date-fns'

@Component({
  components: {},
  props: {
    value: Boolean,
    slotData: Object,
  },
  computed: {
    ...mapStateAuth(['uid', 'user']),
  },
})
export default class extends Vue {
  uid!: string
  user!: BusinessOwner

  statuses = [
    { label: $t('create_slot.status_available'), value: 'available' },
    { label: $t('create_slot.status_confirmed'), value: 'confirmed' },
  ]

  modal = false
  date = ''

  modal2 = false
  time = ''

  duration = 30

  status = 'available'
  applicantName = ''
  applicantPhoneNumber = ''

  isSaving = false
  isDeleting = false

  get slot() {
    return this.$props.slotData as SlotWithExtraInfo | null
  }

  get isEnabledAddButton() {
    return this.status === 'available'
      ? !!this.date && !!this.time && !!this.duration && this.duration > 0
      : !!this.date &&
          !!this.time &&
          !!this.duration &&
          this.duration > 0 &&
          !!this.applicantName &&
          !!this.applicantPhoneNumber
  }

  created() {
    if (this.slot) {
      let slot: SlotWithExtraInfo = this.slot
      this.date = format(slot.startTime, 'yyyy-MM-dd')
      this.time = format(slot.startTime, 'HH:mm')
      this.duration = slot.durationMinute
      this.applicantName = slot.applicant?.name || ''
      this.applicantPhoneNumber = slot.applicant?.phoneNumber || ''
    }
  }

  async handleDeleteSlot() {
    if (this.slot && confirm($t('home.do_you_want_to_delete_this_slot'))) {
      let userDb = new UserDatabase({
        client: getFirestore(app),
      })
      const slotDb = userDb.getSlotDb(this.uid)
      this.isDeleting = true
      slotDb
        .delete(this.slot.id)
        .catch(() => {
          alert($t('home.cant_delete_slot'))
        })
        .finally(() => {
          this.isDeleting = false
          this.$emit('onSlotDeleted', this.slot)
          this.$emit('input', false)
          sendTrackingEvent(
            `${this.$route.path}:handleDeleteSlot`,
            this.slot,
            this.uid
          )
        })
    }
  }

  async handleSaveSlot() {
    this.isSaving = true

    try {
      let userDb = new UserDatabase({
        client: getFirestore(app),
      })
      const slotDb = userDb.getSlotDb(this.uid)
      let slot: Slot
      if (this.slot) {
        await slotDb.update(this.slot.id, {
          startTime: new Date(`${this.date} ${this.time}`),
          durationMinute: this.duration,
          capacity: 1,
          status: SlotStatus.OPEN,
        })
        slot = {
          ...this.slot,
          startTime: new Date(`${this.date} ${this.time}`),
          durationMinute: this.duration,
          capacity: 1,
          status: SlotStatus.OPEN,
        }
      } else {
        slot = await slotDb.create({
          ownerId: this.uid,
          startTime: new Date(`${this.date} ${this.time}`),
          durationMinute: this.duration,
          capacity: 1,
          status: SlotStatus.OPEN,
        })
      }

      if (this.status === 'confirmed') {
        const applicantId = `applicant:${randomString(20)}`

        const userRef = userDb.ref(applicantId)
        await getDoc(userRef)
          .then(async (snap): Promise<Applicant> => {
            if (snap.exists()) {
              // do nothing
              console.log('Snap is existed')
              return toObj<Applicant>(snap) as Applicant
            } else {
              console.log('createApplicant')
              return userDb.createApplicant({
                id: applicantId,
                name: this.applicantName.trim(),
                email: null,
                phoneNumber: this.applicantPhoneNumber.trim(),
                photoURL: null,
              })
            }
          })
          .then(async (applicant: Applicant) => {
            let meeting = await createMeeting({
              slotId: slot.id,
              applicantId,
              businessOwnerId: this.uid,
              note: '',
            })
            slot.status = SlotStatus.FULL
            ;(slot as any).meeting = meeting
            ;(slot as any).applicant = applicant
          })
      }

      if (this.slot) {
        this.$emit('onSlotUpdated', slot)
        this.$emit('input', false)
        sendTrackingEvent(
          `${this.$route.path}:handleUpdateSlot`,
          slot,
          this.uid
        )
      } else {
        this.$emit('onSlotAdded', slot)
        this.$emit('input', false)
        sendTrackingEvent(`${this.$route.path}:handleAddSlot`, slot, this.uid)
      }
    } catch (e) {
      console.error(e)
    }

    this.isSaving = false
  }
}
