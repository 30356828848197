export type STAGES = 'dev' | 'stage' | 'production' | 'local'
export default {
  firebase: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  },
  googleJobFirebase: {
    apiKey: process.env.VUE_APP_GOOGLE_JOB_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_GOOGLE_JOB_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_GOOGLE_JOB_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_GOOGLE_JOB_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:
      process.env.VUE_APP_GOOGLE_JOB_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_GOOGLE_JOB_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_GOOGLE_JOB_FIREBASE_MEASUREMENT_ID,
  },
  gapi: {
    clientId: process.env.VUE_APP_GAPI_CLIENT_ID,
  },
  baseUrl: process.env.VUE_APP_BASE_URL,
  stage: process.env.VUE_APP_STAGE,
}
