import { CREDENTIAL_PERSONAL_ID } from '@shared/models/credential'
import {
  PersonalToken,
  PersonalTokenStatus,
  PersonalTokenType,
} from '@shared/models/personal-token'
import { ActionTokenDatabase } from './action-token-database'

/**
 *
 */
export class PersonalTokenDatabase extends ActionTokenDatabase {
  /**
   *
   * @return {Promise<Credential>}
   */
  async getCredential(): Promise<PersonalToken> {
    return (await this.getObj(CREDENTIAL_PERSONAL_ID)) as PersonalToken
  }

  /**
   *
   * @param {any} input
   */
  async createCredential(input: { token: string, data: any }): Promise<void> { // eslint-disable-line
    await this.create({
      id: CREDENTIAL_PERSONAL_ID,
      status: PersonalTokenStatus.ACTIVE,
      type: PersonalTokenType.PERSONAL,
      ...input,
    })
  }

  /**
   *
   * @param {any} input
   */
  async deleteCredential(input: { token: string, data: any }): Promise<void> { // eslint-disable-line
    await this.delete(CREDENTIAL_PERSONAL_ID)
  }
}
