































































































import { getFirestore } from '@firebase/firestore'
import { Component, Vue } from 'vue-property-decorator'
import { BillingInfoDatabase } from '../db/billing-info-database'
import { app } from '../helpers/firebase'
import { createNamespacedHelpers } from 'vuex'
import {
  BusinessOwner,
  PaymentMethod,
} from '../../shared/models/business-owner'
import { BillingInfoInput } from '@shared/models/billing-info'
const auth = createNamespacedHelpers('auth')
import { sendTrackingEvent } from '@/utils/eventTracker'
import { $t } from '@/plugins/i18n'
import { UserDatabase } from '../db/user-database'
import { PLANS } from '../utils/plans'

@Component({
  components: {},
  computed: {
    ...auth.mapState(['uid', 'user']),
  },
})
export default class RegisterBillingInfoPage extends Vue {
  uid!: string
  user!: BusinessOwner

  isNewBillingInfo = true
  billingInfo: BillingInfoInput = {
    name: '',
    billingName: '',
    billingPostalCode: '',
    billingAddress: '',
    telNo: '',
    email: '',
  }

  isSaving = false

  get isSaveEnabled() {
    return (
      !!this.billingInfo.name &&
      !!this.billingInfo.billingName &&
      !!this.billingInfo.billingPostalCode &&
      !!this.billingInfo.billingAddress &&
      !!this.billingInfo.telNo &&
      !!this.billingInfo.email
    )
  }

  get description_2() {
    const plan = PLANS.find((v) => v.id === this.user.planId)
    console.log({ plan, planId: this.user.planId })

    if (plan) {
      return $t(
        plan.freeQuota
          ? 'register_billing_info.description_2'
          : 'register_billing_info.description_2_no_free_quota',
        {
          pricePerMonth: plan.pricePerMonth,
          freeQuota: plan.freeQuota,
          pricePerInterview: plan.freeQuota
            ? plan.pricePerMonth / plan.freeQuota
            : '',
          pricePerAdditionalInterview: plan.pricePerAdditionalConfirmation,
        }
      )
    } else {
      return ''
    }
  }

  async created() {
    const billingInfoDb = new BillingInfoDatabase({
      client: getFirestore(app),
    })

    const billingInfo = await billingInfoDb.getObj(this.uid)
    if (billingInfo) {
      this.isNewBillingInfo = false
      this.billingInfo = billingInfo
    }
  }

  mounted() {
    const payWithCreditCardATag = document.getElementById(
      'pay_with_credit_card'
    )
    if (payWithCreditCardATag) {
      payWithCreditCardATag.addEventListener('click', this.payWithCreditCard)
    }
  }

  beforeUnmount() {
    const payWithCreditCardATag = document.getElementById(
      'pay_with_credit_card'
    )
    if (payWithCreditCardATag) {
      payWithCreditCardATag.removeEventListener('click', this.payWithCreditCard)
    }
  }

  payWithCreditCard(event: MouseEvent) {
    event.preventDefault()
    this.$router.replace('/register-credit-card')
  }

  back() {
    this.$router.back()
  }

  save() {
    this.isSaving = true
    const billingInfoDb = new BillingInfoDatabase({
      client: getFirestore(app),
    })
    const billingInfo = {
      ...this.billingInfo,
      photoURL: this.user.photoURL,
      createdBy: this.uid,
      updatedAt: new Date(),
    }
    if (this.isNewBillingInfo) {
      ;(billingInfo as unknown as { createdAt: Date }).createdAt = new Date()
    }
    billingInfoDb
      .set(this.uid, billingInfo)
      .then(async () => {
        const userDb = new UserDatabase({
          client: getFirestore(app),
        })
        await userDb.update(this.uid, {
          paymentMethod: PaymentMethod.INVOICE,
        })
      })
      .then(() => {
        alert($t('register_billing_info.message_billing_information_is_saved'))
        this.$router.push('/calendar')
      })
      .catch(() => {
        alert(
          $t('register_billing_info.error_billing_information_is_not_saved')
        )
      })
      .finally(() => (this.isSaving = false))
    sendTrackingEvent(`${this.$route.path}:save`, billingInfo, this.uid)
  }
}
