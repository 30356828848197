import { ActionContext } from 'vuex'

export interface ApplicantState {
  isLoadedApplicantState: boolean
  userId?: string
  userDisplayName?: string
  userPhoneNumber?: string
}

export const state = (): ApplicantState => ({
  isLoadedApplicantState: false,
  userId: undefined,
  userDisplayName: undefined,
  userPhoneNumber: undefined,
})

const STORAGE_KEY_APPLICANT = 'applicant'

export const mutations = {
  updateIsLoadedApplicantState(state: ApplicantState, value: boolean) {
    state.isLoadedApplicantState = value
  },
  updateApplicant(state: ApplicantState, value?: Pick<ApplicantState, 'userId' | 'userDisplayName' | 'userPhoneNumber'>) {
    state.userId = value?.userId
    state.userDisplayName = value?.userDisplayName || ''
    state.userPhoneNumber = value?.userPhoneNumber
    if (state.isLoadedApplicantState) {
      if (value) {
        window.localStorage.setItem(STORAGE_KEY_APPLICANT, JSON.stringify(value))
      } else {
        window.localStorage.removeItem(STORAGE_KEY_APPLICANT)
      }
    }
  },
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  loadApplicantState({ dispatch, commit, state }: ActionContext<ApplicantState, any>) {
    const shouldSetupApplicantstate = !state.isLoadedApplicantState
    if (shouldSetupApplicantstate) {
      const applicantStr = window.localStorage.getItem(STORAGE_KEY_APPLICANT)
      if (applicantStr != null) {
        const applicant = JSON.parse(applicantStr)
        commit('updateApplicant', applicant)
      }
      commit('updateIsLoadedApplicantState', true)
    }
  },
}
