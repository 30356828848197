import { doc, DocumentReference } from '@firebase/firestore'
import { ID, Model } from '@shared/models/models'
import { Database, DatabaseOptions } from './database'

export interface RequiredParentDatabaseOptions<R extends Model>
  extends DatabaseOptions {
  parentRef: DocumentReference<R>
  parentDb: Database<R>
}

export abstract class ChildDatabase<
  T extends Model,
  R extends Model
  > extends Database<T> { // eslint-disable-line
  protected parentRef: DocumentReference<R>
  protected parentDb: Database<R>
  protected options: RequiredParentDatabaseOptions<R>

  constructor(options: RequiredParentDatabaseOptions<R>) {
    super(options)
    const { parentDb, parentRef } = options
    this.parentRef = parentRef
    this.parentDb = parentDb
    this.options = options
  }

  public ref(id: ID): DocumentReference<T> {
    return doc(this.collection(), id) as DocumentReference<T>
  }
}
