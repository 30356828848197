import type { Applicant } from '../models/applicant'
import type { BusinessOwner } from '../models/business-owner'
import type { GoogleEvent, MeetingCommon } from '../models/meeting'

import { toJSTDateStr, toJSTDateTimeStr } from './rules'

export function getVideoEntryPoint(event?: GoogleEvent): string | null {
  if (!event) {
    return null
  }

  const entryPoint = event.conferenceData.entryPoints.find((entryPoint) => {
    return entryPoint.entryPointType === 'video'
  })

  if (!entryPoint) {
    console.log('Video entry point not found')
    return null
  }

  console.log('Video entry point found', entryPoint.uri)
  return entryPoint.uri
}

export const VariableNames = [
  'APPLICANT_NAME',
  'APPLICANT_PHONE_NUMBER',
  'BUSINESS_NAME',
  'BUSINESS_PHONE_NUMBER',
  'MEETING_START_TIME',
  'MEETING_START_TIME_JP',
  'MEETING_START_TIME_EU',
  'MEETING_START_TIME_US',
  'MEETING_START_DATE',
  'MEETING_START_DATE_JP',
  'MEETING_START_DATE_EU',
  'MEETING_START_DATE_US',
  'GMEET_URL',
] as const

type VariableNameType = typeof VariableNames[number]

export function extractVariableFromMeeting({
  meeting,
  applicant,
  businessOwner,
}: {
  meeting: MeetingCommon
  applicant: Applicant
  businessOwner: BusinessOwner
}): {
    [key in VariableNameType]: string // eslint-disable-line
  } { // eslint-disable-line
  const GMEET_URL = getVideoEntryPoint(meeting.googleEvent)
  return {
    APPLICANT_NAME: applicant.name as string,
    APPLICANT_PHONE_NUMBER: applicant.phoneNumber as string,
    BUSINESS_NAME: businessOwner.businessName as string,
    BUSINESS_PHONE_NUMBER: businessOwner.phoneNumber as string,
    MEETING_START_TIME: toJSTDateTimeStr(new Date(meeting.slot.startTime)),
    MEETING_START_TIME_JP: toJSTDateTimeStr(
      new Date(meeting.slot.startTime),
      'jp'
    ),
    MEETING_START_TIME_EU: toJSTDateTimeStr(
      new Date(meeting.slot.startTime),
      'eu'
    ),
    MEETING_START_TIME_US: toJSTDateTimeStr(
      new Date(meeting.slot.startTime),
      'us'
    ),
    MEETING_START_DATE: toJSTDateStr(new Date(meeting.slot.startTime)),
    MEETING_START_DATE_JP: toJSTDateStr(new Date(meeting.slot.startTime), 'jp'),
    MEETING_START_DATE_EU: toJSTDateStr(new Date(meeting.slot.startTime), 'eu'),
    MEETING_START_DATE_US: toJSTDateStr(new Date(meeting.slot.startTime), 'us'),
    GMEET_URL: GMEET_URL || '',
  }
}
