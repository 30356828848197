export interface Plan {
  id: string
  pricePerMonth: number
  pricePerAdditionalConfirmation: number
  freeQuota: number
  description: string
}

export const PLANS: Plan[] = [
  {
    id: 'STARTER',
    pricePerMonth: 0,
    pricePerAdditionalConfirmation: 1000,
    freeQuota: 0,
    description: 'select_plan.plan_starter_description',
  },
  {
    id: 'TINY',
    pricePerMonth: 1000,
    pricePerAdditionalConfirmation: 500,
    freeQuota: 5,
    description: 'select_plan.plan_tiny_description',
  },
  {
    id: 'SMALL',
    pricePerMonth: 5000,
    pricePerAdditionalConfirmation: 500,
    freeQuota: 25,
    description: 'select_plan.plan_small_description',
  },
  {
    id: 'MEDIUM',
    pricePerMonth: 10000,
    pricePerAdditionalConfirmation: 500,
    freeQuota: 50,
    description: 'select_plan.plan_medium_description',
  },
]
