import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import CalendarPage from '../views/CalendarPage.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: CalendarPage,
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    props: true,
    component: () => import('../views/SignInPage.vue'),
  },
  {
    path: '/sign-in-with-phone-number',
    name: 'sign-in-with-phone-number',
    props: true,
    component: () => import('../views/SignInWithPhoneNumberPage.vue'),
  },
  {
    path: '/register-credit-card',
    name: 'register-credit-card',
    props: true,
    component: () => import('../views/RegisterCreditCardPage.vue'),
  },
  {
    path: '/register-billing-info',
    name: 'register-billing-info',
    props: true,
    component: () => import('../views/RegisterBillingInfoPage.vue'),
  },
  {
    path: '/select-plan',
    name: 'select-plan',
    props: true,
    component: () => import('../views/SelectPlanPage.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    props: true,
    component: () => import('../views/CalendarPage.vue'),
  },
  {
    path: '/app/:userId',
    name: 'select-a-slot',
    props: true,
    component: () => import('../views/applicants/SelectASlotPage.vue'),
  },
  {
    path: '/qrcode',
    name: 'QrCode',
    props: true,
    component: () => import('../views/QrcodePage.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    props: true,
    component: () => import('../views/SettingsPage.vue'),
  },
  {
    path: '/settings/update',
    name: 'Update settings',
    props: true,
    component: () => import('../views/UpdateNotificationPage.vue'),
  },
  {
    path: '/settings/:notificationId',
    name: 'Update existing settings',
    props: true,
    component: () => import('../views/UpdateNotificationPage.vue'),
  },
  {
    path: '/profile/update',
    name: 'Update profile',
    props: true,
    component: () => import('../views/UpdateProfilePage.vue'),
  },
  {
    path: '/payment',
    name: 'Payment overview',
    props: true,
    component: () => import('../views/PaymentOverviewPage.vue'),
  },
  {
    path: '/auth-callback',
    name: 'Auth callback',
    props: true,
    component: () => import('../views/AuthCallback.vue'),
  },
  {
    path: '/meeting/answer',
    name: 'Meeting response',
    props: true,
    component: () => import('../views/MeetingAnswer.vue'),
  },
  {
    path: '/sms/:applicantId',
    name: 'Sms history',
    props: true,
    component: () => import('../views/SmsHistoryPage.vue'),
  },
  {
    path: '/developer',
    name: 'Developer',
    props: true,
    component: () => import('../views/DeveloperPage.vue'),
  },
  {
    path: '/landing-page-generation',
    name: 'landing-page-generation',
    props: true,
    component: () => import('../views/LandingPageGenerator/index.vue'),
  },
  {
    path: '/administrators',
    name: 'administrators',
    props: true,
    component: () => import('../views/AdministratorsPage.vue'),
  },
  {
    path: '/customers',
    name: 'customers',
    props: true,
    component: () => import('../views/CustomersPage.vue'),
  },
  {
    path: '/customers/create',
    name: 'create-customer',
    props: true,
    component: () => import('../views/CreateCustomerPage.vue'),
  },
  {
    path: '/customers/:userId/update',
    name: 'update-customer',
    props: true,
    component: () => import('../views/CreateCustomerPage.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
