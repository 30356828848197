import { Model, Optional, Response } from './models'

export enum PersonalTokenType {
  ANSWER = 'answer',
  PERSONAL = 'personal',
}
export enum PersonalTokenStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
}

export interface PersonalTokenData {
  userId: string
  type: PersonalTokenType
}

export interface PersonalTokenDataActionSet extends PersonalTokenData {
  notificationId: string
  meetingId: string
  storeValueAs: string
  value: string
  timestamp: number
}

export interface PersonalTokenCommon {
  token: string
  status: PersonalTokenStatus
  data: PersonalTokenDataActionSet
  type: PersonalTokenType
}

export interface PersonalToken extends PersonalTokenCommon, Model {
  deactivatedAt: Date | null
}
export interface PersonalTokenResponse extends PersonalTokenCommon, Response {
  deactivatedAt: string | null
}

export type PersonalTokenInput = Optional<PersonalToken>
