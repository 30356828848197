import type { Meeting, MeetingResponse } from '@shared/models/meeting'
import type { Model, Response } from '@shared/models/models'
import type { Rule } from '@shared/models/rule'
import type { Slot, SlotResponse } from '@shared/models/slot'
import type {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp
} from 'firebase/firestore'

/**
 *
 * @param {DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>} snap
 * @return {Meeting | null}
 */
export function toObj<T extends Model>(
  snap: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): T | null {
  const data = snap.data()
  if (!data) {
    return null
  }
  return {
    id: snap.id,
    path: snap.ref.path,
    ...data,
    createdAt: data && data.createdAt ? data.createdAt.toDate() : null,
    updatedAt: data && data.updatedAt ? data.updatedAt.toDate() : null,
  } as T
}

/**
 *
 * @param {DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>} snap
 * @return {Meeting | null}
 */
export function toResponse<T extends Response>(
  snap: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): T | null {
  const data = snap.data()
  if (!data) {
    return null
  }

  return {
    id: snap.id,
    path: snap.ref.path,
    ...data,
    createdAt:
      data && data.createdAt
        ? (data.createdAt.toDate().toISOString() as string)
        : null,
    updatedAt:
      data && data.updatedAt
        ? (data.updatedAt.toDate().toISOString() as string)
        : null,
  } as T
}

/**
 *
 * @param {DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>} snap
 * @return {Rule}
 */
export function toRule(
  snap: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Rule | null {
  const data = snap.data()
  const superData = toObj<Rule>(snap)
  if (!data || !superData) {
    return null
  }

  return {
    ...superData,
    startTime: (data?.startTime as Timestamp)?.toDate(),
    endTime: (data?.endTime as Timestamp)?.toDate(),
  }
}

/**
 *
 * @param {DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>} snap
 * @return {Meeting | null}
 */
export function toMeeting(
  snap: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): Meeting | null {
  const data = snap.data()
  const superData = toObj<Meeting>(snap)
  if (!data || !superData) {
    return null
  }

  const slot = superData.slot as any // eslint-disable-line
  return {
    ...superData,
    slot: {
      ...slot,
      startTime: (slot?.startTime as Timestamp)?.toDate(),
      createdAt: (slot?.createdAt as Timestamp)?.toDate(),
      updatedAt: (slot?.updatedAt as Timestamp)?.toDate(),
    },
  }
}

/**
 *
 * @param {DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>} snap
 * @return {Meeting | null}
 */
export function toMeetingResponse(
  snap: DocumentSnapshot<DocumentData> | QueryDocumentSnapshot<DocumentData>
): MeetingResponse | null {
  const data = snap.data()
  const superData = toResponse<MeetingResponse>(snap)
  if (!data || !superData) {
    return null
  }

  const slot = superData.slot as any // eslint-disable-line
  return {
    ...superData,
    slot: {
      ...slot,
      createdAt: (slot?.createdAt as Timestamp)?.toDate().toISOString(),
      updatedAt: (slot?.updatedAt as Timestamp)?.toDate().toISOString(),
      startTime: (slot?.startTime as Timestamp)?.toDate().toISOString(),
    },
  }
}

/**
 *
 * @param {QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>} snap
 * @return {Slot}
 */
export function toSlot(
  snap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>
): Slot | null {
  const data = snap.data()
  const superData = toObj<Slot>(snap)
  if (!data || !superData) {
    return null
  }

  return {
    ...superData,
    startTime: (data?.startTime as Timestamp)?.toDate(),
  }
}

/**
 *
 * @param {QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>} snap
 * @return {SlotResponse}
 */
export function toSlotResponse(
  snap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>
): SlotResponse | null {
  const data = snap.data()
  const superData = toResponse<SlotResponse>(snap)
  if (!data || !superData) {
    return null
  }

  return {
    ...superData,
    startTime: (data?.startTime as Timestamp)?.toDate().toISOString(),
  }
}
