<template>
  <v-list-item v-if="isCheckingGoogleLink">
    <v-list-item-title>
      {{ $t('settings.checking_google_linkage_status') }}
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-else-if="isExchangingGoogleToken">
    <v-list-item-title>
      {{ $t('settings.we_re_signing_you_on_with_google') }}
    </v-list-item-title>
  </v-list-item>

  <v-list-item v-else-if="!isGoogleLinked">
    <v-list-item-content>
      <v-list-item-title>
        <v-btn class="mb-1" :href="authUrl">
          <v-img src="/icons/google.png" width="30" class="mr-1" />
          {{ $t('settings.button_sign_in_to_google_calendar') }}
        </v-btn>
      </v-list-item-title>
      <v-list-item-subtitle
        class="google-calendar-description text-wrap text-left grey--text"
      >
        {{ $t('settings.sign_in_to_google_calendar_description') }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
  <v-list-item v-else>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t('settings.you_have_already_linked_with_google_calendar') }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action class="d-flex flex-row">
      <v-btn outlined rounded color="red" class="ml-2" @click="unlink()">
        <v-icon left> mdi-link-off </v-icon>
        {{ $t('settings.unlink_google') }}
      </v-btn>
      <v-btn outlined rounded class="ml-2" @click="reconnect()">
        <v-icon left> mdi-link </v-icon>
        {{ $t('settings.reconnect') }}
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { Component, Vue } from 'vue-property-decorator'
import { UserDatabase } from '@/db/user-database'
import { getFirestore } from '@firebase/firestore'
import { app } from '../helpers/firebase'
import { CredentialLinkStatus } from '@shared/models/credential'
import { createAuthUrlWithCalendar } from '../helpers/google'
import { $t } from '@/plugins/i18n'

const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

@Component({
  components: {},
  computed: {
    ...mapStateAuth(['uid']),
  },
})
export default class GoogleLink extends Vue {
  isCheckingGoogleLink = true
  isGoogleLinked = false
  isExchangingGoogleToken = false
  authUrl = createAuthUrlWithCalendar()

  async checkGoogleLinkage() {
    let userDb = new UserDatabase({
      client: getFirestore(app),
    })

    const googleCredential = await userDb
      .getCredentialDb(this.uid)
      .getGoogleCredentialObj()

    this.isCheckingGoogleLink = false
    if (googleCredential) {
      this.isExchangingGoogleToken =
        googleCredential.status === CredentialLinkStatus.PENDING
      this.isGoogleLinked =
        googleCredential.status === CredentialLinkStatus.ACTIVE
    } else {
      this.isGoogleLinked = false
      this.isExchangingGoogleToken = false
    }
  }

  async unlink() {
    let userDb = new UserDatabase({
      client: getFirestore(app),
    })

    if (confirm($t('settings.google_unlink_notice'))) {
      await userDb.getCredentialDb(this.uid).clearGoogleCredential()
      this.isCheckingGoogleLink = true
      await this.checkGoogleLinkage()
    }
  }

  async reconnect() {
    window.location.href = this.authUrl
  }

  created() {
    this.checkGoogleLinkage()
  }
}
</script>

<style scoped lang="scss">
.v-list-item__title {
  text-align: left;
}
.v-list-item__action {
  align-items: center;
}
.google-calendar-description {
  font-size: 13px;
}
</style>
