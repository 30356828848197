import {
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  format,
} from 'date-fns'
import { stringify } from 'qs'
import { createEvent, EventStatus } from 'ics'
import { MeetingResponse } from '@shared/models/meeting'
import { Applicant } from '@shared/models/applicant'
import { User, UserResponse } from '@shared/models/user'
import { getVideoEntryPoint } from '@shared/helpers/variables'
import { $t } from '@/plugins/i18n'

declare const window: any // eslint-disable-line

export function generateDescriptionFromMeeting(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
): string {
  return $t('calendar.event_calendar_description', {
    title: generateTitleFromMeeting(meeting, applicant, businessOwner),
    businessName: businessOwner.businessName,
    applicantName: applicant.name,
    applicantPhone: applicant.phoneNumber,
    meetingRoomUrl: getVideoEntryPoint(meeting.googleEvent),
    interviewDateTime: format(
      new Date(meeting.slot.startTime),
      'yyyy/MM/dd HH:mm'
    ),
    confirmedAt: format(
      new Date(meeting.confirmedAt || meeting.createdAt),
      'yyyy/MM/dd HH:mm'
    ),
  })

  //   return `
  // TITLE: Interview for ${businessOwner.businessName} for ${applicant.name}
  // BUSINESS_NAME: ${businessOwner.businessName}
  // ----------
  // APPLICANT_NAME: ${applicant.name}
  // APPLICANT_PHONE: ${applicant.phoneNumber}
  // INTERVIEW_DATETIME: ${format(
  //     new Date(meeting.slot.startTime),
  //     'yyyy/MM/dd HH:mm:ss'
  //   )}
  // CONFIRMED_AT: ${format(
  //     new Date(meeting.confirmedAt || meeting.createdAt),
  //     'yyyy/MM/dd HH:mm:ss'
  //   )}
  // `
}
export function generateTitleFromMeeting(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
): string {
  return $t('calendar.event_calendar_title', {
    businessName: businessOwner.businessName,
    applicantName: applicant.name,
  })
}

export function tryGoogleCalendarAppLink(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
): void {
  const googleAppUrl = generateAddtoGoogleCalendarInAppUrl(
    meeting,
    applicant,
    businessOwner
  )
  console.log('Going to App URL', googleAppUrl)
  setTimeout(function () {
    const googleWebUrl = generateAddToGoogleCalendarUrl(
      meeting,
      applicant,
      businessOwner
    )
    console.log('Still here? Going to Web URL', googleWebUrl)

    window.location = googleWebUrl
  }, 25)

  window.location = googleAppUrl
}

export function generateAddToOutlookCalendarUrl(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
) {
  // https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=t%C3%A9t&enddt=2022-02-08T10%3A45%3A00%2B00%3A00&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-02-08T10%3A15%3A00%2B00%3A00&subject=T%C3%A9t
  const { slot } = meeting
  const dateFormatStr = 'yyyy-MM-dd\'T\'HH:mm:ssX' // eslint-disable-line
  const startTime = new Date(slot.startTime)
  const fromText = format(startTime, dateFormatStr)
  const toText = format(
    addMinutes(startTime, slot.durationMinute),
    dateFormatStr
  )
  const outlookQuery = stringify({
    allday: false,
    body: generateDescriptionFromMeeting(meeting, applicant, businessOwner),
    subject: generateTitleFromMeeting(meeting, applicant, businessOwner),
    rru: 'addevent',
    path: '/calendar/action/compose',
    startdt: fromText,
    enddt: toText,
  })

  return `https://outlook.live.com/calendar/0/deeplink/compose?${outlookQuery}`
}

export function generateAddtoGoogleCalendarInAppUrl(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
): string {
  // https://www.reddit.com/r/shortcuts/comments/9ifjz7/all_google_app_urls_and_callback_urls/
  const { slot } = meeting
  const dateFormatStr = 'yyyyMMdd\'T\'HHmmssX' // eslint-disable-line
  const startTime = new Date(slot.startTime)
  const fromText = startTime.toISOString() //format(startTime, dateFormatStr)
  const toText = addMinutes(startTime, slot.durationMinute).toISOString()
  // format(
  //   addMinutes(startTime, slot.durationMinute),
  //   dateFormatStr
  // )

  const googleQuery = stringify({
    text: generateTitleFromMeeting(meeting, applicant, businessOwner),
    details: generateDescriptionFromMeeting(meeting, applicant, businessOwner),
    dates: `${fromText}/${toText}`,
    action: 'create',
  })

  return `com.google.calendar://?${googleQuery}`
}

export function generateAddToGoogleCalendarUrl(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
): string {
  const { slot } = meeting

  const dateFormatStr = 'yyyyMMdd\'T\'HHmmssX' // eslint-disable-line
  const startTime = new Date(slot.startTime)
  const fromText = format(startTime, dateFormatStr)
  const toText = format(
    addMinutes(startTime, slot.durationMinute),
    dateFormatStr
  )

  const googleQuery = stringify({
    text: generateTitleFromMeeting(meeting, applicant, businessOwner),
    details: generateDescriptionFromMeeting(meeting, applicant, businessOwner),
    dates: `${fromText}/${toText}`,
  })

  return `https://calendar.google.com/calendar/r/eventedit?${googleQuery}`
}

export function generateAddToYahooCalendarUrl(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
): string {
  const { slot } = meeting
  const startDate = new Date(slot.startTime)
  const endDate = addMinutes(startDate, slot.durationMinute)
  const dateFormatStr = "yyyyMMdd'T'HHmmssX" // eslint-disable-line
  const fromText = format(startDate, dateFormatStr)
  const duration = `${(differenceInHours(endDate, startDate) + '').padStart(
    2,
    '0'
  )}${((differenceInMinutes(endDate, startDate) % 60) + '').padStart(2, '0')}`
  const yahooQuery = stringify({
    v: 60,
    view: 'd',
    type: '20',
    title: generateTitleFromMeeting(meeting, applicant, businessOwner),
    st: fromText,
    desc: generateDescriptionFromMeeting(meeting, applicant, businessOwner),
    dur: duration,
  })

  return `https://calendar.yahoo.com/?${yahooQuery}`
}

export function generateIcsFile(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
) {
  const { slot } = meeting
  const startDate = new Date(slot.startTime)
  const endDate = addMinutes(startDate, slot.durationMinute)
  const event = {
    start: [
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      startDate.getDate(),
      startDate.getHours(),
      startDate.getMinutes(),
    ] as [number, number, number, number, number],
    duration: {
      hours: differenceInHours(endDate, startDate),
      minutes: differenceInMinutes(endDate, startDate) % 60,
    },
    title: generateTitleFromMeeting(meeting, applicant, businessOwner),
    description: generateDescriptionFromMeeting(
      meeting,
      applicant,
      businessOwner
    ),
    categories: ['Meetings'],
    status: 'CONFIRMED' as EventStatus,
    busyStatus: 'BUSY' as const,
  }
  const result = createEvent(event)
  const { value } = result
  return value
}

export function generateIcsUrl(
  meeting: MeetingResponse,
  applicant: Applicant,
  businessOwner: UserResponse | User
) {
  const icsContent = generateIcsFile(meeting, applicant, businessOwner)
  console.log(icsContent)
  const data = new Blob([icsContent as string], {
    type: 'text/calendar',
  })
  return window.URL.createObjectURL(data)
}
