


































import { getFirestore } from '@firebase/firestore'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { ID } from '../../shared/models/models'
import {
  Notification,
  NotificationStatus,
  NotificationType,
  NotificationTypeFn,
} from '../../shared/models/notification'
import { NotificationDatabase } from '../db/notification-database'
import { app } from '../helpers/firebase'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { $t } from '@/plugins/i18n'
import { sendTrackingEvent } from '@/utils/eventTracker'

const FIX_TRANSLATE_PARAMS = {
  APPLICANT_NAME: '{APPLICANT_NAME}',
  BUSINESS_NAME: '{BUSINESS_NAME}',
  BUSINESS_PHONE_NUMBER: '{BUSINESS_PHONE_NUMBER}',
  MEETING_START_TIME: '{MEETING_START_TIME}',
  GMEET_URL: '{GMEET_URL}',
  APPLICANT_PHONE_NUMBER: '{APPLICANT_PHONE_NUMBER}',
  URL_YES: '{URL_YES}',
  URL_NO: '{URL_NO}',
  URL_NO_SHOW: '{URL_NO_SHOW}',
  GMEET_URL_TEXT: '{GMEET_URL_TEXT}',
}

export const DEFAULT_NOTIFICATION_CONTENT_MAP = {
  [NotificationType.AFTER_MEETING_CONFIRMATION_NOTIFICATION]: $t(
    'update_notification.notification_after_meeting_confirmation',
    FIX_TRANSLATE_PARAMS
  ),
  [NotificationType.MEETING_NEXT_X_DAY]: $t(
    'update_notification.notification_meeting_next_x_day',
    FIX_TRANSLATE_PARAMS
  ),
  [NotificationType.MEETING_IN_X_MINUTE]: $t(
    'update_notification.notification_meeting_in_x_minute',
    FIX_TRANSLATE_PARAMS
  ),
  [NotificationType.MEETING_AFTER_X_MINUTE]: $t(
    'update_notification.notification_metting_after_x_minute',
    FIX_TRANSLATE_PARAMS
  ),
  [NotificationType.MEETING_AFTER_X_HOUR_TO_BUSINESS_OWNER]: $t(
    'update_notification.notification_metting_after_x_hour_to_business_owner',
    FIX_TRANSLATE_PARAMS
  ),
  [NotificationType.MEETING_AFTER_X_DAYS_TO_BUSINESS_OWNER]: $t(
    'update_notification.notification_metting_after_x_days_to_business_owner',
    FIX_TRANSLATE_PARAMS
  ),
}

@Component({
  components: {},
  computed: {
    ...mapStateAuth(['uid']),
  },
})
export default class UpdateNotification extends Vue {
  uid!: string

  notificationId?: ID
  notificationType?: NotificationType
  notificationTitle = ''
  notification?: Notification
  notificationEnabled = false
  notificationContent = ''

  async created() {
    this.notificationId = this.$route.params.notificationId
    this.notificationType = this.$route.query.type as any // eslint-disable-line

    if (this.notificationId) {
      let notificationDb = new NotificationDatabase({
        client: getFirestore(app),
      })

      this.notification = (await notificationDb.getObj(
        this.notificationId
      )) as Notification
      this.notificationType = this.notification.type
      this.notificationTitle = NotificationTypeFn.getLocalizedTitle(
        this.notificationType,
        this.notification,
        $t
      )
      this.notificationEnabled =
        this.notification.status == NotificationStatus.ENABLED
      this.notificationContent =
        this.notification.content ||
        DEFAULT_NOTIFICATION_CONTENT_MAP[this.notificationType]
    } else if (this.notificationType) {
      this.notificationTitle = NotificationTypeFn.getLocalizedTitle(
        this.notificationType,
        this.notification,
        $t
      )
      this.notificationEnabled = false
      this.notificationContent =
        DEFAULT_NOTIFICATION_CONTENT_MAP[this.notificationType]
    }
  }

  back() {
    this.$router.back()
  }

  async save() {
    let notificationDb = new NotificationDatabase({
      client: getFirestore(app),
    })

    let content =
      this.notificationContent ==
      DEFAULT_NOTIFICATION_CONTENT_MAP[this.notificationType!] // eslint-disable-line
        ? null
        : this.notificationContent

    let notification: Notification
    if (this.notificationId) {
      await notificationDb.update(this.notificationId, {
        content,
        status: this.notificationEnabled
          ? NotificationStatus.ENABLED
          : NotificationStatus.DISABLED,
      })
      notification = {
        id: this.notificationId,
        content,
        status: this.notificationEnabled
          ? NotificationStatus.ENABLED
          : NotificationStatus.DISABLED,
      } as Notification
      this.back()
    } else {
      notification = await notificationDb.create({
        ownerId: this.uid,
        type: this.notificationType,
        content,
        status: this.notificationEnabled
          ? NotificationStatus.ENABLED
          : NotificationStatus.DISABLED,
        timezone: 'Asia/Tokyo',
      })
      this.back()
    }
    sendTrackingEvent(`${this.$route.path}:save`, notification, this.uid)
  }
}
