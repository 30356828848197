import {
  collection,
  CollectionReference,
  QueryConstraint,
  where,
} from '@firebase/firestore'
import { Invoice } from '@shared/models/invoice'
import { User } from '@shared/models/user'
import { ChildDatabase } from './child-database'

export class InvoiceDatabase extends ChildDatabase<Invoice, User> {
  collection(): CollectionReference {
    return collection(this.db(), this.parentRef?.path as string, 'INVOICES')
  }

  getUnpaidInvoices() {
    const wheres: QueryConstraint[] = [where('status', '==', 'open')].filter(
      (_) => !!_
    ) as QueryConstraint[]

    return this.listObj(wheres)
  }
}
