



















































import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import {
  BusinessOwner,
  UserAdminRole,
} from '../../../shared/models/business-owner'
import { $t } from '@/plugins/i18n'
import { UserDatabase } from '../../db/user-database'
import { getFirestore } from '@firebase/firestore'
import { app } from '../../helpers/firebase'
import { sendTrackingEvent } from '@/utils/eventTracker'
import { normalizePhoneNumber } from '../../../shared/helpers/phones'

@Component({
  components: {},
  props: {
    value: Boolean,
  },
  computed: {
    ...mapStateAuth(['uid', 'user']),
  },
})
export default class extends Vue {
  uid!: string

  phoneNumber = ''

  isSaving = false

  get isEnabledAddButton() {
    return !!this.phoneNumber
  }

  created() {}

  async handleSave() {
    this.isSaving = true

    try {
      const userDb = new UserDatabase({
        client: getFirestore(app),
      })

      const normalizedPhoneNumber = normalizePhoneNumber(this.phoneNumber)
      if (normalizedPhoneNumber) {
        let user = (await userDb.getUserByPhoneNumber(
          normalizedPhoneNumber
        )) as BusinessOwner | null
        if (user) {
          if (!user.adminRole) {
            await userDb.update(user.id, {
              adminRole: UserAdminRole.ADMIN,
              adminRoleCreatedBy: this.uid,
            })
          }
        } else {
          // create preregister user here
          user = (await userDb.create({
            id: `preregister:${normalizedPhoneNumber}`,
            phoneNumber: normalizedPhoneNumber,
            adminRole: UserAdminRole.ADMIN,
            adminRoleCreatedBy: this.uid,
          })) as BusinessOwner | null
        }
        this.$emit('onAdded', {
          ...user,
          adminRole: UserAdminRole.ADMIN,
          adminRoleCreatedBy: this.uid,
        })
        this.$emit('input', false)
        sendTrackingEvent(
          `${this.$route.path}:handleSave`,
          {
            ...user,
            adminRole: UserAdminRole.ADMIN,
            adminRoleCreatedBy: this.uid,
          },
          this.uid
        )
      } else {
        alert($t('create_administrator.error_please_confirm_phone_number'))
      }
    } catch (e) {
      console.error(e)
    }

    this.isSaving = false
  }
}
