



























import { Component, Vue } from 'vue-property-decorator'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from '@firebase/firestore'
import { UserDatabase } from '../db/user-database'
import { app } from '../helpers/firebase'
import { createNamespacedHelpers } from 'vuex'
import { BusinessOwner } from '../../shared/models/business-owner'
import { STORAGE_KEY_LANGUAGE } from '../../shared/constants'
import { i18n } from '../plugins/i18n'
import { getBrowserLanguage } from '../utils/utils'
import { createShortenUrl } from '../helpers/shorten-url'
import { getPublicUrl } from '../utils/qrcode'
import { signUpForTracking, identifyTrackedUser } from '@/utils/eventTracker'
const auth = createNamespacedHelpers('auth')

@Component({
  components: {},
  methods: {
    ...auth.mapMutations(['updateAuthStatus']),
  },
})
export default class SignIn extends Vue {
  updateAuthStatus!: (user?: BusinessOwner) => void

  created() {
    const auth = getAuth()
    auth.signOut()
  }

  signInWithPhoneNumber() {
    this.$router.push('/sign-in-with-phone-number')
  }

  async signInWithEmail() {
    const auth = getAuth()
    auth.useDeviceLanguage()

    const provider = new GoogleAuthProvider()

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result)
        // const token = credential?.accessToken
        // // The signed-in user info.
        // const user = result.user
        // // ...
        console.log({ result })
        let firebaseUser = result.user

        const userDb = new UserDatabase({ client: getFirestore(app) })
        let user = await userDb.getObj(firebaseUser.uid)

        if (user) {
          if (!(user as BusinessOwner).shortenUrl) {
            const result = await createShortenUrl(getPublicUrl(user.id)).catch(
              () => null
            )
            if (result && result.shortenUrl) {
              await userDb.update(user.id, {
                shortenUrl: result.shortenUrl,
              })
              ;(user as BusinessOwner).shortenUrl = result.shortenUrl
            }
          }

          identifyTrackedUser(user.id)
          signUpForTracking(user)

          return user as BusinessOwner
        } else {
          const result = await createShortenUrl(
            getPublicUrl(firebaseUser.uid)
          ).catch(() => null)

          user = await userDb.createBusinessOwner({
            id: firebaseUser.uid,
            name: firebaseUser.displayName,
            email: firebaseUser.email,
            phoneNumber: firebaseUser.phoneNumber,
            photoURL: firebaseUser.photoURL,
            language: getBrowserLanguage(),
            shortenUrl: result?.shortenUrl || null,
          })

          identifyTrackedUser(user.id)
          signUpForTracking(user)

          return user as BusinessOwner
        }
      })
      .then((user) => {
        this.updateAuthStatus(user)
        if (
          user.language &&
          user.language !== localStorage.getItem(STORAGE_KEY_LANGUAGE)
        ) {
          localStorage.setItem(STORAGE_KEY_LANGUAGE, user.language)
          i18n.locale = user.language
        }
        setTimeout(() => this.$router.push('/'), 100)
      })
      .catch((error) => {
        // // Handle Errors here.
        // const errorCode = error.code
        // const errorMessage = error.message
        // // The email of the user's account used.
        // const email = error.email
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error)
        // // ...
        console.log({ error })
      })
  }
}
