import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { STORAGE_KEY_LANGUAGE } from '../../shared/constants'
import { LOCALE_EN } from '../../shared/locales/en'
import { LOCALE_JA } from '../../shared/locales/ja'

Vue.use(VueI18n)

// Ready translated locale messages
const messages = {
  en: LOCALE_EN,
  ja: LOCALE_JA,
}

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: localStorage.getItem(STORAGE_KEY_LANGUAGE) || navigator.language, // set locale
  messages, // set locale messages
})

type Join<K, P> = K extends string | number
  ? P extends string | number
  ? `${K}${'' extends P ? '' : '.'}${P}` // eslint-disable-line
  : never  // eslint-disable-line
  : never

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
]

// type Paths<T, D extends number = 10> = [D] extends [never]
//   ? never
//   : T extends object
//   ? {
//       [K in keyof T]-?: K extends string | number
//         ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
//         : never
//     }[keyof T]
//   : ''

type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : /* eslint-disable */
  T extends object
  ? /* eslint-enable */
  { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]   // eslint-disable-line
  : ''

export const $t = (key: Leaves<typeof LOCALE_EN>, values?: any) =>
  i18n.tc(key, undefined, values)
