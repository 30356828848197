import { toObj } from '@/helpers/data'
import {
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  documentId,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  // getFirestore,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  SetOptions,
  UpdateData,
  updateDoc,
  where,
  WithFieldValue
} from '@firebase/firestore'
import type { ID, Model, Optional } from '../../shared/models/models'
// import { app } from '../helpers/firebase'

// const firestore = getFirestore(app)
// connectFirestoreEmulator(firestore, 'localhost', 8081)
export interface DatabaseOptions {
  client: Firestore
}

export abstract class Database<T extends Model> {
  protected client: Firestore
  protected options: DatabaseOptions

  constructor(options: DatabaseOptions) {
    const { client } = options
    this.client = client
    this.options = options
  }

  abstract collection(): CollectionReference

  public ref(id: ID): DocumentReference<T> {
    return doc(this.db(), this.collection().path, id) as DocumentReference<T>
  }

  public async get(id: ID | DocumentReference): Promise<DocumentSnapshot<T>> {
    if (id instanceof DocumentReference) {
      return (await getDoc(id)) as DocumentSnapshot<T>
    }

    console.log(this.ref(id as ID).path)
    return (await getDoc(this.ref(id as ID))) as DocumentSnapshot<T>
  }

  public async getObj(id: ID | DocumentReference): Promise<T | null> {
    return this.converter()(await this.get(id)) as T
  }

  public async getListByIds(ids: ID[]): Promise<T[]> {
    // remove duplicate ids
    const uniqueIds: ID[] = []
    for (const id of ids) {
      if (!uniqueIds.includes(id)) {
        uniqueIds.push(id)
      }
    }

    const count = uniqueIds.length
    if (count === 0) {
      return []
    }

    const n = Math.ceil(count / 10)
    const list: T[] = []
    for (let i = 0; i < n; i++) {
      const subIds = uniqueIds.slice(i * 10, Math.min(count, (i + 1) * 10))
      console.log({ subIds })

      const wheres: QueryConstraint[] = [
        where(documentId(), 'in', subIds),
      ].filter((_) => !!_) as QueryConstraint[]

      const temp = await this.listObj(wheres)
      list.push(...temp)
    }

    return list
  }

  public async create({ id, ...input }: Optional<T>): Promise<T> {
    let ref
    if (id) {
      ref = this.ref(id)
    } else {
      ref = doc(this.collection())
    }

    await setDoc(ref, {
      ...input,
      id: ref.id,
      createdAt: new Date(),
      updatedAt: new Date(),
    })
    return this.converter()(await getDoc(ref)) as T
  }

  protected converter(): (
    snap:
      | DocumentSnapshot<DocumentData | T>
      | QueryDocumentSnapshot<DocumentData | T>
  ) => T | null {
    return toObj
  }

  public async listObj(queryConstraint?: QueryConstraint[]): Promise<T[]> {
    return this.list(queryConstraint).then((docs) =>
      docs.map((doc) => this.converter()(doc) as T)
    )
  }

  public async list(
    queryConstraint?: QueryConstraint[]
  ): Promise<QueryDocumentSnapshot<T>[]> {
    const collectionRef = this.collection()

    if (!queryConstraint || queryConstraint.length === 0) {
      return getDocs(query(collectionRef)).then(
        (snap) => snap.docs as QueryDocumentSnapshot<T>[]
      )
    }

    return getDocs(query(collectionRef, ...queryConstraint)).then(
      (snap) => snap.docs as QueryDocumentSnapshot<T>[]
    )
  }

  public async count(queryConstraint?: QueryConstraint[]): Promise<number> {
    const collectionRef = this.collection()

    let snap: QuerySnapshot
    if (!queryConstraint || queryConstraint.length === 0) {
      snap = await getDocs(query(collectionRef))
    } else {
      snap = await getDocs(query(collectionRef, ...queryConstraint))
    }

    return snap.size
  }

  public async set(
    id: ID | DocumentReference<T>,
    data: Optional<T>,
    options?: SetOptions
  ): Promise<void> {
    if (id instanceof DocumentReference) {
      if (options) {
        await setDoc(id, data as WithFieldValue<T>, options)
      } else {
        await setDoc(id, data as WithFieldValue<T>)
      }
    }

    if (options) {
      await setDoc(this.ref(id as ID), data as WithFieldValue<T>, options)
    } else {
      await setDoc(this.ref(id as ID), data as WithFieldValue<T>)
    }
  }

  public async update(
    id: ID | DocumentReference<T>,
    data: Optional<T>
  ): Promise<void> {
    if (id instanceof DocumentReference) {
      await updateDoc(id, data as UpdateData<T>)
    }

    await updateDoc(this.ref(id as ID), data as UpdateData<T>)
  }

  public async delete(id: ID): Promise<void> {
    await deleteDoc(this.ref(id))
  }

  protected db(): Firestore {
    return this.client
  }
}
