import { ID } from '../../shared/models/models'

export const getEmbedCode = (userId: ID) => {
  return `<div
  id="myol-container"
  data-channel-id="${userId}"
  data-applicant-name=""
  data-applicant-phone-number=""
  data-applicant-email=""
  data-note=""
></div>
<script src="${window.location.origin}/embed.js"></script>`.trim()
}
