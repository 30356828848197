import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentSnapshot,
} from '@firebase/firestore'
import {
  Credential,
  CredentialLinkStatus,
  CREDENTIAL_GOOGLE_ID,
} from '@shared/models/credential'
import { User } from '@shared/models/user'
import { ChildDatabase } from './child-database'

declare const window: any // eslint-disable-line
export class TokenDatabase extends ChildDatabase<Credential, User> {
  collection(): CollectionReference<DocumentData> {
    return collection(this.db(), this.parentRef.path as string, 'TOKENS')
  }

  async getGoogleCredential(): Promise<DocumentSnapshot<Credential>> {
    return this.get(CREDENTIAL_GOOGLE_ID)
  }

  async getGoogleCredentialObj(): Promise<Credential | null> {
    return this.getObj(CREDENTIAL_GOOGLE_ID)
  }

  async createGoogleCredential(input: { accessCode: string }): Promise<void> {
    await this.create({
      id: CREDENTIAL_GOOGLE_ID,
      ...input,
      status: CredentialLinkStatus.PENDING,
      providedLocation: window.location.toString(),
    })
  }

  async clearGoogleCredential(): Promise<void> {
    await this.delete(CREDENTIAL_GOOGLE_ID)
  }
}
