<template>
  <v-list-item v-if="loading">
    <v-list-item-content>
      <v-list-item-title class="text-left">
        {{ $t('settings.loading_personal_token') }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action class="d-flex flex-row">
      <v-progress-circular indeterminate size="24" />
    </v-list-item-action>
  </v-list-item>

  <v-list-item v-else-if="!credential || expired">
    <v-list-item-content>
      <v-list-item-title class="text-left">
        <v-btn class="mb-1" @click="generatePersonalToken">
          <v-icon class="mr-1"> mdi-key </v-icon>
          {{ $t('settings.generate_personal_token') }}
        </v-btn>
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="expired"
        class="text-wrap text-left grey--text"
      >
        {{ $t('settings.personal_token_expired', { expirationDate }) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="d-flex flex-row">
      <small class="grey--text">
        {{ $t('settings.personal_token_not_ready') }}
      </small>
    </v-list-item-action>
  </v-list-item>

  <v-list-item v-else>
    <v-list-item-content>
      <v-list-item-title
        class="text-left"
        v-html="$t('settings.personal_token_ready', { expirationDate })"
      />
      <v-list-item-subtitle class="text-wrap text-left grey--text">
        <span v-html="$t('settings.do_not_share_token')" />
        <div class="mt-1 d-md-none flex justify-space-between">
          <v-btn outlined rounded color="primary" @click="reset()">
            <v-icon> mdi-lock-reset </v-icon>
          </v-btn>
          <v-btn outlined rounded color="primary" class="ml-2" @click="copy()">
            <v-icon> mdi-content-copy </v-icon>
          </v-btn>
          <v-btn outlined rounded color="red" class="ml-2" @click="clear()">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="d-flex flex-row">
      <div class="d-none d-md-flex">
        <v-btn outlined rounded color="primary" @click="reset()">
          <v-icon left> mdi-lock-reset </v-icon>
          {{ $t('settings.personal_token_new') }}
        </v-btn>
        <v-btn outlined rounded color="primary" class="ml-2" @click="copy()">
          <v-icon small> mdi-content-copy </v-icon>
        </v-btn>
        <v-btn outlined rounded color="red" class="ml-2" @click="clear()">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>
<style scoped></style>
<script>
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { UserDatabase } from '@/db/user-database'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { getFirestore } from '@firebase/firestore'
import { app } from '../helpers/firebase'
import { generatePersonalToken } from '../helpers/functions'
import { PersonalTokenType } from '@shared/models/personal-token'
import copyTextToClipboard from 'copy-text-to-clipboard'
import { $t } from '@/plugins/i18n'
import jwt from 'jsonwebtoken'
import isBefore from 'date-fns/isBefore'
import { differenceInDays } from 'date-fns/esm'

@Component({
  components: {},
  computed: {
    ...mapStateAuth(['uid']),
  },
})
export default class PersonalToken extends Vue {
  loading = true
  credential = null
  expired = false

  async created() {
    await this.loadToken()
    this.loading = false
  }

  copy() {
    copyTextToClipboard(this.credential.token)
    alert($t('settings.personal_token_copied'))
  }

  reset() {
    if (confirm($t('settings.personal_token_confirm_reset'))) {
      return this.generatePersonalToken()
    }
  }

  async loadToken() {
    const userDb = new UserDatabase({
      client: getFirestore(app),
    })
    const personalTokenDb = userDb.getPersonalTokenDb(this.uid)
    const credential = await personalTokenDb.getCredential()
    if (!credential) {
      return
    }

    const decoded = jwt.decode(credential.token)
    this.expirationDate = differenceInDays(
      new Date(decoded.exp * 1000),
      new Date()
    )

    if (isBefore(decoded.exp * 1000, new Date())) {
      console.log('Token expired')
      this.expired = true
      this.expirationDate = -this.expirationDate
      return
    }

    this.expired = false
    this.credential = credential
  }

  async clear() {
    this.loading = true
    const userDb = new UserDatabase({
      client: getFirestore(app),
    })
    const personalTokenDb = userDb.getPersonalTokenDb(this.uid)
    await personalTokenDb.deleteCredential()

    this.credential = null
    this.expired = false
    this.loading = false
  }

  generatePersonalToken() {
    this.loading = true

    generatePersonalToken({
      type: PersonalTokenType.PERSONAL,
    })
      .then(({ token }) => {
        console.log(token)
        return this.loadToken()
      })
      .finally(() => (this.loading = false))
  }
}
</script>
