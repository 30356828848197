























































































import { getFirestore } from '@firebase/firestore'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import {
  Notification,
  NotificationStatus,
  NotificationType,
  NotificationTypeFn,
} from '../../shared/models/notification'
import { NotificationDatabase } from '../db/notification-database'
import { UserDatabase } from '../db/user-database'
import { app } from '../helpers/firebase'
import { User } from '@shared/models/user'
import { $t } from '@/plugins/i18n'
import { STORAGE_KEY_LANGUAGE } from '../../shared/constants'
import { sendTrackingEvent } from '@/utils/eventTracker'
import GoogleLink from '../components/GoogleLink.vue'
import { getGoogleJobPageToken } from '@/helpers/functions'

const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

interface ListItemModel {
  type: NotificationType
  title: string
  status: NotificationStatus
  enabled: boolean
  notification?: Notification
}

@Component({
  components: {
    GoogleLink,
  },
  computed: {
    ...mapStateAuth(['uid']),
  },
})
export default class Settings extends Vue {
  uid!: string

  businessName = ''
  phoneNumber = ''
  list: ListItemModel[] = []
  language: string | null = null
  languages = [
    { text: $t('common.language.en'), value: 'en' },
    { text: $t('common.language.ja'), value: 'ja' },
  ]

  async created() {
    let userDb = new UserDatabase({
      client: getFirestore(app),
    })

    let user = (await userDb.getObj(this.uid)) as User
    this.businessName = user.businessName || 'Please input'
    this.phoneNumber = user.phoneNumber || 'Please input'
    this.language = user.language || 'ja'

    // this.checkGoogleLinkage()

    let notificationDb = new NotificationDatabase({
      client: getFirestore(app),
    })

    let notifications = await notificationDb.getNotifications({
      userId: this.uid,
    })

    this.list = NotificationTypeFn.getValues().map((type) => {
      let notification = notifications.find((v) => v.type === type)
      return {
        type,
        title: NotificationTypeFn.getLocalizedTitle(type, notification, $t),
        status: notification?.status || NotificationStatus.DISABLED,
        enabled: notification?.status === NotificationStatus.ENABLED,
        notification,
      }
    })
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  back() {
    this.$router.push('/')
  }

  openUpdateProfile() {
    this.$router.push(`/profile/update`)
    sendTrackingEvent(`${this.$route.path}:openUpdateProfile`, {}, this.uid)
  }

  openSettingsDetails(item: ListItemModel) {
    if (item.notification) {
      this.$router.push(`/settings/${item.notification.id}`)
    } else {
      this.$router.push(`/settings/update?type=${item.type}`)
    }
    sendTrackingEvent(`${this.$route.path}:openSettingsDetails`, item, this.uid)
  }

  async hangleChangeLanguage(language: string) {
    sendTrackingEvent(
      `${this.$route.path}:hangleChangeLanguage`,
      { language },
      this.uid
    )

    let userDb = new UserDatabase({
      client: getFirestore(app),
    })
    await userDb.update(this.uid, {
      language,
    })
    localStorage.setItem(STORAGE_KEY_LANGUAGE, language)
    setTimeout(() => {
      window.location.reload()
    })
  }
}
