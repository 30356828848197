


























































import { getFirestore } from '@firebase/firestore'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { BusinessOwner } from '../../shared/models/business-owner'
import { UserDatabase } from '../db/user-database'
import { app } from '../helpers/firebase'
const auth = createNamespacedHelpers('auth')
import { PLANS } from '../utils/plans'
import { sendTrackingEvent } from '@/utils/eventTracker'

@Component({
  components: {},
  computed: {
    ...auth.mapState(['uid', 'user']),
  },
  methods: {
    ...auth.mapMutations(['updateAuthStatus']),
  },
})
export default class SelectPlan extends Vue {
  uid!: string
  user!: BusinessOwner
  updateAuthStatus!: (user?: BusinessOwner) => void

  plans = PLANS
  selectedPlanId: string | null = null

  isSelectingPlan = false

  async created() {
    this.selectedPlanId = this.user.planId
  }

  back() {
    this.$router.back()
  }

  selectPlan(plan: any) {
    this.selectedPlanId = plan.id
  }

  nextStep() {
    const userDb = new UserDatabase({
      client: getFirestore(app),
    })

    this.isSelectingPlan = true
    userDb
      .update(this.uid, {
        planId: this.selectedPlanId,
      })
      .then(() => {
        this.updateAuthStatus({
          ...this.user,
          planId: this.selectedPlanId,
        })
        setTimeout(() => {
          if (this.user.stripeCustomerId) {
            this.back()
          } else {
            this.$router.push('/register-credit-card')
          }
        }, 100)
      })
      .finally(() => (this.isSelectingPlan = false))
    sendTrackingEvent(
      `${this.$route.path}:nextStep`,
      {
        selectedPlanId: this.selectedPlanId,
      },
      this.uid
    )
  }
}
