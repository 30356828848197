import { LOCALE_EN } from './en'

export const LOCALE_JA: typeof LOCALE_EN = {
  common: {
    test_text: 'これはテストです',
    'slot_{no}': '#{no}',
    status: {
      // statuses
      enabled: '有効',
      disabled: '無効',
      // slot statues
      open: '予約可能',
      closed: '予約済み',
      full: '満席',
      // meeting statuses
      confirmed: '確定済み',
      cancelled: 'キャンセル済み',
      pending: '調整中',
      rescheduled: '再調整済み',
    },
    sms_status: {
      sent: '送信済み',
      delivered: '配達済み',
      failed: '失敗した',
    },
    language: {
      en: 'English',
      ja: '日本語',
    },
  },

  home: {
    configure_availabe_slots: '面接予定',
    button_today: '今日',
    button_calendar: 'カレンダー',
    button_list: 'リスト',
    button_administrators: '管理者',
    button_customers: 'お客様',
    button_accept_interviews: '面接を受け入れますか？',
    button_payment: 'ペイメント',
    button_settings: '設定',
    button_developer: '開発者',
    button_qrcode: 'QRコード',
    button_google_search_job: '採用サイトの作成/変更',
    button_copy_public_url: 'URLのコピー',
    button_sign_out: 'サインアウト',
    section_confirmed_slots: '確定したスロット',
    section_available_slots: '調整可能なスロット',
    section_finished_slots: '終了済みスロット',
    status: '調整ステータス',
    time: '時間',
    applicant_name: '応募者名',
    applicant_phone: '電話番号',
    gmeet_url: 'Google meet URL',
    button_send_text_message: 'SMSを送信する',
    cancel_this_meeting: 'この面接をキャンセルする',
    delete_this_slot: 'このスロットを削除する',
    public_url_is_copied: 'URLをコピーしました',
    do_you_want_to_sign_out: 'サインアウトしますか？',
    do_you_want_to_cancel_this_meeting: 'この面接をキャンセルしますか？',
    meeting_has_been_canceled: '面接をキャンセルしました',
    cant_cancel_meeting:
      'この面接をキャンセルできません。しばらく経ってから、再度お試しください。',
    do_you_want_to_delete_this_slot: 'このスロットを削除しますか？',
    cant_delete_slot:
      'スロットを削除できませんでした。しばらく経ってから、再度お試しください。',
    you_turned_off_acceptance_of_interviews: '面接の受け入れをOFFにしました。',
    you_turned_on_acceptance_of_interviews: '面接の受け入れをONにしました。',
    cant_change_acceptance_of_interviews:
      '面接の受け入れを変更できません。しばらく経ってから、再度お試しください。',
    no_data_to_display: 'データがありません',
  },

  sign_in: {
    hi: '面接予約ONLINE™',
    welcome_to_mensetsu_yoyaku: 'ようこそ',
    button_sign_in_with_phone_number: '電話番号でサインインする',
    button_sign_in_with_email: 'Emailでサインインする',
  },

  sign_in_with_phone_number: {
    title_sign_in_with_phone_number: '電話番号でサインインする',
    button_next: '進む',
    button_change_phone_number: '電話番号を変更する',
    phone_number_is_invalid: '電話番号が不正です',
    verification_code: '認証コード',
    an_error_has_occurred: 'エラーが起きました',
    verification_code_is_invalid: '認証コード(verification code)が不正です',
    button_confirm: '確認',
  },

  payment_overview: {
    title_payment_overview: '支払い',
    button_change_plan: '変更する',
    this_month: '今月',
    confirmed_mettings_count: '確定した面接数',
    estimated_amount: '請求予定額',
    payment_info: '支払い方法',
    name: '名義',
    email: 'Email',
    credit_card_number: 'カード番号',
    button_change_credit_card: '変更する',
    unpaid_invoice: '未払いの請求',
    'payment_for_{month}': '{month}月の支払い',
    amount: '費用',
    status: '支払い状況',
    button_pay_now: '今すぐ支払う',
    invoice_payment: '請求書によるお支払い',
    credit_card_payment: 'クレジットカードによるお支払い',
  },

  qr_code: {
    title_qr_code: 'QRコード',
    this_your_qr_code: 'QRコードはこちらです',
    public_url: 'URL',
    public_url_is_copied: 'URLをコピーしました',
    button_change_qr_code: 'QRコードを更新する',
  },

  change_qr_code: {
    title_change_qr_code: 'QRコードを更新する',
    button_save: '保存する',
    error_cant_change_qr_code:
      'QRコードを更新できません。しばらく経ってから、再度お試しください。',
  },

  register_credit_card: {
    title_register_credit_card: '支払い方法',
    billing_name: '名義',
    billing_email: 'Email',
    card_number: 'カード番号',
    expiration_date: '有効期限',
    cvv: 'CVV',
    button_those_who_have_coupon_code: 'クーポンコードをお持ちの方はこちら',
    coupon_code: 'クーポンコード',
    button_submit: '変更する',
    please_confirm_card_number: 'カード番号をご確認ください',
    please_confirm_expiration_date: '有効期限をご確認ください',
    please_confirm_cvv: 'CVVをご確認ください',
    please_input_name: '名義をご入力ください',
    copoun_code_is_invalid: 'クーポンコードが正しくありません',
    please_confirm_card_information: 'カードの情報をご確認ください',
    message_success: '支払いに成功しました',
    message_error: '支払いに失敗しました',
    button_open_app: 'アプリを開く',
    html_pay_with_invoice:
      '請求書によるお支払いをご希望の方は<a id="pay_with_invoice">こちら</a>から',
  },

  register_billing_info: {
    title_register_billing_info: '請求先情報のご入力',
    description_1:
      '<b>お支払い：</b> <br />月初に前月分の利用料の請求書をメールでお送りいたし ます。お支払いは銀行振り込みとなります。',
    description_2:
      '<b>料金：</b><br />月額{pricePerMonth}円 無料面接設定{freeQuota}回/月 = 面接1回{pricePerInterview}円<br />1回追加毎に{pricePerAdditionalInterview}円',
    description_2_no_free_quota:
      '<b>料金：</b><br />月額{pricePerMonth}円<br />1回追加毎に{pricePerAdditionalInterview}円',
    billing_information: '請求先の情報：',
    name: 'お名前',
    placeholder_name: '山田太郎',
    email: 'Email',
    placeholder_email: 'example@tokyo.jp',
    tel_no: 'お電話番号',
    placeholder_tel_no: '08012345678（半角数字ハイフンなし）',
    billing_name: '請求先',
    placeholder_billing_name: '株式会社 Example',
    billing_postal_code: '郵便番号',
    placeholder_billing_postal_code: '1112222（半角数字ハイフンなし）',
    billing_address: 'ご住所',
    placeholder_billing_address: '東京都 渋谷区 1-2-3 exampleビル 101',
    button_save: '請求書支払いで利用を開始する',
    message_billing_information_is_saved: 'Billing information is saved',
    error_billing_information_is_not_saved: 'Billing information is not saved',
    html_pay_with_credit_card:
      'クレジットカードによるお支払いをご希望の方は<a id="pay_with_credit_card">こちら</a>から',
  },

  select_plan: {
    title_select_plan: 'プランを選択する',
    button_select_plan: 'プランを変更する',
    plan_starter_description: '月額費用無料<br />面接設定1回につき1000円',
    plan_tiny_description:
      '月額1000円<br />無料面接設定5回/月<br />= 面接1回200円<br />1回追加毎に500円',
    plan_small_description:
      '月額5000円<br />無料面接設定25回/月<br /= 面接1回200円<br />1回追加毎に500円',
    plan_medium_description:
      '月額10000円<br />無料面接設定50回/月<br />= 面接1回200円<br />1回追加毎に500円',
  },

  settings: {
    title_settings: '設定',
    sign_in_to_google_calendar: 'Googleカレンダー連携',
    button_sign_in_to_google_calendar: 'Googleカレンダーを連携する',
    sign_in_to_google_calendar_description:
      '面接が確定したら、Googleカレンダーに予定を自動的に追加します。',
    coming_soon: '近日公開予定',
    checking_google_linkage_status: 'Googleとの連携を確認しています',
    you_have_already_linked_with_google_calendar:
      'Google calendarと連携済みです',
    we_re_signing_you_on_with_google:
      'Googleへのサインイン中です。数分で完了予定です。',
    unlink_google: '接続を解除する',
    google_unlink_notice:
      'Googleアカウントとの接続を解除してよろしいですか？解除すると、面接予約が自動でGoogle Calendarに追加されなくなくなります。',
    profile: 'プロフィール',
    business_name: 'ビジネス名',
    phone_number: '電話番号',
    notifications: '通知',
    section_language: '言語',
    language: '言語',
    personal_token: 'API Token',
    do_not_share_token:
      "API Tokenは開発目的以外で他人に共有しないでください。API Docを<a href='https://docs.myol.xyz/'>こちら</a>からご確認いただけます。",
    generate_personal_token: 'API Tokenを発行する',
    loading_personal_token: 'API Tokenを発行中です。',
    personal_token_not_ready: '未発行',
    personal_token_ready:
      'API Tokenを発行済みです。 <i><small>(有効期限:{expirationDate}日後まで）</small></i>',
    personal_token_expired: '{expirationDate}日前で有効期限が切れました',
    personal_token_new: '新規発行する',
    personal_token_confirm_reset:
      '現在のAPI Tokenを削除し、新規発行します。現在のAPI Tokenはご利用できなくなります。よろしいですか？',
    personal_token_copied:
      'API Tokenをコピーしました。開発目的以外で他人に共有しないでください。',
    reconnect: '再連携する',
  },

  update_notification: {
    title_update_notification: '通知内容の詳細',
    notification_content: '通知内容',
    button_save: '保存',
    title_after_meeting_confirmation: '面接が確定次第すぐ',
    title_meeting_next_x_day: '面接前日の午前8:00',
    title_meeting_in_x_minute: '面接開始予定の{x}分前',
    title_metting_after_x_minute: '面接終了{x}時間後',
    title_metting_after_x_hours_to_business_owner:
      '面接終了{x}時間後 (自分宛の通知)',
    title_metting_after_x_days_to_business_owner:
      '面接終了{x}日後 (自分宛の通知)',
    gmeet_url_text:
      '予定時刻になりましたら <{GMEET_URL}> をクリックしてオンライン面接に入室してください。',
    notification_after_meeting_confirmation:
      '{APPLICANT_NAME}さん、面接の予約を承りました。当日の{GMEET_URL_TEXT}\n\n— {BUSINESS_NAME} {BUSINESS_PHONE_NUMBER}',
    notification_meeting_next_x_day:
      '{APPLICANT_NAME}さん、明日{MEETING_START_TIME}に面接予定です。{GMEET_URL_TEXT}リモートですが、お会いできるのを楽しみにしています！ \n\n— {BUSINESS_NAME} {BUSINESS_PHONE_NUMBER}',
    notification_meeting_in_x_minute:
      '{APPLICANT_NAME}さん、あと10分（{MEETING_START_TIME}）で面接予定時刻です。{GMEET_URL_TEXT}リモートですが、お会いできるのを楽しみにしています！ \n\n— {BUSINESS_NAME} {BUSINESS_PHONE_NUMBER}',
    notification_metting_after_x_minute:
      '{APPLICANT_NAME}さん、この度は、{MEETING_START_TIME}に{GMEET_URL}にて、貴重なお時間をありがとうございました。 \n\n— {BUSINESS_NAME} {BUSINESS_PHONE_NUMBER}',
    notification_metting_after_x_hour_to_business_owner: `面接について、記録を残しましょう。
    
  {APPLICANT_NAME}({APPLICANT_PHONE_NUMBER}) さんのインタビューは完了しましたか？以下から選択してください。
「はい」: {URL_YES} 
「いいえ」: {URL_NO}
「無断欠席(no-show)」: {URL_NO_SHOW}`,
    notification_metting_after_x_days_to_business_owner: `採用について、記録を残しましょう。
    
{APPLICANT_NAME}({APPLICANT_PHONE_NUMBER}) さんの採用を決定しましたか？以下から選択してください。
「はい」: {URL_YES} 
「いいえ」: {URL_NO}`,
  },

  meeting_answer: {
    answer_success: '回答を保存しました。',
    answer_error: '解答の保存に失敗しました。',
  },

  update_profile: {
    title_update_profile: 'プロフィールを更新する',
    business_name: 'ビジネス名（面接をする主体となる法人、事業、店舗等の名称）',
    phone_number: '電話番号',
    button_save: '保存',
  },

  select_a_slot: {
    title_select_a_slot: '日時を選択する',
    button_today: '今日',
    button_confirm: '確認',
    placeholder_display_name: '名前',
    placeholder_phone_number: '0123456789',
    button_ok: '予約する',
    button_back: '戻る',
    thank_you: '面接予約ありがとうございました！',
    we_ll_contact_you_later:
      '面接予約を承りました。オンライン面接のリンク等は、追ってご連絡いたします。下記のボタンからカレンダーアプリに予定を追加していただくことができます。',
    note: 'ノート',
    interviews_are_not_accepted: '面接は受け付けていません',
  },

  calendar: {
    event_calendar_title: '{businessName}の{applicantName}とのインタビュー',
    event_calendar_description: `
タイトル: {title}
ビジネス名: {businessName}
応募者名: {applicantName}
電話番号: {applicantPhone}
面接時間: {interviewDateTime}
オンライン面接URL: {meetingRoomUrl}
面接確定: {confirmedAt}
----------
面接予約ONLINE(TM)で自動面接予約を承りました
https://www.mensetsuyoyaku.online/
`,
  },

  create_slot: {
    create_slot_title: 'スロットを追加する',
    update_slot_title: 'スロットを更新する',
    button_cancel: 'キャンセルする',
    button_delete: '削除する',
    button_ok: 'OK',
    date: '日付(*)',
    start_time: '開始時刻(*)',
    duration: '面接時間(*)',
    duration_unit: '分',
    status: '調整ステータス(*)',
    status_available: '調整可能',
    status_confirmed: '確定済み',
    applicant_name: '応募者名(*)',
    applicant_phone_number: '電話番号(*)',
    indicates_required_field: `*必須`,
    button_add: '保存する',
    button_update: '保存する',
  },

  sms_history: {
    placeholder_please_input_message: 'メッセージを入力してください',
    message_is_sent: 'メッセージを送信しました',
    cant_send_message:
      'メッセージを送信に失敗しました。しばらく経ってから、再度お試しください。',
  },

  developer: {
    variables: '変数',
    copied: 'コピーしました',
    variable_description: {
      APPLICANT_NAME: '応募者名',
      APPLICANT_PHONE_NUMBER: '応募者電話番号',
      BUSINESS_NAME: 'ビジネス名',
      BUSINESS_PHONE_NUMBER: 'ビジネス電話番号',
      MEETING_START_TIME:
        'ミーティング開始時間(日本形式、例：2022/12/24 08:30）',
      MEETING_START_TIME_JP:
        'ミーティング開始時間（日本形式、例：2022/12/24 08:30）',
      MEETING_START_TIME_EU:
        'ミーティング開始時間（EU形式、例：24/12/2022 08:30）',
      MEETING_START_TIME_US:
        'ミーティング開始時間（US形式、例：12/24/2022 08:30）',
      MEETING_START_DATE: 'ミーティング開始日（日本語表記、例：2022/12/24）',
      MEETING_START_DATE_JP: 'ミーティング開始日（日本語表記、例：24/12/2022',
      MEETING_START_DATE_EU: 'ミーティング開始日（EU表記、例：24/12/2022',
      MEETING_START_DATE_US: 'ミーティング開始日（表記、例：12/24/2022',
      GMEET_URL: 'Google Meetのビデオチャットに参加するためのURL',
    },
    title_developer: '開発者',
    embed_onto_your_website: '埋め込みコード',
    embed_code: '埋め込みコード',
    embed_code_is_copied: '埋め込みコードをコピーしました',
    note: `応募者について以下の情報を変数として埋め込みコードから送信することができます:
  - data-applicant-name (応募者名)
  - data-applicant-phone-number (応募者電話番号)
  - data-applicant-email (応募者Email)
  - data-note (メモ。応募職種、埋め込み先等の情報)`,
  },

  landing: {
    title: '採用サイトの作成',
    saved: '保存されました',
    updateJob_1: '採用サイトの作成を行う前に',
    updateJob_2: 'で職種の更新をしてください。',
    store: {
      title: '企業(店舗)のページ',
      publicURL: '企業(店舗)の公開サイトのURL',
      previewURL: 'プレビューのURL',
    },
    jobs: {
      title: '募集職種のページ',
      publicURL: 'の公開サイトのURL',
      previewURL: 'のプレビューのURL',
      pageOf: 'のページ',
    },
    addJob: '職種の追加',
    notFilled: '未入力の項目が残っています。',
    save: '保存とプレビュー',
    upload: 'サイトを公開/更新する',
    newJob: '新しい職種',
    button_add: '追加',
    button_cancel: 'キャンセル',
  },

  customers: {
    title_customers: 'お客様情報',
    loading: 'Loading...',
    button_export_csv: 'CSVをエクスポートする',
    button_add_customer: 'お客様を追加する',
    field_id: 'ID',
    field_photoURL: ' ',
    field_name: '氏名',
    field_email: 'メールアドレス',
    field_telNo: '電話番号',

    field_paymentMethod: 'お支払い方法',
    field_planId: 'プラン',
    field_pricePerMonth: '月額',
    field_freeQuota: '無料回数',
    field_pricePerAdditionalConfirmation: '面接設定1回あたりの費用',

    field_interviewsCount: '面接回数',
    field_billingAmount: 'ご請求金額',
    field_billingName: 'ご請求先名',
    field_billingPostalCode: 'ご請求先郵便番号',
    field_billingAddress: 'ご請求先住所',

    field_status: 'ステータス',
    field_createdAt: '作成日',
    field_actions: 'アクション',
  },

  create_customer: {
    title_create_customer: 'お客様を追加する',
    title_update_customer: 'お客様情報を更新する',
    plan: 'プラン',
    placeholder_plan: 'プラン',
    button_save: '保存する',
    customer_is_saved: 'お客様情報を保存しました',
    error_customer_is_not_saved:
      'お客様情報が保存できませんでした。再度試行してください。',
  },

  administrators: {
    title_administrators: '管理者',
    button_add_administrator: '管理者を追加する',
    do_you_want_to_delete_administrator: 'この管理者を削除しますか？',
    you_deleted_administrator: '管理者を削除しました。',
    error_cant_delete_administrator:
      'この管理者を削除できません。再度試行してください。',
  },

  create_administrator: {
    title_create_administrator: '管理者を新規作成する',
    phone_number: '電話番号',
    button_add: '追加する',
    error_cant_find_user_associated_with_phone_number:
      'この電話番号のユーザーが見つかりません。',
    error_please_confirm_phone_number: '電話番号を確認してください。',
  },
}
