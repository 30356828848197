import configs from '@shared/configs'
import { stringify } from 'querystring'

const GAPI_LOADED_EVENT_NAME = 'GAPI_LOADED_EVENT_NAME'
const SCOPES = [
  'https://www.googleapis.com/auth/calendar',
  'https://www.googleapis.com/auth/calendar.events',
]

const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
]

export async function loadScriptAsync(s: {
  rel: string
  name: string
  src: string
}): Promise<void> {
  console.log('loading', s.name, 'from', s.src)
  const body = document.getElementsByTagName('body')[0]

  return new Promise<void>(function (resolve) {
    const script = document.createElement('script')
    script.type = 'text/javascript'

    // script.on = function () {
    //   // eslint-disable-line
    //   if (this.readyState == 'complete') script.onload()
    // }

    script.onload = () => {
      console.log(`%c ${s.name}:Loaded`, 'background: #CDDC39; color: #000000')
      resolve()
    }

    script.src = s.src
    console.log(`%c ${s.name}:Loading`, 'background: #FFEB3B; color: #000000')
    body.appendChild(script)
  })
}

export async function loadGapi(): Promise<void> {
  return loadScriptAsync({
    rel: 'javascript',
    name: 'gapi',
    src: 'https://apis.google.com/js/api.js',
  }).then(() => {
    window.dispatchEvent(new Event(GAPI_LOADED_EVENT_NAME))
  })
}

async function gapi(): Promise<any> { // eslint-disable-line
  return new Promise((resolve) => {
    if ((window as any).gapi) { // eslint-disable-line
      console.log('%c gapi:provided! ', 'background: #f5820b; color: #ffcb2c')
      resolve((window as any).gapi) // eslint-disable-line
      return
    }
    window.addEventListener(GAPI_LOADED_EVENT_NAME, () => {
      console.log('%c gapi:downloaded! ', 'background: #ffcb2c; color: #f5820b')
      resolve((window as any).gapi) // eslint-disable-line
    })
  })
}

export async function initGapi(stage: 'dev' | 'stage' | 'production' = 'dev'): Promise<any> { // eslint-disable-line
  console.log('gapi:initializing')
  return new Promise((resolve) => {
    gapi().then((gapi) => {
      gapi.load('client:auth2', async () => {
        await gapi.client.init({
          client_id: configs.gapi.clientId,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES.join(' '),
        })

        resolve(gapi)
      })
    })
  })
}

export async function linkFirebaseWithGoogleAccount(): Promise<void> {
  const { app } = await import('./firebase')
  const { GoogleAuthProvider, linkWithCredential, getAuth } = await import(
    'firebase/auth'
  )

  const gapi = await initGapi()
  const gglAuth = gapi.auth2.getAuthInstance()
  const gglUser = gglAuth.currentUser.get()
  const token = (await gglUser.reloadAuthResponse()).id_token
  const credential = GoogleAuthProvider.credential(token)

  const firebaseUser = getAuth(app).currentUser
  if (!firebaseUser) {
    return
  }
  await linkWithCredential(firebaseUser, credential)
}

export async function signInWithGoogleAccount(): Promise<{ code: string }> { // eslint-disable-line
  const gapi = await initGapi()
  const gglAuth = gapi.auth2.getAuthInstance()
  return gglAuth.grantOfflineAccess()
}

export function createAuthUrl({
  scope,
  redirectURL,
  clientId,
  state,
}: {
  scope: string
  redirectURL: string
  clientId: string
  state: string
}): string {
  const query = {
    scope,
    access_type: 'offline',
    include_granted_scopes: true,
    response_type: 'code',
    redirect_uri: redirectURL,
    client_id: clientId,
    state,
    prompt: 'consent',
  }
  return `https://accounts.google.com/o/oauth2/v2/auth?${stringify(query)}`
}

export function createAuthUrlWithCalendar(): string {
  return createAuthUrl({
    scope: SCOPES.join(' '),
    redirectURL: process.env.VUE_APP_BASE_URL
      ? `${process.env.VUE_APP_BASE_URL}/auth-callback`
      : (configs.baseUrl as string),
    clientId: configs.gapi.clientId as string,
    state: `${new Date().getTime()}`,
  })
}
