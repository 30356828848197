import { ExcludedPeriod, Rule } from '@shared/models/rule'
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from '@firebase/firestore'
import { ID, Optional } from '@shared/models/models'
import { Period } from '@shared/models/slot'
import { User } from '@shared/models/user'
import { ChildDatabase } from './child-database'
import { toRule } from '@/helpers/data'

export const RULE_DEFAULT_ID: ID = 'default'

export class RuleExcludedDatabase extends ChildDatabase<ExcludedPeriod, Rule> {
  collection(): CollectionReference<DocumentData> {
    return collection(this.db(), this.parentRef?.path as string, 'EXCLUDED')
  }
}
export class RuleDatabase extends ChildDatabase<Rule, User> {
  collection(): CollectionReference<DocumentData> {
    return collection(this.db(), this.parentRef.path as string, 'RULES')
  }

  protected converter(): (
    snap:
      | DocumentSnapshot<Rule | DocumentData>
      | QueryDocumentSnapshot<Rule | DocumentData>
  ) => Rule | null {
    return toRule
  }

  async getDefault(): Promise<DocumentSnapshot<Rule>> {
    return this.get(RULE_DEFAULT_ID)
  }

  async getDefaultObj(): Promise<Rule | null> {
    const rule = await this.getObj(RULE_DEFAULT_ID)
    if (!rule) {
      return null
    }
    return {
      ...rule,
      excludedPeriods: await this.getExcludePeriod(),
    }
  }

  async updateDefault(data: Optional<Rule>): Promise<void> {
    await this.update(RULE_DEFAULT_ID, data)
  }

  async createDefault(input: Optional<Rule>): Promise<void> {
    await this.create({
      id: RULE_DEFAULT_ID,
      ...input,
    })
  }

  getRuleExcludedDb(): RuleExcludedDatabase {
    return new RuleExcludedDatabase({
      client: this.db(),
      parentRef: this.ref(RULE_DEFAULT_ID),
      parentDb: this,
    })
  }

  async excludePeriod(period: Period): Promise<void> {
    const excludePeriodDb = this.getRuleExcludedDb()
    await excludePeriodDb.create({
      ...period,
      ruleId: RULE_DEFAULT_ID,
    })
  }

  async getExcludePeriod(): Promise<ExcludedPeriod[]> {
    const excludePeriodDb = this.getRuleExcludedDb()
    return excludePeriodDb.listObj()
  }
}
