// import { connectFunctionsEmulator } from '@firebase/functions'
import type { MeetingResponse } from '@shared/models/meeting'
import type { ID } from '@shared/models/models'
import { PersonalTokenType } from '@shared/models/personal-token'
import type { Period, PeriodResponse, SlotResponse } from '@shared/models/slot'
import { UserResponse } from '@shared/models/user'
import { SmsHistory } from '../../shared/models/sms-history'

function getFunctions<R, S>(name: string): (payload: R) => Promise<S> {
  return async (payload: R): Promise<S> => {
    const { getFunctions, httpsCallable } = await import('firebase/functions')
    const { app } = await import('./firebase')
    const fn = getFunctions(app, 'asia-northeast1')
    // connectFunctionsEmulator(fn, 'localhost', 5001)
    return httpsCallable(fn, name)(payload).then((result) => result.data as S)
  }
}

export const getAllSlots = getFunctions<
  {
    userId: ID
    numberOfDays: number
  },
  {
    predefinedSlots: SlotResponse[]
    ruleSlots: PeriodResponse[]
    businessUser: UserResponse
  }
>('getAllSlots')

export const createMeeting = getFunctions<
  {
    slotId?: ID
    slot?: Period
    applicantId: ID
    businessOwnerId: ID
    timeZone?: string
    note?: string
  },
  MeetingResponse
>('createMeeting')

export const sendSms = getFunctions<
  {
    phoneNumber?: string
    content: string
    toUserId: string
  },
  | {
    sent: false
    error: string
    smsHistory?: Omit<SmsHistory, 'createdAt'> & {
      createdAt: {
        _seconds: number
      }
    }
  }
  | {
    sent: true
    smsHistory?: Omit<SmsHistory, 'createdAt'> & {
      createdAt: {
        _seconds: number
      }
    }
  }
>('sendSms')

export const savePaymentMethodId = getFunctions<
  {
    paymentMethodId: string
    billingName: string
    billingEmail: string
  },
  { success: boolean; stripeCustomerId?: string }
>('savePaymentMethodId')

export const getDefaultPaymentMethod = getFunctions<
  unknown,
  { paymentMethod?: any }
>('getDefaultPaymentMethod')

export const generatePersonalToken = getFunctions<
  { type: PersonalTokenType.PERSONAL },
  { token: string }
>('generatePersonalToken')

export const getCoupon = getFunctions<string, { coupon?: any }>('getCoupon')

export const getGoogleJobPageToken = getFunctions<unknown, { token: string }>(
  'getGoogleJobPageToken'
)
