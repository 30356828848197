import { toMeeting } from '@/helpers/data'
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  limit,
  orderBy,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  updateDoc,
  where,
} from '@firebase/firestore'
import { ITEM_PER_PAGE } from '@shared/constants'
import { Meeting, MeetingStatus } from '@shared/models/meeting'
import { ID } from '@shared/models/models'
import { Period, Slot } from '@shared/models/slot'
import { User } from '@shared/models/user'
import { AvailableSlotDatabase } from './available-slot-database'
import { ChildDatabase, RequiredParentDatabaseOptions } from './child-database'

export class MeetingDatabase extends ChildDatabase<Meeting, User> {
  collection(): CollectionReference {
    return collection(this.db(), this.parentRef?.path as string, 'MEETINGS')
  }

  protected converter(): (
    snap:
      | DocumentSnapshot<Meeting | DocumentData>
      | QueryDocumentSnapshot<Meeting | DocumentData>
  ) => Meeting | null {
    return toMeeting
  }

  async createMeeting(input: {
    applicantId: ID
    slotId: ID
    slot?: Period
    gmeetUrl?: string
  }): Promise<Meeting> {
    let slotId = input.slotId
    let slotObj: Slot

    const db = new AvailableSlotDatabase(
      this.options as RequiredParentDatabaseOptions<User>
    )

    if (input.slot) {
      slotObj = await db.addSlotFromPeriod(input.slot)
      slotId = slotObj.id
    } else {
      slotObj = (await db.getObj(slotId)) as Slot
    }

    return this.create({
      ...input,
      slotId,
      businessOwnerId: this.parentRef?.id,
      status: MeetingStatus.CONFIRMED,

      slot: slotObj,
    })
  }

  async cancelMeeting(id: ID): Promise<Meeting> {
    const ref = this.ref(id)
    await updateDoc(ref, {
      status: MeetingStatus.CANCELLED,
      cancelledAt: new Date(),
    })

    return this.converter()(await getDoc(ref)) as Meeting
  }

  async getMeetingBySlot({ slotId }: { slotId: ID }): Promise<Meeting[]> {
    const wheres: QueryConstraint[] = [
      where('slotId', '<', slotId),
      limit(ITEM_PER_PAGE),
    ].filter((_) => !!_) as QueryConstraint[]

    return this.listObj(wheres)
  }
  async getMeetingsBySlot({ slotId }: { slotId: string }): Promise<Meeting[]> {
    const wheres: QueryConstraint[] = [where('slotId', '==', slotId)].filter(
      (_) => !!_
    ) as QueryConstraint[]

    return this.listObj(wheres)
  }

  async getMeetings({
    from,
    to,
    status,
  }: {
    from?: Date
    to?: Date
    status?: MeetingStatus
  }): Promise<Meeting[]> {
    const wheres: QueryConstraint[] = [
      to ? where('slot.startTime', '<', to) : null,
      from ? startAfter(from) : null,
      limit(ITEM_PER_PAGE),
      orderBy('slot.startTime', 'desc'),
    ].filter((_) => !!_) as QueryConstraint[]

    if (status) {
      wheres.push(where('status', '==', status))
    }

    return this.listObj(wheres)
  }

  async countConfirmedMeetings({
    from,
    to,
  }: {
    from: Date
    to: Date
  }): Promise<number> {
    const wheres: QueryConstraint[] = [
      where('slot.startTime', '>=', from),
      where('slot.startTime', '<', to),
      where('status', 'in', [MeetingStatus.CONFIRMED]),
    ]

    return this.count(wheres)
  }
}
