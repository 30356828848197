<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value="value"
    @update:return-value="
      (val) => {
        $emit('input', val)
        $emit('change', val)
      }
    "
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        label="募集の開始日を入力してください。"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-if="modal"
      :value="value"
      @input="
        (val) => {
          $emit('input', val)
          $emit('change', val)
        }
      "
      full-width
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">
        {{ $t('create_slot.button_cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(value)">
        {{ $t('create_slot.button_ok') }}
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
  computed: {},
  props: {
    label: String,
    value: String,
  },
})
export default class MyDateInput extends Vue {
  modal = false
}
</script>

<style scoped lang="scss"></style>
