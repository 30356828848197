import { Model } from './models'

export enum CredentialLinkStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
  EXCHANGE_FAILED = 'EXCHANGE_FAILED',
}

export const CREDENTIAL_GOOGLE_ID = 'google'
export const CREDENTIAL_PERSONAL_ID = 'personal'

export interface GoogleExchangedToken {
  access_token: string
  expiry_date: number
  id_token: string
  scope: string
  token_type: 'Bearer'
}
export interface Credential extends Model {
  accessCode: string
  status: CredentialLinkStatus
  providedLocation: string
  exchangedTokens?: GoogleExchangedToken
}
