import { getFirestore } from '@firebase/firestore'
import { getAuth } from 'firebase/auth'
import { ActionContext } from 'vuex'
import { BusinessOwner } from '../../shared/models/business-owner'
import { UserDatabase } from '../db/user-database'
import { app } from '../helpers/firebase'

export interface AuthState {
  uid?: string
  user?: BusinessOwner
}

export const state = (): AuthState => ({
  uid: undefined,
  user: undefined,
})

export const mutations = {
  updateAuthStatus(state: AuthState, value?: BusinessOwner) {
    state.uid = value?.id
    state.user = value
  },
  resetState(state: AuthState) {
    state.uid = undefined
    state.user = undefined
  },
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async loadAuthState({
    dispatch,
    commit,
    state,
  }: ActionContext<AuthState, any>): Promise<AuthState> {
    return new Promise((resolve) => {
      const unsubscribe = getAuth().onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          const userDb = new UserDatabase({
            client: getFirestore(app),
          })
          const user = await userDb.getObj(firebaseUser.uid)

          commit('updateAuthStatus', user)
          resolve({
            ...state,
            uid: user?.id,
            user: user as BusinessOwner,
          })
        } else {
          commit('updateAuthStatus', null)
          resolve({
            ...state,
            uid: undefined,
            user: undefined,
          })
        }

        unsubscribe()
      })
    })
  },
  async signOut({ commit, dispatch }: ActionContext<AuthState, any>) {
    try {
      await getAuth().signOut()
      commit('resetState')
    } catch (err) {
      console.error('error signing out of firebase', err)
    }
  },
}
