import Vue from 'vue'
import Vuex from 'vuex'
import * as applicant from './applicant'
import * as auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      ...auth
    },
    applicant: {
      namespaced: true,
      ...applicant
    },
  },
  plugins: []
})

