



































































































































































import { Component, Vue } from 'vue-property-decorator'
import { PeriodResponse, SlotResponse, SlotStatus } from '@shared/models/slot'
import { createMeeting, getAllSlots } from '@/helpers/functions'
import { createNamespacedHelpers } from 'vuex'
import { getDoc, getFirestore } from '@firebase/firestore'
import { UserDatabase } from '../../db/user-database'
import { app } from '../../helpers/firebase'
import {
  generateAddToYahooCalendarUrl,
  generateIcsUrl,
  generateAddToOutlookCalendarUrl,
  generateAddToGoogleCalendarUrl,
} from '../../utils/calendar'
import { MeetingResponse } from '@shared/models/meeting'
import { toObj } from '@/helpers/data'
import { Applicant } from '@shared/models/applicant'
import { UserResponse } from '@shared/models/user'
import validator from 'validator'
const applicant = createNamespacedHelpers('applicant')
import { $t } from '@/plugins/i18n'
import { randomString } from '../../utils/utils'
import {
  BusinessOwner,
  BusinessOwnerFn,
} from '../../../shared/models/business-owner'

interface CalendarEvent {
  slot: SlotResponse
  name?: string
  start: number
  end: number
  color: string
  timed: boolean
}

enum SelectASlotPageViewType {
  SELECT_A_SLOT,
  CONFIRM_INFO,
  THANKYOU,
}

@Component({
  components: {},
  props: {},
  computed: {
    ...applicant.mapState([
      'isLoadedApplicantState',
      'userId',
      'userDisplayName',
      'userPhoneNumber',
    ]),
  },
  methods: {
    ...applicant.mapMutations(['updateApplicant']),
  },
})
export default class SelectASlotPage extends Vue {
  SelectASlotPageViewType = SelectASlotPageViewType
  viewType = SelectASlotPageViewType.SELECT_A_SLOT

  meeting?: MeetingResponse

  isLoadedApplicantState!: boolean
  userId?: string
  userDisplayName?: string
  userPhoneNumber?: string

  note: string = ''

  updateApplicant!: (applicant: {
    userId?: string
    userDisplayName?: string
    userPhoneNumber?: string
  }) => void

  windowSize = {
    x: 0,
    y: 0,
  }

  displayName: string = ''
  phoneNumber: string = ''
  email: string = ''

  isCreatingMeeting = false

  type = '4day'
  mode = 'stack'
  weekdays = [0, 1, 2, 3, 4, 5, 6]
  value = ''
  events: CalendarEvent[] = []

  predefinedSlots: SlotResponse[] = []
  ruleSlots: PeriodResponse[] = []

  selectedEvent: CalendarEvent | null = null

  businessUser: UserResponse | null = null

  addToCalendars?: {
    img: string
    href: string
    click?: (e: any) => void // eslint-disable-line
    download?: string
  }[]

  validatePhoneNumberRules = [(v: string) => validator.isMobilePhone(v)]

  isLoading = true
  isEmbedded = false // used in embed.js

  get acceptInterviews() {
    if (this.businessUser) {
      return BusinessOwnerFn.acceptInterviews(
        this.businessUser as unknown as BusinessOwner
      )
    } else {
      return true
    }
  }

  created() {
    this.isEmbedded = this.$route.query.embedded == 'true'

    this.displayName = (this.$route.query.applicant_name as string) || ''
    this.phoneNumber =
      (this.$route.query.applicant_phone_number as string) || ''
    this.email = (this.$route.query.applicant_email as string) || ''
    this.note = (this.$route.query.note as string) || ''

    if (this.isEmbedded) {
      const style = document.createElement('style')
      style.innerHTML = `
        html {
          overflow-y: auto;
        }
      `
      document.head.appendChild(style)
    }

    getAllSlots({ userId: this.$route.params.userId, numberOfDays: 30 })
      .then((v) => {
        this.predefinedSlots = v.predefinedSlots
        this.ruleSlots = v.ruleSlots
        this.businessUser = v.businessUser
        this.events = this.predefinedSlots.map((v, index) => {
          let startTime = new Date(v.startTime)
          return {
            slot: v,
            name: $t('common.slot_{no}', {
              no: index + 1,
            }),
            start: startTime.getTime(),
            end: startTime.getTime() + v.durationMinute * 60 * 1000,
            color: v.status === SlotStatus.OPEN ? 'green' : 'gray',
            timed: true,
          }
        })
      })
      .finally(() => {
        this.isLoading = false
        setTimeout(() => {
          if (this.$refs.calendar) {
            ;(this.$refs.calendar as any).scrollToTime('07:50')
          }
        })
      })

    this.onResize()
    window.addEventListener('resize', this.onResize)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight }
  }

  getEventColor(event: CalendarEvent) {
    return event === this.selectedEvent ? 'blue' : event.color
  }

  setToday() {
    this.value = ''
  }

  prev() {
    ;(this.$refs.calendar as any).prev()
  }

  next() {
    ;(this.$refs.calendar as any).next()
  }

  selectEvent({ nativeEvent, event }: any) {
    this.selectedEvent = event
    nativeEvent.stopPropagation()
  }

  backToSelectASlot() {
    this.viewType = SelectASlotPageViewType.SELECT_A_SLOT
  }

  goToConfirm() {
    if (!this.displayName) {
      this.displayName = this.userDisplayName || ''
      this.phoneNumber = this.userPhoneNumber || ''
    }
    this.viewType = SelectASlotPageViewType.CONFIRM_INFO
  }

  goToThankyou() {
    this.isCreatingMeeting = true

    const userId = this.userId || `applicant:${randomString(20)}`
    const displayName = this.displayName.trim()
    const phoneNumber = this.phoneNumber.trim()
    this.updateApplicant({
      userId,
      userDisplayName: displayName,
      userPhoneNumber: phoneNumber,
    })

    const firestore = getFirestore(app)
    const userDb = new UserDatabase({ client: firestore })

    const userRef = userDb.ref(userId)
    getDoc(userRef)
      .then(async (snap): Promise<Applicant> => {
        if (snap.exists()) {
          console.log('Snap is existed')
          return userDb.updateApplicant(userId, {
            name: displayName,
            email: this.email,
            phoneNumber: phoneNumber,
            photoURL: null,
          })
        } else {
          console.log('createApplicant')
          return userDb.createApplicant({
            id: userId,
            name: displayName,
            email: this.email,
            phoneNumber: phoneNumber,
            photoURL: null,
          })
        }
      })
      .then(async (applicant: Applicant) => {
        if (this.selectedEvent) {
          this.meeting = await createMeeting({
            slotId: this.selectedEvent.slot.id,
            applicantId: userId,
            businessOwnerId: this.$route.params.userId,
            note: this.note,
          })
          this.viewType = SelectASlotPageViewType.THANKYOU

          const addToYahooCalendarUrl = generateAddToYahooCalendarUrl(
            this.meeting,
            applicant,
            this.businessUser as UserResponse
          )
          const icsUrl = generateIcsUrl(
            this.meeting,
            applicant,
            this.businessUser as UserResponse
          )
          const outlookUrl = generateAddToOutlookCalendarUrl(
            this.meeting,
            applicant,
            this.businessUser as UserResponse
          )
          const googleUrl = generateAddToGoogleCalendarUrl(
            this.meeting,
            applicant,
            this.businessUser as UserResponse
          )

          this.addToCalendars = [
            {
              img: '/icons/google_calendar.png',
              href: googleUrl,
              // href: '#',
              // eslint-disable-next-line
              // click: (e: any) => {
              //   e.preventDefault()
              //   tryGoogleCalendarAppLink(
              //     this.meeting as MeetingResponse,
              //     applicant,
              //     this.businessUser as UserResponse
              //   )
              // },
            },
            {
              img: '/icons/yahoo_calendar.png',
              href: addToYahooCalendarUrl as string,
            },
            {
              img: '/icons/outlook_calendar.png',
              href: outlookUrl,
            },
            {
              img: '/icons/apple_calendar.png',
              href: icsUrl as string,
              download: `${displayName}.ics`,
            },
          ]
          // call post message for embedded mode
          if (this.isEmbedded) {
            this.addToCalendars = this.addToCalendars.map((v) => {
              return {
                ...v,
                href: '#',
                click: (e) => {
                  e.stopPropagation()
                  window.parent.postMessage(
                    {
                      type: 'open-link',
                      link: v.href,
                    },
                    '*'
                  )
                },
              }
            })
          }
        }
      })
      .finally(() => (this.isCreatingMeeting = false))
  }
}
