




































































import { Component, Vue } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import { createNamespacedHelpers } from 'vuex'
import { getPublicUrl } from '../utils/qrcode'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import copy from 'copy-text-to-clipboard'
import { $t } from '@/plugins/i18n'
import { BusinessOwner } from '../../shared/models/business-owner'
import { sendTrackingEvent } from '@/utils/eventTracker'
import ChangeQrCodeDialog from '../components/dialogs/ChangeQrCodeDialog.vue'

@Component({
  components: {
    QrcodeVue,
    ChangeQrCodeDialog,
  },
  computed: {
    ...mapStateAuth(['uid', 'user']),
  },
})
export default class Qrcode extends Vue {
  uid!: string
  user!: BusinessOwner

  url: string = ''

  moreMenu = [
    {
      icon: 'mdi-qrcode-edit',
      title: $t('qr_code.button_change_qr_code'),
      click: this.changeQrCode,
    },
  ]

  showChangeQrCodeDialog = false

  created() {
    this.url = this.user.shortenUrl || getPublicUrl(this.uid)
  }

  back() {
    this.$router.back()
  }

  copyUrl() {
    const url = this.url
    copy(url)
    console.log(url)
    alert($t('qr_code.public_url_is_copied'))
    sendTrackingEvent(`${this.$route.path}:copyUrl`, {}, this.uid)
  }

  changeQrCode() {
    this.showChangeQrCodeDialog = true
    sendTrackingEvent(`${this.$route.path}:changeQrCode`, {}, this.uid)
  }

  handleOnShortenUrlUpdated(shortenUrl: string) {
    this.url = shortenUrl
  }
}
