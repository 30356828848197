import { toSlot } from '@/helpers/data'
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentSnapshot,
  limit,
  orderBy,
  QueryConstraint,
  QueryDocumentSnapshot,
  where,
} from '@firebase/firestore'
import { ITEM_PER_PAGE } from '@shared/constants'
import { ID } from '@shared/models/models'
import { Period, Slot, SlotStatus } from '@shared/models/slot'
import { User } from '@shared/models/user'
import { ChildDatabase } from './child-database'
import { RuleDatabase } from './rule-database'

export class AvailableSlotDatabase extends ChildDatabase<Slot, User> {
  collection(): CollectionReference {
    return collection(this.db(), this.parentRef?.path as string, 'SLOTS')
  }

  async addSlotFromPeriod(input: Period): Promise<Slot> {
    const slot = await this.create({
      ...input,
      capacity: 1,
      ownerId: this.parentRef?.id,
      status: SlotStatus.OPEN,
    })

    const ruleDb = new RuleDatabase(this.options)
    await ruleDb.excludePeriod(input)

    return slot
  }

  async closeSlot(id: ID): Promise<void> {
    return this.update(id, {
      status: SlotStatus.CLOSED,
    })
  }

  protected converter(): (
    snap:
      | DocumentSnapshot<Slot | DocumentData>
      | QueryDocumentSnapshot<Slot | DocumentData>
  ) => Slot | null {
    return toSlot
  }

  async getSlots({
    after,
    before,
  }: {
    after?: Date
    before?: Date
  }): Promise<Slot[]> {
    console.log('from', after, 'to', before)

    const wheres: QueryConstraint[] = [
      before ? where('startTime', '<', after) : null,
      after ? where('startTime', '>', before) : null,
      orderBy('startTime', 'asc'),
      limit(ITEM_PER_PAGE),
    ].filter((_) => !!_) as QueryConstraint[]
    return this.listObj(wheres)
  }

  async getFinishedSlots() {
    const wheres: QueryConstraint[] = [
      where('startTime', '<', new Date()),
      where('status', 'in', [SlotStatus.CLOSED, SlotStatus.FULL]),
      orderBy('startTime', 'desc'),
      limit(ITEM_PER_PAGE),
    ].filter((_) => !!_) as QueryConstraint[]
    return this.listObj(wheres)
  }
}
