import { ID, Model } from './models'

export enum NotificationType {
  AFTER_MEETING_CONFIRMATION_NOTIFICATION = 'after-meeting-confirmation',
  MEETING_NEXT_X_DAY = 'meeting-next-x-day',
  MEETING_IN_X_MINUTE = 'meeting-in-x-minute',
  MEETING_AFTER_X_MINUTE = 'meeting-after-x-minute',
  MEETING_AFTER_X_HOUR_TO_BUSINESS_OWNER = 'meeting-after-x-hour-to-business-owner',
  MEETING_AFTER_X_DAYS_TO_BUSINESS_OWNER = 'meeting-after-x-days-to-business-owner',
}

export enum NotificationStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export interface Notification extends Model {
  type: NotificationType
  ownerId: ID
  minute: number | null
  day: number | null
  status: NotificationStatus
  timezone?: 'Asia/Tokyo' | string
  content?: string
}

export const NotificationTypeFn = {
  getTitle: (
    type?: NotificationType,
    notification?: Notification | null
  ): string => {
    // TODO TrungNQ please localize this
    switch (type) {
      case NotificationType.AFTER_MEETING_CONFIRMATION_NOTIFICATION:
        return `Right after the meeting is confirmed`

      case NotificationType.MEETING_NEXT_X_DAY:
        return `At 8:00 AM on the previous day of the meeting`

      case NotificationType.MEETING_IN_X_MINUTE:
        return `${notification?.minute || 10} min before the meeting`

      case NotificationType.MEETING_AFTER_X_MINUTE:
        return `${notification?.day || 2} hours after the meeting is held`
      default:
        return ''
    }
  },

  getLocalizedTitle: (
    type: NotificationType,
    notification: Notification | null | undefined,
    $t: (key: any, values?: any) => string
  ): string => {
    switch (type) {
      case NotificationType.AFTER_MEETING_CONFIRMATION_NOTIFICATION:
        return $t('update_notification.title_after_meeting_confirmation')

      case NotificationType.MEETING_NEXT_X_DAY:
        return $t('update_notification.title_meeting_next_x_day')

      case NotificationType.MEETING_IN_X_MINUTE:
        return $t('update_notification.title_meeting_in_x_minute', {
          x: notification?.minute || 10,
        })

      case NotificationType.MEETING_AFTER_X_MINUTE:
        return $t('update_notification.title_metting_after_x_minute', {
          x: notification?.day || 2,
        })

      case NotificationType.MEETING_AFTER_X_HOUR_TO_BUSINESS_OWNER:
        return $t(
          'update_notification.title_metting_after_x_hours_to_business_owner',
          {
            x: (notification?.minute || 120) / 60,
          }
        )

      case NotificationType.MEETING_AFTER_X_DAYS_TO_BUSINESS_OWNER:
        return $t(
          'update_notification.title_metting_after_x_days_to_business_owner',
          {
            x: notification?.day || 2,
          }
        )

      default:
        return ''
    }
  },

  getValues: (): NotificationType[] => {
    return [
      NotificationType.AFTER_MEETING_CONFIRMATION_NOTIFICATION,
      NotificationType.MEETING_NEXT_X_DAY,
      NotificationType.MEETING_IN_X_MINUTE,
      NotificationType.MEETING_AFTER_X_MINUTE,
      NotificationType.MEETING_AFTER_X_HOUR_TO_BUSINESS_OWNER,
      NotificationType.MEETING_AFTER_X_DAYS_TO_BUSINESS_OWNER,
    ]
  },
}

export function getNotificationTarget(
  notification: Notification
): 'applicant' | 'businessOwner' {
  return [
    NotificationType.AFTER_MEETING_CONFIRMATION_NOTIFICATION,
    NotificationType.MEETING_NEXT_X_DAY,
    NotificationType.MEETING_IN_X_MINUTE,
    NotificationType.MEETING_AFTER_X_MINUTE,
  ].includes(notification.type)
    ? 'applicant'
    : 'businessOwner'
}
