
















































































































































































































































































































































































import { getFirestore } from '@firebase/firestore'
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import {
  Period,
  Slot,
  SlotStatus,
  SlotWithExtraInfo,
} from '../../shared/models/slot'
import { UserDatabase } from '../db/user-database'
import { app } from '../helpers/firebase'
import copy from 'copy-text-to-clipboard'
import { getAuth } from '@firebase/auth'
import { getPublicUrl } from '../utils/qrcode'
import format from 'date-fns/format'
import { GoogleEvent, Meeting } from '../../shared/models/meeting'
import { User } from '../../shared/models/user'
import { addMinutes } from 'date-fns'
import {
  BusinessOwner,
  BusinessOwnerFn,
  PaymentMethod,
  UserAdminRole,
  UserStatus,
} from '../../shared/models/business-owner'
import CreateSlotDialog from '../components/dialogs/CreateSlotDialog.vue'
import { $t } from '@/plugins/i18n'
import { sendTrackingEvent } from '@/utils/eventTracker'
import { getVideoEntryPoint } from '@shared/helpers/variables'

enum CalendarPageViewType {
  CALENDAR,
  LIST,
}

interface CalendarEvent {
  name?: string
  start: number
  end: number
  color: string
  timed: boolean
  slot?: Slot
}

@Component({
  components: {
    CreateSlotDialog,
  },
  props: {},
  computed: {
    ...mapStateAuth(['uid', 'user']),
  },
})
export default class CalendarPage extends Vue {
  CalendarPageViewType = CalendarPageViewType
  viewType = CalendarPageViewType.CALENDAR

  uid!: string
  user!: BusinessOwner

  searchText = ''
  searchSuggestions: string[] = []
  showSearchSuggestionsMenu = false

  panel = [0, 1]
  get panelsData() {
    if (this.searchText.trim().length > 0) {
      return [
        {
          title: 'Search result', //$t('home.search_result'),
          data: this.filteredSlots,
        },
      ]
    }

    return [
      {
        title: $t('home.section_confirmed_slots'),
        data: this.unavailableSlots,
      },
      {
        title: $t('home.section_available_slots'),
        data: this.availableSlots,
      },
      {
        title: $t('home.section_finished_slots'),
        data: this.finishedSlots,
      },
    ]
  }

  type = '4day'
  mode = 'stack'
  weekdays = [0, 1, 2, 3, 4, 5, 6]
  value = ''
  events: CalendarEvent[] = []

  predefinedSlots: SlotWithExtraInfo[] = []
  ruleSlots: Period[] = []

  availableSlots: Slot[] = []
  unavailableSlots: Slot[] = []
  finishedSlots: Slot[] = []
  filteredSlots: Slot[] = []

  meetings: Meeting[] = []
  applicants: User[] = []

  dragEvent: CalendarEvent | null = null
  dragStart: number | null = null
  dragTime: number | null = null
  extendEvent: CalendarEvent | null = null
  extendStart: number | null = null
  extendOriginalEnd: number | null = null

  selectedSlot: Slot | null = null
  showSlotDetailsDialog = false

  isCancelingMeeting = false
  isDeletingSlot = false

  isLoading = true

  get acceptInterviews() {
    return BusinessOwnerFn.acceptInterviews(this.user)
  }

  set acceptInterviews(value: boolean) {
    let userDb = new UserDatabase({
      client: getFirestore(app),
    })
    userDb
      .update(this.uid, {
        acceptInterviews: value,
      })
      .then(() => {
        this.user = {
          ...this.user,
          acceptInterviews: value,
        }
        if (value) {
          alert($t('home.you_turned_on_acceptance_of_interviews'))
        } else {
          alert($t('home.you_turned_off_acceptance_of_interviews'))
        }
      })
      .catch(() => {
        alert($t('home.cant_change_acceptance_of_interviews'))
      })
  }

  get adminMenu() {
    if (this.user.adminRole == UserAdminRole.SUPER_ADMIN) {
      return [
        {
          icon: 'mdi-account-cog-outline',
          title: $t('home.button_administrators'),
          click: () => this.$router.push(`/administrators`),
        },
        {
          icon: 'mdi-account-group-outline',
          title: $t('home.button_customers'),
          click: () => this.$router.push(`/customers`),
        },
      ]
    } else if (this.user.adminRole == UserAdminRole.ADMIN) {
      return [
        {
          icon: 'mdi-account-group-outline',
          title: $t('home.button_customers'),
          click: () => this.$router.push(`/customers`),
        },
      ]
    }

    return []
  }

  get moreMenu() {
    return [
      {
        icon: 'mdi-cog',
        title: $t('home.button_settings'),
        click: this.openSettings,
      },
      {
        icon: 'mdi-credit-card-outline',
        title: $t('home.button_payment'),
        click: this.openPayment,
      },
      {
        icon: 'mdi-code-tags',
        title: $t('home.button_developer'),
        click: this.openDeveloper,
      },
      {
        icon: 'mdi-qrcode',
        title: $t('home.button_qrcode'),
        click: this.openQrCode,
      },
      {
        icon: 'mdi-search-web',
        title: $t('home.button_google_search_job'),
        click: this.openGoogleSearchJob,
      },
      {
        icon: 'mdi-content-copy',
        title: $t('home.button_copy_public_url'),
        click: this.copyUrl,
      },
      {
        icon: 'mdi-logout',
        title: $t('home.button_sign_out'),
        color: 'red',
        click: this.signOut,
      },
    ]
  }

  windowSize = {
    x: 0,
    y: 0,
  }

  handleSearch() {
    this.setFilteredSlots()
    this.showOrHideSuggestionMenu()
  }

  showOrHideSuggestionMenu() {
    const SUGGESTIONS = ['label:confirmed', 'label:finished']

    if (this.searchText.trim().length === 0) {
      this.searchSuggestions = [...SUGGESTIONS]
    } else {
      const temp = this.searchText.toLowerCase().trim().split(' ')
      const last = temp.pop()
      if (last) {
        this.searchSuggestions = SUGGESTIONS.filter(
          (v) => v.startsWith(last) && v !== last
        )
      } else {
        this.searchSuggestions = []
      }
    }

    const shouldShow = this.searchSuggestions.length > 0
    if (this.showSearchSuggestionsMenu !== shouldShow) {
      setTimeout(() => {
        this.showSearchSuggestionsMenu = shouldShow
      }, 100)
    }
  }

  appendSuggestionToSearchText(text: string) {
    const temp = this.searchText.trim().split(' ')
    const last = temp.pop()
    if (last && text.startsWith(last)) {
      if (temp.length > 0) {
        this.searchText = `${temp.join(' ')} ${text} `
      } else {
        this.searchText = `${text} `
      }
    } else {
      if (this.searchText) {
        this.searchText = `${this.searchText} ${text} `
      } else {
        this.searchText = `${text} `
      }
    }
    ;(this.$refs['searchBox'] as any).focus()
    this.setFilteredSlots()
  }

  isDisabledUser(user: BusinessOwner) {
    return user.status === UserStatus.DISABLED
  }

  isFinishedSlot(slot: Slot) {
    return this.finishedSlots.find((v) => v.id === slot.id)
  }

  showCreateSlotDialog = false
  getVideoEntryPoint(entry?: GoogleEvent) {
    return getVideoEntryPoint(entry)
  }
  created() {
    let userDb = new UserDatabase({
      client: getFirestore(app),
    })
    console.log('getAllSlot', this.uid)
    userDb
      .getAllSlot({ userId: this.uid, numberOfDays: 30 })
      .then((v) => this.setSlots(v))
      .finally(() => {
        this.isLoading = false
        setTimeout(() => {
          if (this.$refs.calendar) {
            ;(this.$refs.calendar as any).scrollToTime('07:50')
          }
        })
      })

    let meetingDb = userDb.getMeetingDb(this.uid)
    meetingDb.getMeetings({}).then(async (meetings) => {
      this.meetings = meetings
      let applicantIds = meetings.map((v) => v.applicantId)
      let applicants = await userDb.getListByIds(applicantIds)
      this.applicants = applicants
      this.setSlots({
        predefinedSlots: this.predefinedSlots,
        ruleSlots: this.ruleSlots,
      })
    })

    setTimeout(() => {
      if (
        this.user.adminRole &&
        [UserAdminRole.SUPER_ADMIN, UserAdminRole.ADMIN].includes(
          this.user.adminRole
        )
      ) {
        // OK. This user is admin. SKIP payment
      } else if (this.user.status === UserStatus.DISABLED) {
        // This user is disabled!!!
      } else if (!this.user.businessName || !this.user.phoneNumber) {
        // user hasn't input businessName or phoneNumber
        this.$router.push('/profile/update')
      } else if (
        !this.user.planId ||
        (!this.user.stripeCustomerId &&
          this.user.paymentMethod === PaymentMethod.CARD)
      ) {
        // user hasn't select plan yet
        this.$router.push('/select-plan')
      }
    })

    this.onResize()
    window.addEventListener('resize', this.onResize)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight }
  }

  setSlots({
    predefinedSlots,
    ruleSlots,
    finishedSlots,
  }: {
    predefinedSlots: Slot[]
    ruleSlots: Period[]
    finishedSlots?: Slot[]
  }) {
    this.predefinedSlots = predefinedSlots
    this.ruleSlots = ruleSlots
    this.availableSlots = predefinedSlots.filter(
      (v) => v.status === SlotStatus.OPEN
    )
    this.unavailableSlots = predefinedSlots.filter(
      (v) => v.status !== SlotStatus.OPEN
    )
    if (finishedSlots) {
      this.finishedSlots = finishedSlots
    }
    ;[
      ...this.predefinedSlots,
      ...(this.finishedSlots as SlotWithExtraInfo[]),
    ].map((slot: SlotWithExtraInfo) => {
      let meeting = this.meetings.find((v) => v.slotId === slot.id)
      let applicant = meeting
        ? this.applicants.find((v) => v.id === meeting?.applicantId)
        : undefined
      slot.meeting = meeting
      slot.applicant = applicant
    })

    this.events = [
      ...this.predefinedSlots,
      ...(this.finishedSlots as SlotWithExtraInfo[]),
    ].map((v, index) => {
      let startTime = new Date(v.startTime)
      let name = $t('common.slot_{no}', { no: index + 1 })
      v.name = name
      return {
        name,
        start: startTime.getTime(),
        end: startTime.getTime() + v.durationMinute * 60 * 1000,
        color: this.getSlotColor(v),
        timed: true,
        slot: v,
      }
    })
  }

  setFilteredSlots() {
    if (this.searchText.trim().length > 0) {
      const temp = this.searchText.toLowerCase().trim().split(' ')
      const labels = temp.filter((v) => v.startsWith('label:'))
      const searchText = temp.filter((v) => !v.startsWith('label:')).join(' ')
      let allSlots: Slot[] = []
      if (labels.length === 0) {
        allSlots = [
          ...this.availableSlots,
          ...this.unavailableSlots,
          ...this.finishedSlots,
        ]
      } else {
        if (labels.includes('label:confirmed')) {
          allSlots = [...allSlots, ...this.unavailableSlots]
        }
        if (labels.includes('label:finished')) {
          allSlots = [...allSlots, ...this.finishedSlots]
        }
      }

      if (searchText) {
        this.filteredSlots = allSlots.filter(
          (v: SlotWithExtraInfo) =>
            v.applicant &&
            (v.applicant.name?.toLowerCase().includes(searchText) ||
              v.applicant.phoneNumber?.toLowerCase().includes(searchText))
        )
      } else {
        this.filteredSlots = allSlots
      }
    } else {
      this.filteredSlots = []
    }
  }

  showEvent({
    nativeEvent,
    event,
  }: {
    nativeEvent: MouseEvent
    event: CalendarEvent
  }) {
    if (event.slot) {
      this.showSlotDetails(event.slot)
    }

    nativeEvent.stopPropagation()
  }

  showSlotDetails(slot: SlotWithExtraInfo) {
    const open = () => {
      this.selectedSlot = slot || null
      requestAnimationFrame(() => {
        if (slot.status === SlotStatus.OPEN) {
          this.showCreateSlotDialog = true
        } else {
          this.showSlotDetailsDialog = true
        }
      })
    }

    if (this.showSlotDetailsDialog) {
      this.showSlotDetailsDialog = false
      requestAnimationFrame(() => open())
    } else {
      open()
    }
  }

  hideSlotDetails() {
    this.showSlotDetailsDialog = false
  }

  startDrag({ event, timed }: any) {
    if (event && timed) {
      this.dragEvent = event
      this.dragTime = null
    }
  }

  startTime(tms: any) {
    const mouse = this.toTime(tms)
    console.log('startTime')

    if (this.dragEvent && this.dragTime === null) {
      const start = this.dragEvent.start

      this.dragTime = mouse - start
    } else {
      const createStart = this.roundTime(mouse)
      const createEvent: CalendarEvent = {
        name: $t('common.slot_{no}', { no: this.events.length + 1 }),
        color: 'green',
        start: createStart,
        end: createStart + 30 * 60 * 1000,
        timed: true,
      }

      this.events.push(createEvent)

      let userDb = new UserDatabase({
        client: getFirestore(app),
      })
      const slotDb = userDb.getSlotDb(this.uid)
      slotDb
        .create({
          ownerId: this.uid,
          startTime: new Date(createEvent.start),
          durationMinute: (createEvent.end - createEvent.start) / (60 * 1000),
          capacity: 1,
          status: SlotStatus.OPEN,
        })
        .then((slot) => {
          ;(slot as SlotWithExtraInfo).name = createEvent.name
          this.predefinedSlots.push(slot)
          this.availableSlots.push(slot)
          createEvent.slot = slot
        })
    }
  }

  extendBottom(event: CalendarEvent) {
    console.log('extendBottom', event)
    if (event.slot && event.slot.status === SlotStatus.OPEN) {
      this.extendEvent = event
      this.extendStart = event.start
      this.extendOriginalEnd = event.end
    }
  }

  mouseMove(tms: any) {
    const mouse = this.toTime(tms)

    if (
      this.dragEvent &&
      this.dragEvent.slot &&
      this.dragEvent.slot.status === SlotStatus.OPEN &&
      this.dragTime !== null
    ) {
      const start = this.dragEvent.start
      const end = this.dragEvent.end
      const duration = end - start
      const newStartTime = mouse - this.dragTime
      const newStart = this.roundTime(newStartTime)
      const newEnd = newStart + duration

      this.dragEvent.start = newStart
      this.dragEvent.end = newEnd
    } else if (
      this.extendEvent &&
      this.extendEvent.slot &&
      this.extendEvent.slot.status === SlotStatus.OPEN &&
      this.extendStart !== null
    ) {
      const mouseRounded = this.roundTime(mouse, false)
      const min = Math.min(mouseRounded, this.extendStart)
      const max = Math.max(mouseRounded, this.extendStart)

      this.extendEvent.start = min
      this.extendEvent.end = max
    }
  }

  endDrag() {
    console.log('endDrag', this.dragEvent, this.extendEvent)
    let slot: Slot | undefined
    let startTime: Date | undefined
    let durationMinute: number | undefined

    if (
      this.dragEvent &&
      this.dragEvent.slot &&
      this.dragEvent.slot.status === SlotStatus.OPEN
    ) {
      slot = this.dragEvent.slot
      startTime = new Date(this.dragEvent.start)
      durationMinute = (this.dragEvent.end - this.dragEvent.start) / (60 * 1000)
    } else if (
      this.extendEvent &&
      this.extendEvent.slot &&
      this.extendEvent.slot.status === SlotStatus.OPEN
    ) {
      slot = this.extendEvent.slot
      startTime = new Date(this.extendEvent.start)
      durationMinute =
        (this.extendEvent.end - this.extendEvent.start) / (60 * 1000)
    }

    if (
      slot &&
      slot.status === SlotStatus.OPEN &&
      startTime &&
      durationMinute
    ) {
      slot.startTime = startTime
      slot.durationMinute = durationMinute

      const userDb = new UserDatabase({
        client: getFirestore(app),
      })
      const slotDb = userDb.getSlotDb(this.uid)
      slotDb.update(slot.id, {
        startTime,
        durationMinute,
      })
    }

    this.dragTime = null
    this.dragEvent = null
    this.extendEvent = null
    this.extendStart = null
    this.extendOriginalEnd = null
  }

  cancelDrag() {
    console.log('cancelDrag', this.dragEvent, this.extendEvent)
  }

  roundTime(time: number, down = true) {
    const roundTo = 15 // minutes
    const roundDownTime = roundTo * 60 * 1000

    return down
      ? time - (time % roundDownTime)
      : time + (roundDownTime - (time % roundDownTime))
  }

  toTime(tms: any) {
    return new Date(
      tms.year,
      tms.month - 1,
      tms.day,
      tms.hour,
      tms.minute
    ).getTime()
  }

  getEventColor(event: CalendarEvent) {
    return event.color
  }

  getSlotColor(slot: Slot) {
    return slot.status === SlotStatus.OPEN ? 'green' : 'grey'
  }

  getApplicantName(slot: SlotWithExtraInfo) {
    return slot.applicant?.name || ''
  }

  getApplicantPhoneNumber(slot: SlotWithExtraInfo) {
    return slot.applicant?.phoneNumber || ''
  }

  // rnd(a: number, b: number) {
  //   return Math.floor((b - a + 1) * Math.random()) + a
  // }

  formatDateForSlot(slot: Slot) {
    return `${format(slot.startTime, 'yyyy-MM-dd HH:mm aa')} - ${format(
      addMinutes(slot.startTime, slot.durationMinute),
      'HH:mm aa'
    )}`
  }

  setToday() {
    this.value = ''
  }

  prev() {
    ;(this.$refs.calendar as any).prev()
  }

  next() {
    ;(this.$refs.calendar as any).next()
  }

  selectViewTypeCalendar() {
    this.viewType = CalendarPageViewType.CALENDAR
    setTimeout(() => {
      if (this.$refs.calendar) {
        ;(this.$refs.calendar as any).scrollToTime('07:50')
      }
    })
    sendTrackingEvent(
      `${this.$route.path}:selectViewTypeCalendar`,
      {},
      this.uid
    )
  }

  selectViewTypeList() {
    this.viewType = CalendarPageViewType.LIST
    sendTrackingEvent(`${this.$route.path}:selectViewTypeList`, {}, this.uid)
  }

  openPayment() {
    this.$router.push('/payment')
    sendTrackingEvent(`${this.$route.path}:openPayment`, {}, this.uid)
  }

  openSettings() {
    this.$router.push('/settings')
    sendTrackingEvent(`${this.$route.path}:openSettings`, {}, this.uid)
  }

  openDeveloper() {
    this.$router.push('/developer')
    sendTrackingEvent(`${this.$route.path}:openDeveloper`, {}, this.uid)
  }

  openEmbedCode() {
    this.$router.push('/embed')
    sendTrackingEvent(`${this.$route.path}:openEmbedCode`, {}, this.uid)
  }

  openQrCode() {
    this.$router.push('/qrcode')
    sendTrackingEvent(`${this.$route.path}:openQrCode`, {}, this.uid)
  }

  openGoogleSearchJob() {
    this.$router.push('/landing-page-generation')
    sendTrackingEvent(`${this.$route.path}:openGoogleSearchJob`, {}, this.uid)
  }

  copyUrl() {
    const url = this.user.shortenUrl || getPublicUrl(this.uid)
    copy(url)
    console.log(url)
    alert($t('home.public_url_is_copied'))
    sendTrackingEvent(`${this.$route.path}:copyUrl`, {}, this.uid)
  }

  signOut() {
    if (confirm($t('home.do_you_want_to_sign_out'))) {
      getAuth()
        .signOut()
        .then(() => {
          this.$router.push('/sign-in')
        })
    }
    sendTrackingEvent(`${this.$route.path}:signOut`, {}, this.uid)
  }

  cancelMeeting(slot: SlotWithExtraInfo) {
    if (slot.meeting) {
      if (confirm($t('home.do_you_want_to_cancel_this_meeting'))) {
        let userDb = new UserDatabase({
          client: getFirestore(app),
        })

        this.isCancelingMeeting = true
        let meetingDb = userDb.getMeetingDb(this.uid)
        meetingDb
          .cancelMeeting(slot.meeting.id)
          .then(() => {
            alert($t('home.meeting_has_been_canceled'))
            this.showSlotDetailsDialog = false
            this.setSlots({
              predefinedSlots: this.predefinedSlots.map((v) => {
                if (v.id === slot.id) {
                  return {
                    ...v,
                    status: SlotStatus.OPEN,
                  }
                } else {
                  return v
                }
              }),
              ruleSlots: this.ruleSlots,
            })
          })
          .catch(() => {
            alert($t('home.cant_cancel_meeting'))
          })
          .finally(() => (this.isCancelingMeeting = false))
        sendTrackingEvent(`${this.$route.path}:cancelMeeting`, slot, this.uid)
      }
    }
  }

  deleteSlot(slot: Slot) {
    if (confirm($t('home.do_you_want_to_delete_this_slot'))) {
      let userDb = new UserDatabase({
        client: getFirestore(app),
      })
      const slotDb = userDb.getSlotDb(this.uid)
      this.isDeletingSlot = true
      slotDb
        .delete(slot.id)
        .then(() => {
          let predefinedSlots = this.predefinedSlots.filter(
            (v) => v.id !== slot.id
          )
          this.setSlots({
            predefinedSlots,
            ruleSlots: this.ruleSlots,
          })
          this.showSlotDetailsDialog = false
        })
        .catch(() => {
          alert($t('home.cant_delete_slot'))
        })
        .finally(() => (this.isDeletingSlot = false))
      sendTrackingEvent(`${this.$route.path}:deleteSlot`, slot, this.uid)
    }
  }

  toggleViewType() {
    this.viewType =
      this.viewType === CalendarPageViewType.CALENDAR
        ? CalendarPageViewType.LIST
        : CalendarPageViewType.CALENDAR
  }

  showAddSlotDialog() {
    this.selectedSlot = null
    this.showCreateSlotDialog = true
    sendTrackingEvent(`${this.$route.path}:showAddSlotDialog`, {}, this.uid)
  }

  handleOnSlotAdded(slot: SlotWithExtraInfo) {
    if (slot.meeting) {
      this.meetings = [...this.meetings, slot.meeting]
    }
    if (slot.applicant) {
      this.applicants = [...this.applicants, slot.applicant]
    }
    this.setSlots({
      predefinedSlots: [...this.predefinedSlots, slot],
      ruleSlots: this.ruleSlots,
    })
  }

  handleOnSlotUpdated(slot: SlotWithExtraInfo) {
    if (slot.meeting) {
      this.meetings = [...this.meetings, slot.meeting]
    }
    if (slot.applicant) {
      this.applicants = [...this.applicants, slot.applicant]
    }
    this.setSlots({
      predefinedSlots: this.predefinedSlots.map((v) =>
        v.id === slot.id ? slot : v
      ),
      ruleSlots: this.ruleSlots,
    })
  }

  handleOnSlotDeleted(slot: SlotWithExtraInfo) {
    let predefinedSlots = this.predefinedSlots.filter((v) => v.id !== slot.id)
    this.setSlots({
      predefinedSlots,
      ruleSlots: this.ruleSlots,
    })
  }

  openSmsHistory(slot: SlotWithExtraInfo) {
    if (slot.applicant) {
      this.$router.push(`/sms/${slot.applicant.id}`)
      sendTrackingEvent(`${this.$route.path}:openSmsHistory`, slot, this.uid)
    }
  }
}
