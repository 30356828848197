







































































































import { getFirestore } from '@firebase/firestore'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { app } from '../helpers/firebase'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { $t } from '@/plugins/i18n'
import { sendTrackingEvent } from '@/utils/eventTracker'
import { UserDatabase } from '../db/user-database'
import {
  BusinessOwner,
  UserAdminRole,
  UserStatus,
} from '../../shared/models/business-owner'
import { format } from 'date-fns/esm'
import { BillingInfoDatabase } from '../db/billing-info-database'
import { PLANS } from '../utils/plans'
import { Parser } from 'json2csv'

const AVATAR_BACKGROUND_COLORS = [
  '#CB771D',
  '#CA3E3C',
  '#D6206D',
  '#B93095',
  '#5757D9',

  '#2361AA',
  '#0B7684',
  '#70971A',
  '#884BC9',
  '#586E7D',
]

const downloadFileWithContent = (filename: string, text: string) => {
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

@Component({
  components: {},
  computed: {
    ...mapStateAuth(['uid', 'user']),
  },
})
export default class Customers extends Vue {
  uid!: string
  user!: BusinessOwner

  selectedAdminId: string | null = null
  admins: BusinessOwner[] = []

  isLoading = true

  headers = [
    {
      value: 'id',
    },
    {
      value: 'photoURL',
    },
    {
      value: 'name',
    },
    {
      value: 'email',
    },
    {
      value: 'telNo',
    },
    {
      value: 'paymentMethod',
    },
    {
      value: 'planId',
    },
    {
      value: 'pricePerMonth',
    },
    {
      value: 'freeQuota',
    },
    {
      value: 'pricePerAdditionalConfirmation',
    },
    {
      value: 'interviewsCount',
    },
    {
      value: 'billingAmount',
    },
    {
      value: 'billingName',
    },
    {
      value: 'billingPostalCode',
    },
    {
      value: 'billingAddress',
    },
    {
      value: 'status',
    },
    {
      value: 'createdAt',
      width: '120px',
    },
    {
      value: 'actions',
    },
  ].map((v) => ({
    ...v,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    text: $t(`customers.field_${v.value}` as any),
    sortable: false,
  }))
  businessOwners: BusinessOwner[] = []

  async created() {
    this.selectedAdminId = this.uid
    this.admins = [this.user].map((v) => ({
      ...v,
      label: v.businessName || v.businessName || v.phoneNumber,
    }))

    if (this.isSuperAdmin()) {
      const userDb = new UserDatabase({
        client: getFirestore(app),
      })
      userDb.getAdminUsersByCreatedBy(this.uid).then(
        (v) =>
          (this.admins = [this.user, ...v].map((v) => ({
            ...v,
            label: v.businessName || v.businessName || v.phoneNumber,
          })))
      )
    }
  }

  @Watch('selectedAdminId')
  fetchCustomers() {
    if (!this.selectedAdminId) {
      return
    }

    this.isLoading = true
    this.businessOwners = []
    const userDb = new UserDatabase({
      client: getFirestore(app),
    })
    const billingInfoDb = new BillingInfoDatabase({
      client: getFirestore(app),
    })
    userDb
      .getBusinessUsersByCreatedBy(this.selectedAdminId)
      .then(
        (v) =>
          (this.businessOwners = v.map((user) => {
            const plan = PLANS.find((plan) => plan.id === user.planId)
            return {
              ...user,
              name: user.businessName || user.billingName || user.name,
              email: user.email || user.billingEmail,
              telNo: user.phoneNumber,
              ...(plan
                ? {
                    pricePerMonth: plan.pricePerMonth,
                    freeQuota: plan.freeQuota,
                    pricePerAdditionalConfirmation:
                      plan.pricePerAdditionalConfirmation,
                  }
                : {}),
            }
          }))
      )
      .then(() => billingInfoDb.listObj())
      .then((billingInfos) => {
        this.businessOwners = this.businessOwners.map((user) => {
          const billingInfo = billingInfos.find(
            (_billingInfo) => _billingInfo.id === user.id
          )

          return {
            ...user,
            ...(billingInfo || {}),
          }
        })
      })
      .finally(() => (this.isLoading = false))
  }

  isSuperAdmin() {
    return this.user.adminRole === UserAdminRole.SUPER_ADMIN
  }

  getAvatarLabel(name: string) {
    return name.substr(0, 1)
  }

  getAvatarColor(id: string) {
    let count = 0
    for (let i = 0; i < id.length; i++) {
      count += id.charCodeAt(i)
    }

    return AVATAR_BACKGROUND_COLORS[count % AVATAR_BACKGROUND_COLORS.length]
  }

  getUserStatusColor(status: UserStatus) {
    switch (status) {
      case UserStatus.ENABLED:
        return 'green'
      case UserStatus.DISABLED:
        return 'gray'
      case UserStatus.UNPAID:
        return 'orange'
      case UserStatus.PREREGISTER_WITH_PHONE_NUMBER:
        return 'orange'
    }
  }

  formatDate(date: Date) {
    return format(date, 'yyyy-MM-dd HH:mm aa')
  }

  back() {
    this.$router.back()
  }

  updateCustomer(user: BusinessOwner) {
    this.$router.push(`/customers/${user.id}/update`)
  }

  createCustomer() {
    this.$router.push(`/customers/create`)
  }

  exportCSV() {
    const fields = this.headers
      .map((v) => v.value)
      .filter((v) => !['photoURL', 'actions'].includes(v))
    const opts = { fields }

    try {
      const parser = new Parser(opts)
      const csv = parser.parse(this.businessOwners)
      downloadFileWithContent(
        `myol_customers_${format(new Date(), 'yyyyMMdd_HHmmss')}.csv`,
        csv
      )
    } catch (err) {
      console.error(err)
    }
  }
}
