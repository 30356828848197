<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-title>Responding</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="back">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container class="d-flex flex-column text-center">
        <div class="justify-space-between mt-16">
          <v-progress-circular
            :rotate="360"
            :size="150"
            :width="10"
            indeterminate
          >
          </v-progress-circular>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { Component, Vue } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
import { answer } from '../helpers/api'
import { $t } from '@/plugins/i18n'

@Component({
  components: {},
  computed: {
    ...mapStateAuth(['uid']),
  },
})
export default class MeetingAnswer extends Vue {
  async created() {
    console.log('answering', this.$route.query)
    await answer(this.$route.query.actionCode)
      .then(() => {
        alert($t('meeting_answer.answer_success'))
        this.$router.push({ path: '/', replace: true })
      })
      .catch((e) => {
        console.error(e)
        alert($t('meeting_answer.answer_error'))
        this.$router.push({ path: '/', replace: true })
      })
  }
}
</script>
