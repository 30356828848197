






import '@shared/models/models'
import './helpers/firebase'
import { createNamespacedHelpers } from 'vuex'
import { AuthState } from './store/auth'
import { STORAGE_KEY_LANGUAGE } from '../shared/constants'
import { i18n } from './plugins/i18n'
const auth = createNamespacedHelpers('auth')
const applicant = createNamespacedHelpers('applicant')

export default {
  name: 'App',
  data() {
    return {
      isLoadedAuthState: false,
    }
  },
  // https://mensetsu-yoyaku-online-dev.web.app/meeting/answer?actionCode=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWV0aW5nSWQiOiJaY2JUb245TFRDcnE0YmFHcVpRZyIsIm5vdGlmaWNhdGlvbklkIjoiYm9NR1hoT0l5UEQzMFNVWjNqVUMiLCJ1c2VySWQiOiI3SHlOc05wUVVyWEpXbG5KZnhiR3BPalB1Z3AyIiwidGltZXN0YW1wIjoxNjQ4NjIwMzAyNDY5LCJ0eXBlIjoiYW5zd2VyIiwic3RvcmVWYWx1ZUFzIjoiaW50ZXJ2aWV3U3RhdHVzIiwidmFsdWUiOiJ5ZXMiLCJpYXQiOjE2NDg2MjAzMDIsImV4cCI6MTY0ODc5MzEwMn0.AK9swgOQtHwaf7off1Q2WvXnimBrjaey3wVq0-u3b2I
  created() {
    ;(this as any).loadAuthState().then((state: AuthState) => {
      console.log('authState', { state })
      if (
        (!state.uid || !state.user) &&
        (this as any).$route.path !== '/meeting/answer' &&
        (this as any).$route.path !== '/sign-in' &&
        !(this as any).$route.path.startsWith('/app/')
      ) {
        // redirect to login page
        ;(this as any).$router.replace('/sign-in')
      }
      if (state.user) {
        if (
          state.user.language &&
          state.user.language != localStorage.getItem(STORAGE_KEY_LANGUAGE)
        ) {
          localStorage.setItem(STORAGE_KEY_LANGUAGE, state.user.language)
          i18n.locale = state.user.language
          // window.location.reload()
          // return
        }
      }
      setTimeout(() => {
        ;(this as any).isLoadedAuthState = true
      }, 100)
    })
    ;(this as any).loadApplicantState()
  },
  methods: {
    ...auth.mapActions(['loadAuthState', 'signOut']),
    ...applicant.mapActions(['loadApplicantState']),
  },
}
