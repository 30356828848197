import type { Model, Optional, Response } from './models'

export enum UserType {
  BUSINESS_OWNER = 'business-owner',
  APPLICANT = 'applicant',
}

export interface User extends Model {
  type: UserType
  name: string | null
  phoneNumber: string | null
  email: string | null
  photoURL: string | null
  businessName: string | null
  language: string | null
}

export interface UserResponse extends Response {
  name: string | null
  phoneNumber: string | null
  email: string | null
  photoURL: string | null
  businessName: string | null
}

export type UserInput = Optional<User>
