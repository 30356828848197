import { Applicant } from './applicant'
import { Meeting } from './meeting'
import { ID, Model, Optional, Response } from './models'

export enum SlotStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  FULL = 'full',
}

interface PeriodCommon {
  startTime: Date | string
  durationMinute: number
  timezone?: 'Asia/Tokyo' | string
}
export interface Period extends PeriodCommon {
  startTime: Date
}
export interface PeriodResponse extends PeriodCommon {
  startTime: string
}

interface SlotCommon extends PeriodCommon {
  ownerId: ID
  startTime: Date | string
  durationMinute: number
  capacity: 1
  status: SlotStatus
}

export interface Slot extends Model, SlotCommon {
  startTime: Date
}
export interface SlotResponse extends Response, SlotCommon {
  startTime: string
}

export interface SlotWithExtraInfo extends Slot {
  name?: string
  meeting?: Meeting
  applicant?: Applicant
}

export type SlotInput = Optional<Slot>
