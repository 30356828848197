

























































































import { Component, Vue } from 'vue-property-decorator'
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ConfirmationResult,
} from 'firebase/auth'
import { getFirestore } from '@firebase/firestore'
import { app } from '../helpers/firebase'
import { UserDatabase } from '../db/user-database'
import { createNamespacedHelpers } from 'vuex'
import { BusinessOwner } from '../../shared/models/business-owner'
const auth = createNamespacedHelpers('auth')
import { $t, i18n } from '@/plugins/i18n'
import { STORAGE_KEY_LANGUAGE } from '../../shared/constants'
import { getBrowserLanguage } from '../utils/utils'
import { createShortenUrl } from '../helpers/shorten-url'
import { getPublicUrl } from '../utils/qrcode'
import { signUpForTracking, identifyTrackedUser } from '@/utils/eventTracker'
import { BillingInfoDatabase } from '../db/billing-info-database'

enum SignInWithPhoneNumberViewType {
  INPUT_PHONE_NUMBER,
  CONFIRM_VERIFICATION_CODE,
}

@Component({
  components: {},
  methods: {
    ...auth.mapMutations(['updateAuthStatus']),
  },
})
export default class SignInWithPhoneNumber extends Vue {
  SignInWithPhoneNumberViewType = SignInWithPhoneNumberViewType
  viewType = SignInWithPhoneNumberViewType.INPUT_PHONE_NUMBER

  updateAuthStatus!: (user?: BusinessOwner) => void

  phoneNumber: string = ''
  phoneNumberErrors: string[] = []

  verificationCode: string = ''
  verificationCodeErrors: string[] = []

  isSendingVerificatioCode = false
  isConfirmingVerificationCode = false

  appVerifier?: RecaptchaVerifier
  confirmationResult?: ConfirmationResult

  backToSignIn() {
    this.$router.back()
  }

  sendVerificationCode() {
    const auth = getAuth()
    auth.useDeviceLanguage()

    if (!this.appVerifier) {
      this.appVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response: any) => {
            console.log('recapchar callback', { response })
          },
          'expired-callback': () => {
            console.log('recapchar expired-callback')
          },
        },
        auth
      )
    }

    this.isSendingVerificatioCode = true
    let phoneNumber = this.phoneNumber
    if (!phoneNumber.startsWith('+')) {
      phoneNumber = `+81${phoneNumber}`
    }
    signInWithPhoneNumber(auth, phoneNumber, this.appVerifier)
      .then((confirmationResult) => {
        this.confirmationResult = confirmationResult
        this.verificationCode = ''
        this.phoneNumberErrors = []
        this.verificationCodeErrors = []
        this.viewType = SignInWithPhoneNumberViewType.CONFIRM_VERIFICATION_CODE
      })
      .catch((error) => {
        console.log({ error })
        let message: string
        if (error.code === 'auth/invalid-phone-number') {
          message = $t('sign_in_with_phone_number.phone_number_is_invalid')
        } else {
          message = $t('sign_in_with_phone_number.an_error_has_occurred')
        }
        this.phoneNumberErrors = [message]
      })
      .finally(() => (this.isSendingVerificatioCode = false))
  }

  resendVerificationCode() {}

  confirmVerificationCode() {
    if (this.confirmationResult) {
      this.isConfirmingVerificationCode = true
      this.confirmationResult
        .confirm(this.verificationCode)
        .then(async (result) => {
          let firebaseUser = result.user

          const userDb = new UserDatabase({ client: getFirestore(app) })
          let user = await userDb.getObj(firebaseUser.uid)

          if (user) {
            if (!(user as BusinessOwner).shortenUrl) {
              const result = await createShortenUrl(
                getPublicUrl(user.id)
              ).catch(() => null)
              if (result && result.shortenUrl) {
                await userDb.update(user.id, {
                  shortenUrl: result.shortenUrl,
                })
                ;(user as BusinessOwner).shortenUrl = result.shortenUrl
              }
            }

            identifyTrackedUser(user.id)
            signUpForTracking(user)

            return user as BusinessOwner
          } else {
            const preregisterUser = await userDb.getObj(
              `preregister:${firebaseUser.phoneNumber}`
            )

            const result = await createShortenUrl(
              getPublicUrl(firebaseUser.uid)
            ).catch(() => null)

            user = await userDb.createBusinessOwner({
              ...(preregisterUser || {}),
              id: firebaseUser.uid,
              name: firebaseUser.displayName,
              email: firebaseUser.email,
              phoneNumber: firebaseUser.phoneNumber,
              photoURL: firebaseUser.photoURL,
              language: getBrowserLanguage(),
              shortenUrl: result?.shortenUrl || null,
            })

            if (preregisterUser) {
              const billingInfoDb = new BillingInfoDatabase({
                client: getFirestore(app),
              })
              const preregisterBillingInfo = await billingInfoDb.getObj(
                preregisterUser.id
              )

              if (preregisterBillingInfo) {
                await billingInfoDb.set(firebaseUser.uid, {
                  ...preregisterBillingInfo,
                  id: firebaseUser.uid,
                })
              }

              await userDb.delete(preregisterUser.id)
              if (preregisterBillingInfo) {
                await billingInfoDb.delete(preregisterUser.id)
              }
            }

            identifyTrackedUser(user.id)
            signUpForTracking(user)

            return user as BusinessOwner
          }
        })
        .then((user) => {
          this.verificationCodeErrors = []
          this.updateAuthStatus(user)
          if (
            user.language &&
            user.language !== localStorage.getItem(STORAGE_KEY_LANGUAGE)
          ) {
            localStorage.setItem(STORAGE_KEY_LANGUAGE, user.language)
            i18n.locale = user.language
          }
          setTimeout(() => this.$router.push('/'), 100)
        })
        .catch((error) => {
          console.log({ error })
          let message: string
          if (error.code === 'auth/invalid-verification-code') {
            message = $t(
              'sign_in_with_phone_number.verification_code_is_invalid'
            )
          } else {
            message = $t('sign_in_with_phone_number.an_error_has_occurred')
          }
          this.verificationCodeErrors = [message]
        })
        .finally(() => (this.isConfirmingVerificationCode = false))
    }
  }

  changePhoneNumber() {
    this.viewType = SignInWithPhoneNumberViewType.INPUT_PHONE_NUMBER
  }
}
